import { Select, MenuItem } from "@mui/material";

function DropDown ({ name, label, value, style, change, lists }) {
 return(
  <Select
    name={name}
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    label={label}
    value={value}
    sx={style}
    small={"small"}
    onChange={change}
   >
   {lists.map((item, index) => {
     return(
       <MenuItem key={index} value={item}>
            {item}
        </MenuItem>
        )
    })}
   </Select>
 )
}

export default DropDown;