import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import moment, { localeData } from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getDissaproved } from "../../Actions/Actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportOffIcon from "@mui/icons-material/ReportOff";
import { size } from "lodash";
import { createTheme } from "@mui/material/styles";
import ExportReportBtn from "../Commons/ExportReportBtn";
import { STAGES } from "../../constants";
import { STATUS } from "../../constants";
import { ButtonCleanFilters } from "../Buttons/Button";
import { Label, Search } from "../Input/Input";
import DropDown from "../DropDown/DropDown";

const defaultTheme = createTheme();

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Search
        value={props.value}
        change={props.onChange}
        className={classes.textField}
      />
    </div>
  );
}

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

export default function ApprovedDissaproved() {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [backUpData, setBackUpData] = useState([]);
  const [totalapproved, settotalapproved] = useState(0);
  const [totaldisapproved, settotaldisapproved] = useState(0);
  const [chartdata, setchartdata] = useState([]);
  const [pageSize, setPageSize] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [loading, setloading] = useState(false);
  const [selectedStage, setSelectedStage] = useState("");
  const [monthfilter, setmonthfilter] = useState("");
  const [filterDate, setFilterDate] = useState({ m: "", y: "" });
  const [statusfilter, setStatusfilter] = useState("");
  const [stagelist, setStagelist] = useState([]);
  const [statuslist, setStatuslist] = useState([]);

  useEffect(() => {
    const loadDissaprovedList = async () => {
      await loadAll();
    };

    loadDissaprovedList();
  }, []);

  const loadAll = async () => {
    const response = await getDissaproved();
    let uniquestatus = [...new Set(response.map((item) => item.status))];
    let uniquestage = [...new Set(response.map((item) => item.stage))];
    setStatuslist(uniquestatus);
    setStagelist(uniquestage);
    const upper = response.map((item) => {
      return {
        ...item,
        fullname: item.fullname.toUpperCase(),
        company: item.company ? item.company.toUpperCase() : item.company,
      };
    });

    let approvedarray = [];
    let dissaprovedarray = [];
    let datearray = [];
    //response.sort(compare);
    response.forEach((item) => {
      item.id = Number(item.Id);
      item.datestring = item.date;
      item.fullname = item.fullname?.toUpperCase();
      item.stage = item.stage ? item.stage.trim().toUpperCase() : item.stage;
      item.status = item.status;
    });
    setData(response);
    setBackUpData(upper);
  };

  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  function compare(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    const filteredDataBackend = data.filter((item) => {
      const filterStatus =
        statusfilter !== ""
          ? item.status.toUpperCase() === statusfilter.toUpperCase()
          : true;
      const filterStage =
        selectedStage !== ""
          ? item.stage.toUpperCase() === selectedStage.toUpperCase()
          : true;
      const filterMonth =
        monthfilter !== ""
          ? moment(item.date).isSame(moment(monthfilter), "month")
          : true;

      return filterStatus && filterStage && filterMonth;
    });

    let filteredDataWithSearch = filteredDataBackend;

    if (searchText !== "") {
      const searchRegex = new RegExp(escapeRegExp(searchText), "i");
      filteredDataWithSearch = filteredDataBackend.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
    }

    const upper = filteredDataWithSearch.map((item) => {
      return { ...item, fullname: item.fullname.toUpperCase() };
    });

    setFilteredData(upper);
  }, [data, statusfilter, selectedStage, monthfilter, searchText]);

  const columns = [
    {
      field: "id",
      headerName: "#",
      width: 20,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 500,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      editable: false,
      renderCell: (params) => <DrawerStatus params={params} />,
    },
    {
      field: "datestring",
      headerName: "Desition Date",
      renderCell: (params) => (
        <Typography> {moment(params.row.datestring).format("ll")}</Typography>
      ),

      width: 200,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
      editable: false,
    },
    {
      field: "reason",
      headerName: "Reason",

      width: 1000,
    },
    {
      field: "company",
      headerName: "Company",
      width: 200,
    },

    {
      field: "department",
      headerName: "Departament",
      width: 200,
    },
    {
      field: "position",
      headerName: "Position",
      width: 200,
    },
    {
      field: "idproceso",
      headerName: "Flokzu ID",
      width: 150,
    },
  ];

  const DrawerStatus = ({ params }) => {
    let value = params.row.status;
    if (value) {
      if (value === "APPROVED") {
        return (
          <>
            <CheckCircleIcon sx={{ color: "green" }} />
            <Typography>{value}</Typography>
          </>
        );
      } else {
        return (
          <>
            <ReportOffIcon sx={{ color: "red" }} />
            <Typography>{value}</Typography>
          </>
        );
      }
    } else {
      return <Typography>{value}</Typography>;
    }
  };

  const getCsvData = () => {
    const csvData = [];
    const headers = columns.map((item) => {
      return item.headerName;
    });
    filteredData.forEach((item) => {
      csvData.push({
        id: item.id,
        stage: item.stage,
        status: item.status,
        datestring: item.date,
        fullname: item.fullname,
        reason: item.reason,
        company: item.company,
        department: item.department,
        position: item.position,
        idproceso: item.idproceso,
      });
    });
    return [headers, csvData];
  };

  const onChangeMonths = (dateChange, itemDate) => {
    if (!dateChange || dateChange === "-01") return true;

    const m = new Date(dateChange).getMonth();
    const y = new Date(dateChange).getUTCFullYear();

    const firstDay = getFirstDay(dateChange);
    const lastDay = getLastDay(dateChange);

    const itemDateObj = new Date(itemDate);

    return itemDateObj >= firstDay && itemDateObj <= lastDay;
  };

  const getFirstDay = (dateValue) => {
    const y = new Date(dateValue).getFullYear();
    const m = new Date(dateValue).getMonth();
    return new Date(y, m, 1);
  };

  const getLastDay = (dateValue) => {
    const y = new Date(dateValue).getFullYear();
    const m = new Date(dateValue).getMonth();
    return new Date(y, m + 1, 0);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" color="initial">
            Approved & dissapproved
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              marginTop: 10,
            }}
          >
            <CheckCircleIcon sx={{ color: "green" }} />
            Total Approved:{" "}
            {size(
              filteredData.filter((item) => item.status.trim() === "APPROVED")
            )}
            <ReportOffIcon sx={{ color: "red", marginLeft: 2 }} />
            Total Disapproved:{" "}
            {size(
              filteredData.filter(
                (item) => item.status.trim() === "DISAPPROVED"
              )
            )}
          </div>
          <Grid container sx={{ marginLeft: 10 }}>
            <Grid item xs={3} sx={{ marginTop: 4 }}>
              <Label text="Filter by Status" />
              <DropDown
                name="status"
                label="status"
                value={statusfilter}
                style={{ width: 200, height: 55, mr: 2 }}
                change={(e) => setStatusfilter(e.target.value)}
                lists={STATUS}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: 4 }}>
              <Label text="Filter by Desition Date" />
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="month"
                value={monthfilter}
                onChange={(e) => setmonthfilter(e.target.value)}
                sx={{ width: 200, mr: 2 }}
              />
            </Grid>
            <Grid item xs={3} sx={{ marginTop: 4 }}>
              <Label text="Filter by Stage" />
              <DropDown
                name="stage"
                label="stage"
                value={selectedStage}
                style={{ width: 200, mr: 2 }}
                change={(e) => setSelectedStage(e.target.value)}
                lists={STAGES}
              />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="right"
              alignItems="center"
              sx={{ marginTop: 4, marginRight: 10 }}
            >
              <ButtonCleanFilters
                style={{ mr: 2 }}
                click={() => {
                  setData(data);
                  setStatusfilter("");
                  setSelectedStage("");
                  setmonthfilter("");
                  setSearchText("");
                }}
              />
              <ExportReportBtn getData={getCsvData} />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "80vh", marginTop: 3 }}>
            <DataGrid
              rows={filteredData}
              columns={columns}
              pagination
              rowsPerPageOptions={[20, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                pagination: {
                  pageSize: pageSize,
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    setSearchText(event.target.value);
                  },
                  filterDate: filterDate,
                  monthfilter: monthfilter,
                  selectedStage,
                },
                pagination: { classes: null },
              }}
            />
          </Box>
        </Paper>
      </Container>
    </>
  );
}
