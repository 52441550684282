import { Box } from "@mui/material";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import defaultUser from "../../assets/default-avatar.jpg";
import { useEffect, useState } from "react";
import { BorderClear, BorderColor } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

const UserProfilePicture = ({
  profileImg,
  setProfileImg,
  setPreviewImg,
  previewImg,
}) => {
  const [showPreview, setShowPreview] = useState(false);

  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const handleFileInput = (e) => {
    let currentImg = e.target.files[0];
    if (currentImg && !acceptedImageTypes.includes(currentImg["type"])) {
      toast.error("Please select a valid image file", {
        position: "top-center",
      });
      return;
    }
    setProfileImg(currentImg);
    setShowPreview(true);
    const imgUrl = URL.createObjectURL(currentImg);
    setPreviewImg(imgUrl);
  };

  const handleShowPreview = () => {
    if (showPreview) return previewImg;
    return profileImg;
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginBottom: 5,
      }}>
      <Box
        sx={{
          width: 150,
          height: 150,
          zindex: 999999999,
          borderRadius: "50%",
          padding: 0.2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "2px solid orange",
        }}>
        <img
          src={
            profileImg === "" || profileImg === undefined
              ? defaultUser
              : handleShowPreview()
          }
          alt=""
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
      </Box>
      <Box
        sx={[
          {
            position: "relative",
            top: 105,
            left: -45,
            backgroundColor: "gray",
            width: 35,
            height: 35,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            cursor: "pointer",
          },
          {
            "&:hover": {
              backgroundColor: "lightGray",
            },
          },
        ]}>
        <CameraAltOutlinedIcon sx={{ width: 27, height: 27 }} />
        <input
          type="file"
          name="userPicture"
          multiple={false}
          onChange={(e) => {
            handleFileInput(e);
          }}
          style={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}></input>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default UserProfilePicture;
