import {
  AB_POSITIONS,
  BOSUN_POSITIONS,
  FITTER_POSITIONS,
  MESSMAN_POSITIONS,
  OILER_POSITIONS,
  COOK_POSITIONS,
  messmanSkill,
  abOsSkill,
  bosunSkillTable,
  abOsSkillTable,
  bosunSkill,
  cookSkill,
  cookSkillTable,
  messmanSkillTable,
  fitterSkillTable,
  fitterSkill,
  oiler,
  oilerWiperSkillTable,
  wiper,
} from "../constants";

import CryptoJS from 'crypto-js';

export const numberNextLimiter = (text, amount) => {
  if (!text) return "";
  let resultado = "";
  for (var i = 0; i < text.length; i += amount) {
    resultado += text.substr(i, amount) + "\n";
  }
  return resultado;
};

export const getSkillFromIdProcess = (idprocessdata, person) => {
  let filteredQuestions = [];
  if (person.idproceso !== "") {
    //TO DO MAKE THE PROCESS ID QUESTIONSttt
    idprocessdata.fields.forEach((item) => {
      if (
        Object.keys(item).some(function (k) {
          return ~k.indexOf("Answer");
        })
      ) {
        filteredQuestions.push(item);
      }
    });
  }
  if (
    AB_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let ABOS_1 = filteredQuestions.slice(0, 18);
    let ABOS_2 = filteredQuestions.slice(118, 140);
    const fullAnswers = [...ABOS_1, ...ABOS_2];
    const questionsArray = fullAnswers.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: abOsSkill[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = abOsSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (
    BOSUN_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let BOSUN_1 = filteredQuestions.slice(18, 36);
    let BOSUN_2 = filteredQuestions.slice(118, 140);
    const fullAnswers = [...BOSUN_1, ...BOSUN_2];
    const questionsArray = fullAnswers.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: bosunSkill[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = bosunSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (
    COOK_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let COOK = filteredQuestions.slice(36, 80);
    const questionsArray = COOK.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: cookSkill[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = cookSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (
    MESSMAN_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let MESSMAN = filteredQuestions.slice(88, 105);
    const questionsArray = MESSMAN.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: messmanSkill[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = messmanSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (
    FITTER_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let FITTER_1 = filteredQuestions.slice(71, 88);
    let FITTER_2 = filteredQuestions.slice(118, 140);
    const fullAnswers = [...FITTER_1, ...FITTER_2];
    const questionsArray = fullAnswers.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: fitterSkill[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = fitterSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (
    OILER_POSITIONS.map((item) => item.toUpperCase()).includes(
      person.position_applying.toUpperCase()
    )
  ) {
    let OILER_1 = filteredQuestions.slice(96, 100);
    let OILER_2 = filteredQuestions.slice(105, 127);
    const fullAnswers = [...OILER_1, ...OILER_2];
    const questionsArray = fullAnswers.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: oiler[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = oilerWiperSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
  if (person.position_applying === "WIPER") {
    let WIPER_1 = filteredQuestions.slice(100, 127);
    const questionsArray = WIPER_1.map((item, index) => {
      const keys = Object.keys(item);
      return {
        isTrue: item[keys[0]],
        question: wiper[index],
      };
    });
    let currentIndex = 0;
    const newQuestions = oilerWiperSkillTable.map((item, index) => {
      return {
        skill: item.skill,
        questions: item.questions.map((it, i) => {
          currentIndex++;
          return {
            question: it.question,
            isTrue:
              questionsArray[currentIndex - 1]?.isTrue === "true"
                ? true
                : false,
          };
        }),
      };
    });
    return newQuestions;
  }
};

export const sortExperiencesByDate = (data) => {
  return data?.sort((a, b) => new Date(b["Date on"]) - new Date(a["Date on"]));
};

export const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, 'secret').toString()
}

export const decryptPassword = (encryptPassword) => {
  return CryptoJS.AES.decrypt(encryptPassword, 'secret').toString(CryptoJS.enc.Utf8)
}
