import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect, useState } from "react";
import { PDF_FONT_SIZE } from "../../../constants";

const PersonalInformation = ({ names, person }) => {
  const [leng, setLeng] = useState([]);

  useEffect(() => {
    if (person.languages !== "") {
      setLeng(JSON.parse(person.languages));
    }
  }, []);

  return (
    <View style={{ width: "96%", borderBottom: 0.5 }}>
      <View style={[styles.row, { marginTop: 5, borderRight: 0.5 }]}>
      <Text
          style={[
            styles.normalTableText,
            styles.cell,
            { width: "25%", backgroundColor: "#b8cce4" },
          ]}
        >
            COMPLETE HOME 
            ADDRESS
          </Text>
          <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", paddingLeft: 4}
            ]}>
              {person.customeraddress}
            </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", textAlign: "left", paddingLeft: 4, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          NEARLY AIRPORT
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%", paddingLeft: 4},
          ]}
        >
           {person.nearly_airport}
        </Text>
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
      <Text
          style={[
            styles.normalTableText,
            styles.cell,
            { width: "15%", backgroundColor: "#b8cce4" },
          ]}
        >
            PHONE/CELL
          </Text>
          <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"}
            ]}>
            +{person.countrycode} {person.phone}
            </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          WHATSAPP
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"},
          ]}
        >
          {person.whatsapp}
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", textAlign: "left", paddingLeft: 4, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          E-MAIL
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%"},
          ]}
        >
         {person.email}
        </Text>
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
      <Text
          style={[
            styles.normalTableText,
            styles.cell,
            { width: "19%", backgroundColor: "#b8cce4" },
          ]}
        >
            LANGUAGES
          </Text>
          <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "19%"}
            ]}>
             ENGLISH
            </Text>
            <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"}
            ]}>
            {leng[0] ? leng[0]["%"] : ""}%
            </Text>
            <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "19%"}
            ]}>
             SPANISH
            </Text>
            <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"}
            ]}>
            {leng[1] ? leng[1]["%"] : ""}%
            </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "18%", textAlign: "left", paddingLeft: 4, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          OTHERS
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%"},
          ]}
        >
        {person.otherlanguajes}
        </Text>
        <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"}
            ]}>
            {person.percentagelanguajes}%
            </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center"
  },
  cell: {
    display: "flex",
    borderLeft: 0.5,
    borderColor: "black",
    minHeight: 23,
  },
  normalTableText: {
    fontSize: PDF_FONT_SIZE,
    paddingTop: 5,
  },
  verticalCell: {
    display: "flex",
  },
});

export default PersonalInformation;
