import React, { Component, useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Title from "../Commons/Title";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import { isEmpty, now } from "lodash";
import moment from "moment";
import { getEmbarkations } from "../../Actions/Actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import Button from "@mui/material/Button";
import LoadingProgress from "../Commons/LoadingProgress";
import { TextField } from "@mui/material";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import _ from "lodash";

export default function EmbarcationTimeLine() {
	const [list, setlist] = useState([]);
	const [backUpList, setBackUpList] = useState([]);
	const [valuemain, setValuemain] = React.useState(0);
	const [loading, setloading] = useState(false);
	const [filterText, setFilterText] = useState("");

	useEffect(() => {
		const loadData = async () => {
			await loadEmbarkations();
		};

		loadData();
	}, []);

	useEffect(() => {
		if (filterText === "" && list.length === 0) return;
		setlist([...backUpList]);
	}, [filterText]);

	const handleSearch = (event) => {
		if (filterText === "") {
			setlist([...backUpList]);
			return;
		}
		let data = list.filter((item) => {
			if (item.x.includes(filterText.toLocaleUpperCase())) {
				return item;
			}
		});
		setlist([...data]);
	};

	const getFillColor = (embarkation) => {
		if (embarkation.signoffreason_detail.toUpperCase() === "MEDICAL LEAVE")
			return "#f44336";
		return !isEmpty(embarkation.signoffdate) ? "#009688" : "#FFA000";
	};

	const loadEmbarkations = async () => {
		setloading(true);
		const response = await getEmbarkations();
		let embarcationlist = [];
		response.map((item) => {
			if (item.signondate) {
				const obj = {
					x: item.fullname ? item.fullname.toUpperCase() : "",
					y: [
						new Date(item.signondate).getTime(),
						item.signoffdate
							? new Date(item.signoffdate).getTime()
							: new Date().getTime(),
					],
					fillColor: getFillColor(item),
					name: item.VesselName,
				};
				embarcationlist.push(obj);
			}
		});

		setBackUpList(embarcationlist);
		setlist(embarcationlist);
		setloading(false);
	};

	const state = {
		series: [
			{
				data: list,
			},
		],
		options: {
			chart: {
				height: 350,
				type: "rangeBar",
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			xaxis: {
				type: "datetime",
			},
			dataLabels: {
				enabled: true,
				formatter: function (val, opts) {
					var label = !isEmpty(_.get(list[opts.dataPointIndex], "name", ""))
						? list[opts.dataPointIndex].name.toUpperCase()
						: "";
					var a = moment(val[0]);
					var b = moment(val[1]);
					var diff = b.diff(a, "months") + 1;
					return label + ": " + diff + (diff > 1 ? " Months" : " Month");
				},
				style: {
					colors: ["#f3f4f5", "#fff"],
				},
			},
		},
	};

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
				<Grid item xs={12} md={8} lg={9}>
					<Paper
						sx={{
							p: 2,
							display: "flex",
							flexDirection: "column",
						}}>
						<Grid container>
							<Grid container>
								<Grid item xs={6}>
									<Title>EMBARKATIONS BY PERSON</Title>
								</Grid>

								<Grid xs={2}>
									<Button
										variant="contained"
										startIcon={<CheckCircleIcon />}
										size={"medium"}
										sx={{ backgroundColor: "#009688", mr: 2 }}>
										COMPLETED
									</Button>
								</Grid>
								<Grid xs={2}>
									<Button
										variant="contained"
										startIcon={<DirectionsBoatIcon />}
										size={"medium"}
										sx={{ backgroundColor: "#FFA000", mr: 2 }}>
										ON BOARD
									</Button>
								</Grid>
								<Grid xs={2}>
									<Button
										variant="contained"
										startIcon={<MedicalServicesIcon />}
										size={"medium"}
										color="error">
										MEDICAL LEAVE
									</Button>
								</Grid>
								<Grid xs={4}>
									<Box sx={{ display: "flex", alignItems: "center" }}>
										<TextField
											id="standard-basic"
											value={filterText}
											label="Filter"
											variant="standard"
											sx={{ ml: 2 }}
											fullWidth
											onChange={(e) => setFilterText(e.target.value)}
										/>
										<ManageSearchIcon
											fontSize="large"
											sx={{ mt: 2, cursor: "pointer" }}
											onClick={handleSearch}
										/>
									</Box>
								</Grid>
							</Grid>
							<Grid item sx={{ mt: 2 }}>
								<div className="bar">
									<Chart
										options={state.options}
										series={state.series}
										type="rangeBar"
										height={2000}
										width={1024}
									/>
								</div>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<LoadingProgress loading={loading} />
			</Container>
		</Box>
	);
}
