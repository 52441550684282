import React, { useState } from "react";
import { Route, Redirect, Navigate } from "react-router-dom";

import DrawerComponent from "./DrawerComponent";

export default function PrivateRoute() {
  const [islogin, setislogin] = useState(false);

  const account = sessionStorage.getItem("user");

  return account ? <DrawerComponent /> : <Navigate to="/login" />;
}
