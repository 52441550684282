export const TEMPLATE = [
	{
		id: "NEW_USER",
		body: `<style>*{margin: 0;padding: 0;}</style><div style="background-color: #92b2df; text-align: center;"><img src="https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/img.png?alt=media&token=77f5572c-420d-48d4-b9fd-670ca5a1ce74" style="height: 90px; margin-right: 10px;vertical-align: middle;padding: 10px 0px;" alt=""><span style="font-size: 40px; font-weight: 700;">LOGISTIC INTERNATIONAL SERVICES</span></div><div><div style="text-align: center;"><h2 style="margin-top: 15px;">WELCOME TO LOGISTIC INTERNATIONAL SERVICES</h2></div><div style="padding: 20px 0px;"><h4>Thanks to for signing up. Your user has been created.</h4><h4>Please enter to <a href="https://portal.panamamarinelogistic.com.pa/">link</a></h4><h4>User: $fullname</h4><h4>Password: $password</h4><h4>If you have any questions about your account or any other matter, please feel free to contact us at info@mp-ip.edu.pa.</h4><h4>Kind regards.</h4></div></div><div style="margin-top: 10px;"><h4>Recruitment Department</h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Telephones. (507) 373-4907 / 373-4908 / 788-6596/6592</h4><h4>Mobiles. (507) 6717-2054 / 6962-2742 / 6781-6847 </h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Urbanización Perejil, Calle 3era, Edificio PH Barcelona, planta baja, Local #1. P. O. BOX.  0819-07420</h4> 
    </div>`,
		subject: "Welcome to Logistic International Services CORP. Portal",
	},
	{
		id: "NEW_REQUEST_COMPANY",
		body: `<div style="background-color: #92b2df; text-align: center;"><img src="https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/img.png?alt=media&token=77f5572c-420d-48d4-b9fd-670ca5a1ce74" style="height: 90px; margin-right: 10px;vertical-align: middle;padding: 10px 0px;" alt=""><span style="font-size: 40px; font-weight: 700;">LOGISTIC INTERNATIONAL SERVICES</span></div><div><style>*{margin: 0;padding:0;}</style><div style="text-align: center;"><h2 style="margin-top: 15px;">YOUR REQUEST IS CONFIRMED</h2></div><div style="padding: 20px 0px;"><h4>Hi, $company_name</h4><h4>Thank you for trusting us. Our team will be processing your request as soon as we can.</h4><h4>If you have any questions about your account or any other matter, please feel free to contact us at info@mp-ip.edu.pa .</h4><h4>Kind regards.</h4></div></div><div style="margin-top: 10px;"><h4>Recruitment Department</h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Telephones. (507) 373-4907 / 373-4908 / 788-6596/6592</h4><h4>Mobiles. (507) 6717-2054 / 6962-2742 / 6781-6847 </h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Urbanización Perejil, Calle 3era, Edificio PH Barcelona, planta baja, Local #1. P. O. BOX.  0819-07420</h4></div>`,
		subject: "Thank you for trusting us.",
	},
	{
		id: "NEW_REQUEST_STAFF",
		body: `<div style="background-color: #92b2df; text-align: center;"><img src="https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/img.png?alt=media&token=77f5572c-420d-48d4-b9fd-670ca5a1ce74" style="height: 90px; margin-right: 10px;vertical-align: middle;padding: 10px 0px;" alt=""><span style="font-size: 40px; font-weight: 700;">LOGISTIC INTERNATIONAL SERVICES</span></div><div><style>*{margin: 0;padding: 0;}</style><div style="text-align: center;"><h2 style="margin-top: 15px;">DEAR PANAMA, MARINE TEAM</h2></div><div style="padding: 20px 0px;"><h4>“ $company_name ” has made a staff request on the Portal. Please, make sure to process the petition. </h4></div></div><div style="margin-top: 10px;"><h4>Recruitment Department</h4>  <h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Telephones. (507) 373-4907 / 373-4908 / 788-6596/6592</h4><h4>Mobiles. (507) 6717-2054 / 6962-2742 / 6781-6847 </h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Urbanización Perejil, Calle 3era, Edificio PH Barcelona, planta baja, Local #1. P. O. BOX.  0819-07420</h4></div>`,
		subject: "NEW REQUEST FROM LOGISTIC INTERNATIONAL SERVICES CORP. PORTAL",
	},
	{
		id: "UPDATE_PASSWORD",
		body: "<p>Your password has changed in LOGISTIC INTERNATIONAL SERVICES CORP.&nbsp;</p><p>Please enter to <a href='portal.mp-ip.edu.pa'>link&nbsp;</a></p><p>User: $fullname </p><p>Password: $password </p><p>If you have any question, please send a email to&nbsp;<span>info@mp-ip.edu.pa</span></p><p></p><p>Thanks</p><p>LOGISTIC INTERNATIONAL SERVICES CORP.</p><p><img src='https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/logo.jpg?alt=media&token=697d8deb-1177-433d-80c6-67697884c3d5' alt='logo' width='149' height='145' /></p>",
		subject: "Password Changed in Logistic International Services Corp. Portal",
	},
	{
		id: "UPDATE_INFO",
		body: `<div style="background-color: #92b2df; text-align: center;"><img src="https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/img.png?alt=media&token=77f5572c-420d-48d4-b9fd-670ca5a1ce74" style="height: 90px; margin-right: 10px;vertical-align: middle;padding: 10px 0px;" alt=""><span style="font-size: 40px; font-weight: 700;">LOGISTIC INTERNATIONAL SERVICES</span></div><div><div style="text-align: center;"><h2 style="margin-top: 15px;color: red;">UPDATE INFORMATION</h2></div><div style="padding: 20px 0px;"><h4>Dear, $fullname . We greet you from LOGISTIC INTERNATIONAL SERVICES team.</h4><h4>Please update your info in Logistic International Services Portal </h4><h4>Please click in following link: <a href="$link">link</a></h4><h4>If you have any questions about your account or any other matter, please feel free to contact us at info@mp-ip.edu.pa .</h4><h4>Kind regards.</h4></div></div><div style="margin-top: 10px;"><h4>Recruitment Department</h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Telephones. (507) 373-4907 / 373-4908 / 788-6596/6592</h4><h4>Mobiles. (507) 6717-2054 / 6962-2742 / 6781-6847 </h4><h4>LOGISTIC INTERNATIONAL SERVICES</h4><h4>Urbanización Perejil, Calle 3era, Edificio PH Barcelona, planta baja, Local #1. P. O. BOX.  0819-07420</h4> 
    </div>`,
		subject: "UPDATE INFO REQUEST FOR LOGISTIC INTERNATIONAL SERVICES",
	},
];
