import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme();

export default function AddOtherTrainings({
  open,
  handleClose,
  data,
  onChange,
  handleChangeCertIssueDate,
  handleChangeCertExpiredDate,
  loading,
  handleFileChange,
  addnew,
  isedit,
  edit,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper
              sx={{
                width: 0.5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: 5,
              }}>
              <Grid>
                <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                  <CloseOutlinedIcon onClick={() => handleClose()} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  <Title>{data.id}</Title>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  {isedit ? (
                    <Title>UPDATE</Title>
                  ) : (
                    <Title>ADD OTHER CERTIFICATE / COURSE</Title>
                  )}
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                    pr: 5,
                    pl: 5,
                  }}>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">
                      Name of education institution / tech. institute
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <TextField
                      name="Name of education institution / tech. institute"
                      id="description"
                      autoFocus
                      variant="standard"
                      onChange={onChange}
                      fullWidth
                      value={
                        data["Name of education institution / tech. institute"]
                      }
                    />
                  </Grid>

                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">
                      Obtained title or grade
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <TextField
                      name="Obtained title or grade"
                      id="number"
                      autoFocus
                      variant="standard"
                      onChange={onChange}
                      fullWidth
                      value={data["Obtained title or grade"]}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">Date on </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={data["Date on"]}
                      onChange={handleChangeCertIssueDate}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">Date off </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={data["Date off"]}
                      onChange={handleChangeCertExpiredDate}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div>
                      <input
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        onChange={handleFileChange}
                      />
                    </div>
                  </Grid> */}
                  <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                    {isedit ? (
                      <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                        {!loading ? (
                          "UPDATE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ mr: 5 }}
                        onClick={addnew}>
                        {!loading ? (
                          "ADD NEW CERTIFICATE / COURSE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </Modal>
  );
}
