import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { getData, getGapPool } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { escapeRegExp, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Checkbox,
  TextField,
  Typography,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { createStyles, makeStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import * as XLSX from "xlsx";
import moment from "moment";
import { ButtonCleanFilters } from "../Buttons/Button";
import { Label } from "../Input/Input";
import DropDown from "../DropDown/DropDown";
import GetAppIcon from "@mui/icons-material/GetApp";

export const ExpiredDocuments = (props) => {
  const [checked, setChecked] = useState(false);
  const [data, setdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [backData, setBackData] = useState([]);
  const [time, settime] = useState(12);
  const [alldocuments, setalldocuments] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [vesseltypefilter, setvesseltypefilter] = useState("");
  const [vesselTypelist, setvesseltypelist] = useState([]);
  const [statusfilter, setstatusfilter] = useState("");
  const [statuslist, setstatuslist] = useState([]);
  const [expiredfilter, setexpiredfilter] = useState("");
  const [expiredlist, setexpiredlist] = useState([]);


  const columns = [
    {
      field: "pmssa",
      headerName: "#",
      width: 20,
    },
    {
      field: "category_vessel",
      headerName: "Vessel Type",
      width: 180,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 300,
    },

    {
      field: "doctype",
      headerName: "Doc Type",
      width: 250,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
    },
    // {
    //   field: "contryissue",
    //   headerName: "Country Issue",
    //   width: 150,
    // },
    // {
    //   field: "issueatplace",
    //   headerName: "Issue At Place",
    //   width: 170,
    // },

    // {
    //   field: "number",
    //   headerName: "Number",
    //   width: 100,
    // },

    // {
    //   field: "dateofissue",
    //   headerName: "Date of Issue",
    //   width: 110,
    // },
    {
      field: "expirydate",
      headerName: "Expiry Date",
      width: 130,
    },
    {
      field: "months",
      headerName: "Status",
      width: 150,
      renderCell: (params) => <DrawerStatus params={params} />,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "id",
      headerName: "Acciones",
      width: 120,
      renderCell: (params) => (
        <Link
          to={{
            pathname: `/updatepersonalinfo?id=${params.row.pmssa}`,
            state: { id: params.row.pmssa },
          }}
          reloadDocument
        >
          <EditIcon style={{ fontSize: 25 }} color="primary" />
        </Link>
      ),
      sortable: false,
    },
  ];

  const handleDownloadXlsx = () => {
    let excelData = [];
    if (data.length < 1) return;
    filteredData.forEach((item) => {
      excelData.push({
        pmssa: item.pmssa,
        status: item.status,
        fullname: item.fullname,
        category_vessel: item.category_vessel,
        doctype: item.doctype,
        description: item.description,
        contryissue: item.contryissue,
        issueatplace: item.issueatplace,
        number: item.number,
        dateofissue: item.dateofissue,
        expirydate: item.expirydate,
        months: item.months,
      });
    });
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "PMSSA",
          "Status",
          "Full name",
          "Vessel type",
          "Document type",
          "Description",
          "Country of issue",
          "Issue at place",
          "Number",
          "Date of issue",
          "Expiry date",
          "Months",
        ],
      ],
      {
        origin: "A1",
      }
    );
    const max_width = filteredData.reduce((w, r) => Math.max(w, r.fullname.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];
    XLSX.writeFile(workbook, "Expiring documents.xlsx");
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

 
  const DrawerStatus = ({ params }) => {
    if (Number(params.row.months) < 0) {
      return (
        <>
          <NewReleasesIcon sx={{ color: "red" }} />
          <Typography>Expired</Typography>
        </>
      );
    } else if (params.row.months === null) {
      return (
        <>
          <MoreHorizIcon sx={{ color: "gray" }} />
          <Typography>Empty</Typography>
        </>
      );
    } else if (Number(params.row.months) <= time) {
      return (
        <>
          <VisibilityIcon sx={{ color: "orange" }} />
          <Typography> {params.row.months} M to expire</Typography>
        </>
      );
    } else {
      return (
        <>
          <CheckCircleIcon sx={{ color: "green" }} />
          <Typography> {params.row.months} M to expire</Typography>
        </>
      );
    }
  };

  const handleSliderChange = (event, newValue) => {
    settime(newValue);
  };


  useEffect(() => {
    const loadResults = async () => {
      setloading(true);
      let documentlist = [];
      const resultado = await getGapPool();
      let uniquestatus = [...new Set(resultado.map((item) => item.status))].filter((status, index, array) => status !== "" && array.indexOf(status) === index);
      let uniquevessel = [...new Set(resultado.map(item => item.category_vessel))].filter((vessel, index, array) => vessel !== "" && array.indexOf(vessel) === index);
      setvesseltypelist(uniquevessel);
      setstatuslist(uniquestatus);
      resultado.map((item) => {
        addDocs(item, documentlist);
        addCOC(item, documentlist);
        addtrainings(item, documentlist);
        //	addothertrainings(item, documentlist);
      });
      
      let total = documentlist.filter(
        (item) =>
          item.months <= time &&
          item.months > 0 &&
          item.status !== "CONTRACT REVOKED"
      );

      total.sort((a, b) => a.months - b.months);
      let uniqueexpired = [...new Set(total.map((item) => item.expirydate))];
      setexpiredlist(uniqueexpired);
      documentlist.sort(compare);
      setalldocuments(documentlist);
      setBackData(total);
      setdata(total);
      setloading(false);
    };

    loadResults();
  }, [time]);

  
  function compare(a, b) {
    if (a.months < b.months) {
      return -1;
    }
    if (a.months > b.months) {
      return 1;
    }
    return 0;
  }

  const addDocs = async (item, documentlist) => {
    if (!isEmpty(item.type_document)) {
      let typedocuments = JSON.parse(item.type_document);
      typedocuments.map((documents) => {
        let dato = {
          id: uuidv4(),
          pmssa: item.Id,
          status: item.status,
          fullname: item.fullname,
          category_vessel: item.category_vessel,
          doctype: "PERSONAL DOCUMENT",
          description: documents["Type of Document"],
          contryissue: documents["Country of Issue"],
          issueatplace: documents["Issue at (place)"],
          number: documents["No."],
          dateofissue: documents["Date of Issue"],
          expirydate: documents["Valid Until"],
          months: !isEmpty(documents["Valid Until"])
            ? getMonthDifference(new Date(), new Date(documents["Valid Until"]))
            : null,
        };
        documentlist.push(dato);
      });
    }
  };
  const addCOC = async (item, documentlist) => {
    if (!isEmpty(item.COC)) {
      let typedocuments = JSON.parse(item.COC);
      typedocuments.map((documents) => {
        let dato = {
          id: uuidv4(),
          pmssa: item.Id,
          fullname: item.fullname,
          category_vessel: item.category_vessel,
          status: item.status,
          doctype: "COC",
          description: "COC",
          contryissue: documents["Country of issue"],
          issueatplace: documents["Issue at (place)"],
          number: documents["No."],
          dateofissue: documents["Date of issue"],
          expirydate: documents["Valid until"],
          months: !isEmpty(documents["Valid until"])
            ? getMonthDifference(new Date(), new Date(documents["Valid until"]))
            : null,
        };
        documentlist.push(dato);
      });
    }
  };

  const addtrainings = async (item, documentlist) => {
    if (!isEmpty(item.lic_sbook_stcw_certificates)) {
      let typedocuments = JSON.parse(item.lic_sbook_stcw_certificates);
      typedocuments.map((documents) => {
        let dato = {
          id: uuidv4(),
          pmssa: item.Id,
          fullname: item.fullname,
          category_vessel: item.category_vessel,
          status: item.status,
          doctype: "TRAINING AND CERTIFICATION",
          description: documents["Description of cert / course"],
          contryissue: documents["Country of issue"],
          issueatplace: documents["Country of issue"],
          number: documents["Number"],
          dateofissue: documents["Date of issue"],
          expirydate: documents["Date of expiry"],
          months: !isEmpty(documents["Date of expiry"])
            ? getMonthDifference(
                new Date(),
                new Date(documents["Date of expiry"])
              )
            : null,
        };
        documentlist.push(dato);
      });
    }
  };
  const addothertrainings = async (item, documentlist) => {
    if (!isEmpty(item.other_training)) {
      let typedocuments = JSON.parse(item.other_training);
      typedocuments.map((documents) => {
        let dato = {
          id: uuidv4(),
          pmssa: item.Id,
          fullname: item.fullname,
          status: item.status,
          doctype: "OTHER TRAININGS",
          description:
            documents["Name of education institution / tech. institute"],
          grade: documents["Obtained title or grade"],
          dateofissue: documents["Date on"],
          expirydate: documents["Date off"],
          months: !isEmpty(documents["Date of expiry"])
            ? getMonthDifference(
                new Date(),
                new Date(documents["Date of expiry"])
              )
            : null,
        };
        documentlist.push(dato);
      });
    }
  };

  function getMonthDifference(startDate, endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  }

  const marks = [
    {
      value: 6,
      label: "6 M",
    },
    {
      value: 12,
      label: "12 M",
    },
    {
      value: 18,
      label: "18 M",
    },
    {
      value: 24,
      label: "24 M",
    },
  ];

  function valuetext(value) {
    return `${value} M`;
  }

 const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      let total = [...backData];
      if (!checked) {
        let newTotal = alldocuments.filter((item) => item.months > 0);
        total.sort((a, b) => a.months - b.months);
        setFilteredData(newTotal);
        setSearchText(searchValue);
        return;
      }
      setSearchText(searchValue);
      setFilteredData(backData);
    } else {
      setSearchText(searchValue);
      const filteredRows = alldocuments.filter((item) => {
        return item.fullname.toUpperCase().includes(searchValue.toUpperCase());
      });
      setFilteredData(filteredRows);
    }
  };

  useEffect(() => {
    if (!checked) {
      const filteredDataBackend = backData.filter((item) => {
        const expirationDate = moment(item.expirydate);
        const selectedDate = moment(expiredfilter);
  
        const filterVessel =
          vesseltypefilter !== ""
            ? item.category_vessel.toUpperCase() === vesseltypefilter.toUpperCase()
            : true;
  
        const filterExpired =
          expiredfilter !== ""
            ? expirationDate.isSameOrAfter(selectedDate.startOf("month")) &&
              expirationDate.isSameOrBefore(selectedDate.endOf("month"))
            : true;
  
        const filterStatus =
          statusfilter !== ""
            ? item.status.toUpperCase() === statusfilter.toUpperCase()
            : true;
  
        return filterVessel && filterExpired && filterStatus;
      });

      setFilteredData(filteredDataBackend)
      if (searchText === "") {
        setFilteredData(filteredDataBackend);
      } else {
        const filteredRows = filteredDataBackend.filter((item) => {
          return item.fullname.toUpperCase().includes(searchText.toUpperCase());
        });
        setFilteredData(filteredRows);
      }
    } else  {
      const total = alldocuments.filter((item) => {
        const documentexpired =
          item.months <= time &&
          item.status !== "CONTRACT REVOKED" &&
          (item.months <= 0 || item.months === null);
        const expirationDate = moment(item.expirydate);
        const selectedDate = moment(expiredfilter);
  
        const filterVessel =
          vesseltypefilter !== ""
            ? item.category_vessel.toUpperCase() === vesseltypefilter.toUpperCase()
            : true;
  
        const filterExpired =
          expiredfilter !== ""
            ? expirationDate.isSameOrAfter(selectedDate.startOf("month")) &&
              expirationDate.isSameOrBefore(selectedDate.endOf("month"))
            : true;
  
        const filterStatus =
          statusfilter !== ""
            ? item.status.toUpperCase() === statusfilter.toUpperCase()
            : true;
  
        return documentexpired && filterVessel && filterExpired && filterStatus;
      });

      total.sort((a, b) => a.months - b.months);
      setFilteredData(total);
      if (searchText === "") {
        setFilteredData(total);
      } else {
        const filteredRows = total.filter((item) => {
          return item.fullname.toUpperCase().includes(searchText.toUpperCase());
        });
        setFilteredData(filteredRows);
      }
    }
       
  }, [checked, backData, vesseltypefilter, statusfilter, expiredfilter, alldocuments, time, searchText]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Title>Expired Documents</Title>
            <Box sx={{ width: 300 }}>
              <Slider
                aria-label="Time"
                defaultValue={6}
                getAriaValueText={valuetext}
                step={1}
                marks={marks}
                valueLabelDisplay="on"
                onChange={handleSliderChange}
                onChangeCommitted={handleSliderChange}
                value={time}
                max={24}
              />
            </Box>
            <Grid container spacing={1} sx={{ mt: 4 }} justifyContent="center">
              <Grid item xs={3}>
               <Label 
                 text='Filter by Expired Date'
                />
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="month"
                  size={"small"}
                  value={expiredfilter}
                  onChange={(e) => setexpiredfilter(e.target.value)}
                />
              </Grid>
              <Grid item xs={3}>
                <Label 
                  text='Filter by Vessel Type'
                 />
                 <DropDown
                  name='vesseltype'
                  label='vesseltype'
                  value={vesseltypefilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setvesseltypefilter(e.target.value)}
                  lists={vesselTypelist}
                 />
             </Grid>
              <Grid item xs={3}>
               <Label 
                 text='Filter by Status'
                 />
                <DropDown 
                  name='status'
                  label='status'
                  value={statusfilter}
                  style={{ width: 200, mr: 2, mb: 2 }}
                  change={(e) => setstatusfilter(e.target.value)}
                  lists={statuslist}
                 />
              </Grid>
              <Grid
               item 
               xs={12}
               container
               direction="row"
               justifyContent="right"
               alignItems="center"
               sx={{ marginBottom: 2 }}
              >
                <ButtonCleanFilters
                  style={{ ml: "auto", cursor: "pointer" }}
                  click={() => {
                    setdata(backData);
                    setstatusfilter("");
                    setvesseltypefilter("");
                    setexpiredfilter("");
                    setSearchText("");
                    setChecked("");
                  }}
                />
                <Button
                  variant="outlined"
                  startIcon={<GetAppIcon fontSize="large" color="sucess" />}
                  sx={{ ml: 2, cursor: "pointer" }}
                  color="success"
                  onClick={handleDownloadXlsx}
                >
                  Export to Excel
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={filteredData}
              columns={columns} 
              autoPageSize={true}
              loading={loading}
              sx={{
                boxShadow: 2,
                display: "flex",
              }}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {setSearchText(event.target.value)},
                  //clearSearch: () => requestSearch(""),
                  checked,
                  handleChange,
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
};

function CustomToolbar(props) {
  const classes = useStyles();
  return (
    <GridToolbarContainer>
       <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          ml: 2,
        }}
      >
        <Typography variant="body1" color="initial">
          Show expired documents:
        </Typography>
        <Checkbox
          checked={props.checked}
          onChange={props.handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
        </Box>
      <Box
        sx={{ marginLeft: "auto" }}
      >
      </Box>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
        }}
      />
    </GridToolbarContainer>
  );
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

export default ExpiredDocuments;
