import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export default function CocTable({
  data,
  addNew,
  deleterecord,
  edit,
  isview,
  disableAdd,
  checkForNewFiles,
  dbData,
  position,
}) {
  let datos = isEmpty(data) ? [] : JSON.parse(data);

  const checkfileExist = (obj) => {
    if (isview !== 1) {
      if (dbData[0].COC === "") return false;
      let cocArray = JSON.parse(dbData[0].COC);
      const isExist = cocArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
    return true;
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ marginBottom: 2 }}>
          {disableAdd ? (
            ""
          ) : (
            <>
              <Button variant="contained" onClick={addNew}>
                ADD +
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>COC DOC.</TableCell>
            {position && position.includes("CADET") && (
              <TableCell>Grade of license (if apply)</TableCell>
            )}
            <TableCell>Country of issue</TableCell>
            <TableCell>Issue at (place)</TableCell>
            <TableCell>No.</TableCell>
            <TableCell>Date of issue</TableCell>
            <TableCell>Valid until</TableCell>
            {isview !== 1 && (
              <>
                <TableCell>Edit.</TableCell>
                <TableCell>Del.</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {datos.map((row, index) => (
            <>
              <TableRow key={index}>
                {checkForNewFiles ? (
                  <>
                    {checkfileExist(row) ? (
                      <>
                        <TableCell>{row["COC DOC."]}</TableCell>
                        {position && position.includes("CADET") && (
                          <TableCell>
                            {row["Grade of license (if apply)"]}
                          </TableCell>
                        )}
                        <TableCell>{row["Country of issue"]}</TableCell>
                        <TableCell>{row["Issue at (place)"]}</TableCell>
                        <TableCell>{row["No."]}</TableCell>
                        <TableCell>{row["Date of issue"]}</TableCell>
                        <TableCell>{row["Valid until"]}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell>{row["COC DOC."]}</TableCell>
                        {position && position.includes("CADET") && (
                          <TableCell>
                            {row["Grade of license (if apply)"]}
                          </TableCell>
                        )}
                        <TableCell sx={{ color: "blue" }}>
                          {row["Country of issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Issue at (place)"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["No."]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date of issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Valid until"]}
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{row["COC DOC."]}</TableCell>
                    {position && position.includes("CADET") && (
                      <TableCell>
                        {row["Grade of license (if apply)"]}
                      </TableCell>
                    )}
                    <TableCell>{row["Country of issue"]}</TableCell>
                    <TableCell>{row["Issue at (place)"]}</TableCell>
                    <TableCell>{row["No."]}</TableCell>
                    <TableCell>{row["Date of issue"]}</TableCell>
                    <TableCell>{row["Valid until"]}</TableCell>
                  </>
                )}

                {isview !== 1 && (
                  <>
                    <TableCell>
                      <ModeEditIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          edit(row);
                        }}></ModeEditIcon>
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let resp = window.confirm(
                            "Do you want delete this document?"
                          );
                          if (resp) {
                            deleterecord(datos, row);
                          }
                        }}></DeleteIcon>
                    </TableCell>{" "}
                  </>
                )}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
