import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

function Copyright() {
	return (
		<Typography variant="body2" color="text.secondary">
			{"Copyright © "}
			<Link color="inherit" href="https://mui.com/">
				Your Website
			</Link>{" "}
			{new Date().getFullYear()}
			{"."}
		</Typography>
	);
}

export default function StickyFooter() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				minHeight: "100vh",
				backgroundColor: "#fff",
			}}>
			<CssBaseline />
			<Container component="main" sx={{ mt: 20, mb: 2 }} maxWidth="sm">
				<Typography variant="h2" component="h1" gutterBottom>
					Disabled option
				</Typography>
				<Typography variant="h5" component="h2" gutterBottom>
					{
						"Please contact info@mp-ip.edu.pa to re-enable access to request personnel."
					}
				</Typography>
				<Typography variant="body1">Thank You</Typography>
			</Container>
			{/* <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="body1">
            My sticky footer can be found here.
          </Typography>
          <Copyright />
        </Container>
      </Box> */}
		</Box>
	);
}
