import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { loadCompanies } from "../../Actions/Actions";
import _, { size, isEmpty } from "lodash";

const CompaniesSelect = ({ data, changeCompany, isview }) => {
  const [companies, setcompanies] = useState([]);

  const loadCompanie = async () => {
    const companieslist = await loadCompanies();
    setcompanies(companieslist);
  };

  useEffect(() => {
    loadCompanie();
  }, []);

  return (
    <Box>
      <FormControl fullWidth>
        <Select
          labelId="companyselect"
          id="companyselect"
          name="company"
          disabled={isview}
          value={_.get(data, "company", "Reederei Nord").toUpperCase()}
          label="Company"
          onChange={(e) => changeCompany(e)}
          fullWidth
          variant="standard">
          {companies.map((item) => (
            <MenuItem value={item.companyname.toUpperCase()} key={item.Id}>
              {item.companyname.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CompaniesSelect;
