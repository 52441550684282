import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { DataGrid } from "@mui/x-data-grid";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Title from "./Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const style = {};

const columns = [
  {
    field: "Id",
    headerName: "Id",
    width: 10,
    editable: true,
  },
  {
    field: "fullname",
    headerName: "Name",
    width: 250,
    editable: true,
  },
  {
    field: "category_vessel",
    headerName: "Vessel Category",
    width: 150,
    editable: true,
  },
  {
    field: "department",
    headerName: "Department",
    width: 150,
    editable: true,
  },
  {
    field: "position_applying",
    headerName: "Position",
    width: 170,
    editable: true,
  },

  {
    field: "sex",
    headerName: "Gender",
    width: 100,
    editable: true,
  },
  {
    field: "id",
    headerName: "View",
    width: 120,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/viewuser?id=${params.value}`,
          state: { id: params.value },
        }}
      >
        <AccountCircle style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },
];

export default function ModalPersonal({
  open,
  handleOpen,
  handleClose,
  data,
  setchoose,
  addStaffToRequest,
}) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.9,

            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Title>Please Choose Staff</Title>
            </Grid>

            <Grid item xs={12}>
              <DataGrid
                rows={data}
                columns={columns}
                autoPageSize={true}
                sx={{
                  boxShadow: 2,
                  height: "60vh",
                  marginRight: 5,
                  marginLeft: 5,
                }}
                checkboxSelection
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  const selectedRowData = data.filter((row) =>
                    selectedIDs.has(row.id.toString())
                  );
                  setchoose(selectedRowData);
                }}
              />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mb: 4 }}>
              <Button
                variant="contained"
                onClick={() => {
                  addStaffToRequest();
                }}
              >
                Add Staff to Request
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
