import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export default function OtherTrainings({
  data,
  addNew,
  deleterecord,
  edit,
  isview,
  dbData,
  checkForNewFiles,
}) {
  let datos = isEmpty(data) ? [] : JSON.parse(data);
  
  const checkfileExist = (obj) => {
    if (isview !== 1) {
      if (dbData[0].other_training === "") return false;
      let documentArray = JSON.parse(dbData[0].other_training);
      const isExist = documentArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
    return true;
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ marginBottom: 2 }}>
          <Button variant="contained" onClick={addNew}>
            ADD +
          </Button>
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              Name of education institution / tech. institute
            </TableCell>
            <TableCell>Obtained title or grade</TableCell>
            <TableCell>Date on</TableCell>
            <TableCell>Date off</TableCell>
            {isview !== 1 && (
              <>
                <TableCell>Edit.</TableCell>
                <TableCell>Del.</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {datos.map((row) => (
            <TableRow key={Math.random()}>
              {checkForNewFiles ? (
                <>
                  {checkfileExist(row) ? (
                    <>
                      <TableCell>
                        {row["Name of education institution / tech. institute"]}
                      </TableCell>
                      <TableCell>{row["Obtained title or grade"]}</TableCell>
                      <TableCell>{row["Date on"]}</TableCell>
                      <TableCell>{row["Date off"]}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell sx={{ color: "blue" }}>
                        {row["Name of education institution / tech. institute"]}
                      </TableCell>
                      <TableCell sx={{ color: "blue" }}>
                        {row["Obtained title or grade"]}
                      </TableCell>
                      <TableCell sx={{ color: "blue" }}>
                        {row["Date on"]}
                      </TableCell>
                      <TableCell sx={{ color: "blue" }}>
                        {row["Date off"]}
                      </TableCell>
                    </>
                  )}
                </>
              ) : (
                <>
                  <TableCell>
                    {row["Name of education institution / tech. institute"]}
                  </TableCell>
                  <TableCell>{row["Obtained title or grade"]}</TableCell>
                  <TableCell>{row["Date on"]}</TableCell>
                  <TableCell>{row["Date off"]}</TableCell>
                </>
              )}
              {isview !== 1 && (
                <>
                  <TableCell>
                    <ModeEditIcon
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        edit(row);
                      }}></ModeEditIcon>
                  </TableCell>
                  <TableCell>
                    <DeleteIcon
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        let resp = window.confirm(
                          "Do you want delete this document?"
                        );
                        if (resp) {
                          deleterecord(datos, row);
                        }
                      }}></DeleteIcon>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
