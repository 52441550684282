import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import Button from "@mui/material/Button";

export const ButtonCleanFilters = ({ click, style }) => {
 return(
   <Button
    variant="outlined"
    startIcon={<FilterAltOffIcon fontSize="large" />}
    sx={style}
    onClick={click}
    >
   CLEAN FILTERS
  </Button>
 )
}

