import React from "react";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function LoadingProgress({ loading }) {
  return (
    <Modal
      open={loading}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ display: "flex" }}>
        <CircularProgress
          size={68}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Box>
    </Modal>
  );
}
