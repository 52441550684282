import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { PDF_FONT_SIZE } from "../../../constants";

const EmergencyContact = ({ names, person, sosContacts }) => {
  return (
    <View style={{ width: "96%", borderBottom: 0.5 }}>
     <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  2. EMERGENCY CONTACT / NEXT OF KIN
                </Text>
    <View style={[styles.row, { borderRight: 0.5 }]}>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "100%", backgroundColor: "#b8cce4" },
      ]}
    >
      EMERGENCY CONTACT / NEXT OF KIN
    </Text>
  </View>
  <View style={styles.row}>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "25%", backgroundColor: "#b8cce4" },
      ]}
    >
      RELATIONSHIP
    </Text>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "25%", backgroundColor: "#b8cce4" },
      ]}
    >
      COMPLETE NAME
    </Text>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "25%", borderRight: 0.5, backgroundColor: "#b8cce4" },
      ]}
    >
      TELEPHONE NUMBER / 
      MOBILE
    </Text>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "25%", borderRight: 0.5, backgroundColor: "#b8cce4" },
      ]}
    >
      ADDRESS
    </Text>
  </View>
  {sosContacts.length > 0 ? (
          <>
            <View style={{ borderRight: 0.5, borderBottom: 0.5 }}>
              {sosContacts.map((row) => {
                return (
                  <View style={[styles.row, { borderRight: 0.5 }]}>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "25%", height: 30, border: 0.5 },
                      ]}
                    >
                      {row.Relationship}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "25%", height: 30, border: 0.5 },
                      ]}
                    >
                      {row.Name} {row["Middle name"]} {row.Surnames}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "25%", height: 30, border: 0.5 },
                      ]}
                    >
                      {row.Phone}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "25%", height: 30, border: 0.5 },
                      ]}
                    >
                      {row.Address}
                    </Text>
                  </View>
                );
              })}
            </View>
          </>
        ) : (
          <>
            <View style={[styles.row, { borderRight: 0.5, borderBottom: 0.5 }]}>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "25%", minHeight: 30 }]}
              ></Text>
              <Text
                style={[styles.cell, styles.normalTableText, { width: "25%", minHeight: 30 }]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "25%", minHeight: 30},
                ]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "25%", minHeight: 30},
                ]}
              ></Text>
            </View>
          </>
        )}
      </View>
)
}

const styles = StyleSheet.create({
    mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
    topSectionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    firstTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 20,
    },
    icon: {
      width: 70,
      height: 90,
      borderWidth: 0.5,
      borderColor: "black",
    },
    row: {
      borderTop: 0.5,
      borderColor: "black",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      textAlign: "center"
    },
    cell: {
      display: "flex",
      borderLeft: 0.5,
      borderColor: "black",
      minHeight: 23,
    },
    normalTableText: {
      fontSize: PDF_FONT_SIZE,
      paddingTop: 5,
    },
    verticalCell: {
      display: "flex",
    },
  });

export default EmergencyContact

