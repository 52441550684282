import React from 'react'
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { PDF_FONT_SIZE } from "../../../constants";


const MarlinTest = ({ names, person}) => {
  return (
    <View style={{ width: "96%", borderRight: 0.5, borderBottom: 0.5 }}>
    <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", height: 20, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          MARLINS / LANGUAGE -TEST
        </Text>
      </View>
      <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", backgroundColor: "#b8cce4" },
          ]}
        >
          TOTAL %
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", backgroundColor: "#b8cce4" },
          ]}
        >
          ISSUE DATE
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          PLACE OF ISSUE
        </Text>
      </View>
      <View style={[styles.row, { borderBottom: 0.5 }]}>
        <Text style={[styles.cell, styles.normalTableText, { width: "60%", minHeight: 15 }]}>
          {person.mt_timeandnumber}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "60%", minHeight: 15 }]}>
          {person.mt_reading}%
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "60%", minHeight: 15, borderRight: 0.5 },
          ]}
        >
          {person.mt_placeofissue}
        </Text>
      </View>
      <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "18%", backgroundColor: "#b8cce4" },
          ]}
        >
          LISTENING
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "18%", backgroundColor: "#b8cce4" },
          ]}
        >
         GRAMMAR
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "18%", backgroundColor: "#b8cce4" },
          ]}
        >
          VOCABULARY
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "18%", backgroundColor: "#b8cce4" },
          ]}
        >
          TIME AND NUMBERS
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "28%", borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          READING
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%", minHeight: 15 }]}>
          {person.mt_total}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%", minHeight: 15 }]}>
          {person.mt_listening}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%", minHeight: 15 }]}>
          {person.mt_grammar}%
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "18%", minHeight: 15 }]}>
          {person.mt_vocabulary}%
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "28%", minHeight: 15, borderRight: 0.5 },
          ]}
        >
          {moment(person.mt_issuedate).isValid()
    ? moment(person.mt_issuedate).format("L")
    : '%'}
        </Text>
      </View>
      </View>
  )
}

const styles = StyleSheet.create({
    mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
    topSectionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    firstTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 20,
    },
    icon: {
      width: 70,
      height: 90,
      borderWidth: 0.5,
      borderColor: "black",
    },
    row: {
      borderTop: 0.5,
      borderColor: "black",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      textAlign: "center"
    },
    cell: {
      display: "flex",
      borderLeft: 0.5,
      borderColor: "black",
      minHeight: 23,
    },
    normalTableText: {
      fontSize: PDF_FONT_SIZE,
      paddingTop: 5,
    },
    verticalCell: {
      display: "flex",
    },
  });

  export default MarlinTest