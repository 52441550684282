import React, { Component } from "react";
import Chart from "react-apexcharts";
import Title from "../Commons/Title";
import Grid from "@mui/material/Grid";
import { isEmpty, now } from "lodash";
export default function EmbarcationTimeLine({ data, title }) {
  let embarcationdata = [];
  data.map((item) => {
    const obj = {
      x: item.VesselName ? item.VesselName.toUpperCase() : "",
      y: [
        new Date(item.signondate).getTime(),
        item.signoffdate
          ? new Date(item.signoffdate).getTime()
          : new Date().getTime(),
      ],
      fillColor: !isEmpty(item.signoffdate) ? "#00E396" : "#FEB019",
    };
    embarcationdata.push(obj);
  });

  const state = {
    series: [
      {
        data: embarcationdata,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "rangeBar",
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        type: "datetime",
      },
    },
  };

  return (
    <Grid container>
      <Grid container>
        <Title>{title}</Title>
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        <div className="bar">
          <Chart
            options={state.options}
            series={state.series}
            type="rangeBar"
            height={350}
            width={850}
          />
        </div>
      </Grid>
    </Grid>
  );
}
