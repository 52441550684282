import React from 'react'
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { numberNextLimiter, sortExperiencesByDate } from "../../../utils/utils";
import moment from "moment";

const OtherCertificates = ({  
  styles,
  person,
  certificates,
  otherTraining,
  lightGrey
}) => {
  return (
    <>
    <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  7. HIGHEST LEVEL OF EDUCATION / OTHER TRAINING OR CERTIFICATE
                </Text>
    <View style={[styles.row, { borderRight: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "100%",
              border: 0.5,
              paddingTop: 5,
              height: 20,
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          HIGHEST LEVEL OF EDUCATION / OTHER TRAINING OR CERTIFICATE
        </Text>
      </View>
      <View style={[styles.row, { borderRight: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "40%",
              height: 20,
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          NAME OF EDUCATION INSTITUTION / TECHNICAL INSTITUTE / UNIVERSITY
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 20,
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          OBTAINED TITLE OR GRADE
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 20,
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          DATE ON 
        (MM/DD/YYYY)
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            {
              width: "20%",
              height: 20,
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          DATE OFF
        (MM/DD/YYYY)
        </Text>
      </View>
       {sortExperiencesByDate(otherTraining).length > 0 ? (
        <View style={{ borderBottom: 0.5 }}>
          {sortExperiencesByDate(otherTraining).map((item, index) => {
            return (
              <View key={index} style={[styles.row, { borderRight: 0.5 }]}>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "40%",
                      minHeight: 30,
                      border: 0.5,
                    },
                  ]}
                >
                  {item["Name of education institution / tech. institute"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      minHeight: 30,
                      border: 0.5
                    },
                  ]}
                >
                  {item["Obtained title or grade"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      minHeight: 30,
                      border: 0.5
                    },
                  ]}
                >
                  {item["Date on"] ? moment(item["Date on"]).format("MM/DD/YYYY") : ''}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    {
                      width: "20%",
                      minHeight: 30,
                      border: 0.5
                    },
                  ]}
                >
                  {item["Date off"] ? moment(item["Date off"]).format("MM/DD/YYYY") : ''}
                </Text>
              </View>
            );
          })}
        </View>
         ) : (
          <View style={[styles.row, { borderBottom: 0.5, borderRight: 0.5 }]}>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "40%",
                height: 30,
                color: "white",
              },
            ]}
          >-</Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
                color: "white",
              },
            ]}
          >-</Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
                color: "white",
              },
            ]}
          >-</Text>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              {
                width: "20%",
                height: 30,
                color: "white",
              },
            ]}
          >-</Text>
        </View>
      )}
      </>
  )
}

export default OtherCertificates