import { Text, View } from "@react-pdf/renderer";
import { numberNextLimiter, sortExperiencesByDate } from "../../../utils/utils";
import { size } from "lodash";
import { PDF_FONT_SIZE } from "../../../constants";
import moment from "moment";

const Certificates = ({
  styles,
  person,
  certificates,
  otherTraining,
  lightGrey,
}) => {
  const certificatesColums = [
    {
      name: "DESCRIPTION OF CERT / COURSE",
      width: "20%",
    },
    {
      name: "COUNTRY OF ISSUE",
      width: "20%",
    },
    {
      name: "NUMBER",
      width: "20%",
    },
    {
      name: "DATE OF ISSUE(MM/DD/YYYY)",
      width: "20%",
    },
    {
      name: "DATE OF EXPIRY(MM/DD/YYYY)",
      width: "20%",
    },
  ];
  const filteredCertificates = certificates.filter((item) =>
    Object.values(item).some((value) => value !== "")
  );
  const arrayCertificatenew = filteredCertificates.filter((item) =>
    Object.values(item).some((value) => value !== "")
  );
  const sortedCertificates = arrayCertificatenew.sort((a, b) => {
    const descA = a["Description of cert / course"].toUpperCase();
    const descB = b["Description of cert / course"].toUpperCase();
    return descA.localeCompare(descB);
  });

  return (
    <>
      <Text
        style={[
          styles.normalTableText,
          {
            textAlign: "left",
          },
        ]}
      >
        5. TRAINING AND CERTIFICATION
      </Text>
      <View style={[styles.row, { backgroundColor: "#b8cce4" }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", paddingTop: 5, height: 20, border: 0.5 },
          ]}
        >
          STCW CERTIFICATES
        </Text>
      </View>
      <View style={[styles.row, { border: 0.5 }]}>
        {certificatesColums.map((item, index) => {
          return (
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                {
                  width: item.width,
                  minheight: 10,
                  backgroundColor: "#b8cce4",
                },
              ]}
              key={index}
            >
              {item.name}
            </Text>
          );
        })}
      </View>
      <View>
        {person.lic_sbook_stcw_certificates === "" ? (
          <>
            <View style={[styles.row, { border: 0.5 }]}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: PDF_FONT_SIZE },
                ]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: PDF_FONT_SIZE },
                ]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: PDF_FONT_SIZE },
                ]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: PDF_FONT_SIZE },
                ]}
              ></Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { height: 25, width: "20%", fontSize: PDF_FONT_SIZE },
                ]}
              ></Text>
            </View>
          </>
        ) : (
          <>
            {sortedCertificates.map((item, index) => {
              return (
                <View style={[styles.row, { borderRight: 0.5 }]} key={index}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      {
                        border: 0.5,
                        width: "20%",
                        fontSize: PDF_FONT_SIZE,
                        textAlign: "left",
                        height: "auto",
                        backgroundColor: "#b8cce4",
                      },
                    ]}
                  >
                    {size(
                      item[`Description of cert / course`]?.toUpperCase()
                    ) <= 21
                      ? `${item["Description of cert / course"]?.toUpperCase()}`
                      : `${item[
                          "Description of cert / course"
                        ]?.toUpperCase()}`}
                  </Text>
                  <View
                    style={[
                      styles.cell,
                      {
                        minheight: 25,
                        border: 0.5,
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <Text
                      style={[
                        styles.normalTableText,
                        {
                          fontSize: PDF_FONT_SIZE,
                        },
                      ]}
                    >
                      {item["Country of issue"]}
                    </Text>
                  </View>

                  <View
                    style={[
                      styles.cell,
                      {
                        minheight: 15,
                        border: 0.5,
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    <Text style={[styles.normalTableText, { fontSize: 8 }]}>
                      {numberNextLimiter(item["Number"], 15)}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        minheight: 25,
                        border: 0.5,
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    {item.hasOwnProperty("Date of issue") &&
                      item["Date of issue"] !== "" && (
                        <Text
                          style={[
                            styles.normalTableText,
                            { fontSize: PDF_FONT_SIZE },
                          ]}
                        >
                          {moment(item["Date of issue"]).format("MM/DD/YYYY")}
                        </Text>
                      )}
                  </View>
                  <View
                    style={[
                      styles.cell,
                      {
                        minheight: 25,
                        border: 0.5,
                        width: "20%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      },
                    ]}
                  >
                    {item.hasOwnProperty("Date of expiry") &&
                      item["Date of expiry"] !== "" && (
                        <Text
                          style={[
                            styles.normalTableText,
                            { fontSize: PDF_FONT_SIZE },
                          ]}
                        >
                          {moment(item["Date of expiry"]).format("MM/DD/YYYY")}
                        </Text>
                      )}
                  </View>
                </View>
              );
            })}
          </>
        )}
      </View>
    </>
  );
};

export default Certificates;
