import { Text, View } from "@react-pdf/renderer";
import { numberNextLimiter, sortExperiencesByDate } from "../../../utils/utils";
import moment from "moment";

const ExpOnBoard = ({ styles, person, expOnBoard }) => {
  const experiencesColumn = [
    {
      name: "DATE ON (MM/DD/YYYY)",
      width: "15%",
    },
    {
      name: "DATE OFF (MM/DD/YYYY)",
      width: "15%",
    },
    {
      name: "COMPANY NAME",
      width: `${expOnBoard.length > 15 ? "25%" : "15%"}`,
    },
    {
      name: "VESSEL NAME",
      width: "15%",
    },
    {
      name: "IMO #",
      width: "10%",
    },
    {
      name: "GT / HP",
      width: "15%",
    },
    {
      name: "TYPE OF VESSEL",
      width: "15%",
    },
    {
      name: "RANK/POSITION",
      width: "15%",
    },
  ];
  return (
    <View>
      <Text
        style={[
          styles.normalTableText,
          {
            textAlign: "left",
          },
        ]}
      >
        3.WORK EXPERIENCE ONBOARD
      </Text>
      <View style={{ borderBottom: 0.5 }}>
        <View style={[styles.row, { borderRight: 0.5 }]}>
          {experiencesColumn.map((item, index) => {
            return (
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: item.width, backgroundColor: "#b8cce4" },
                ]}
                key={index}
              >
                {item.name}
              </Text>
            );
          })}
        </View>
        {sortExperiencesByDate(expOnBoard).length > 0 ? (
          <>
            {sortExperiencesByDate(expOnBoard).map((item, index) => {
              return (
                <View style={styles.row} key={index}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, border: 0.5 },
                    ]}
                  >
                    {item["Date on"]
                      ? moment(item["Date on"]).isValid()
                        ? moment(item["Date on"]).format("MM/DD/YYYY")
                        : ""
                      : ""}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, border: 0.5 },
                    ]}
                  >
                    {item["Date off"]
                      ? moment(item["Date off"]).isValid()
                        ? moment(item["Date off"]).format("MM/DD/YYYY")
                        : ""
                      : ""}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      {
                        width: `${expOnBoard.length > 15 ? "25%" : "15%"}`,
                        height: 30,
                        border: 0.5,
                      },
                    ]}
                  >
                    {item["Company name"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, border: 0.5 },
                    ]}
                  >
                    {item["Vessel name"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "10%", height: 30, border: 0.5 },
                    ]}
                  >
                    {numberNextLimiter(item["IMO #"], 10)}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, border: 0.5 },
                    ]}
                  >
                    {item["GT / HP"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, padding: 4, border: 0.5 },
                    ]}
                  >
                    {item["Type of vessel"]}
                  </Text>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { width: "15%", height: 30, border: 0.5 },
                    ]}
                  >
                    {item["Rank/position"]}
                  </Text>
                </View>
              );
            })}
          </>
        ) : (
          <>
            <View style={styles.row}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30 },
                ]}
              >
                {""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", minHeight: 30, borderRight: 0.5 },
                ]}
              >
                {""}
              </Text>
            </View>
          </>
        )}
      </View>
    </View>
  );
};

export default ExpOnBoard;
