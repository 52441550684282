import React, { useContext, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import ApartmentRoundedIcon from "@mui/icons-material/ApartmentRounded";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import StorageIcon from "@mui/icons-material/Storage";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { Link } from "react-router-dom";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LogoutIcon from "@mui/icons-material/Logout";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import Collapse from "@mui/material/Collapse";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { menu, settingsmenu, embarkationmenu } from "./Reports/menu";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import Badge from "@mui/material/Badge";
import { GlobalContext } from "../context/GlobalContext";
import { useEffect } from "react";

export const MainListComponent = ({ rol }) => {
  const { staffRequest, updateInfo } = useContext(GlobalContext);

  if (rol === "PMSSA") {
    return (
      <React.Fragment>
        <Link to="/graph" style={{ textDecoration: "none", color: "#121212" }}>
          <ListItemButton>
            <ListItemIcon>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>

        <Link
          to="/requests"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Badge
                badgeContent={
                  staffRequest &&
                  staffRequest.reduce((total, item) => {
                    if (item.status !== "pending") return total + 0;
                    return total + 1;
                  }, 0)
                }
                color="primary"
              >
                <PersonSearchIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Staff Requests" />
          </ListItemButton>
        </Link>

        <Link
          to="/staffdatabase"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <StorageIcon />
            </ListItemIcon>
            <ListItemText primary="Data Base" />
          </ListItemButton>
        </Link>
        <Link
          to="/applications"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DonutSmallIcon />
            </ListItemIcon>
            <ListItemText primary="Applications" />
          </ListItemButton>
        </Link>
        {/* <Link
					to="/addstaff"
					style={{ textDecoration: "none", color: "#121212" }}>
					<ListItemButton>
						<ListItemIcon>
							<PersonAddAlt1OutlinedIcon />
						</ListItemIcon>
						<ListItemText primary="Add Staff" />
					</ListItemButton>
				</Link> */}
        <Link
          to="/expireddocuments"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <NewReleasesIcon />
            </ListItemIcon>
            <ListItemText primary="Expired Documents" />
          </ListItemButton>
        </Link>
        {
          /* <Link
					to="/embarkationreport"
					style={{ textDecoration: "none", color: "#121212" }}>
					<ListItemButton>
						<ListItemIcon>
							<DirectionsBoatIcon />
						</ListItemIcon>
						<ListItemText primary="Embark. Graph" />
					</ListItemButton>
				</Link>
				<Link
					to="/embarkationlist"
					style={{ textDecoration: "none", color: "#121212" }}>
					<ListItemButton>
						<ListItemIcon>
							<DirectionsBoatIcon />
						</ListItemIcon>
						<ListItemText primary="Embarkations List" />
					</ListItemButton>
				</Link> */

          embarkationmenu.map((item, key) => (
            <MenuItem key={key} item={item} />
          ))
        }

        <Link
          to="/updatelists"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <Badge
                badgeContent={
                  updateInfo &&
                  updateInfo.reduce((total, item) => {
                    if (item.status === "Finished") return total + 0;
                    return total + 1;
                  }, 0)
                }
                color="primary"
              >
                <RotateRightIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Update Info" />
          </ListItemButton>
        </Link>
        {menu.map((item, key) => (
          <MenuItem key={key} item={item} />
        ))}
        {settingsmenu.map((item, key) => (
          <MenuItem key={key} item={item} />
        ))}
        <Link
          to="/signout"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="SignOut" />
          </ListItemButton>
        </Link>
      </React.Fragment>
    );
  } else if (rol === "EXTERNAL") {
    return (
      <React.Fragment>
        <Link
          to="/orderstaff"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Order Staff" />
          </ListItemButton>
        </Link>

        <Link
          to="/requests"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="My Requests" />
          </ListItemButton>
        </Link>
        <Link
          to="/signout"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="SignOut" />
          </ListItemButton>
        </Link>
      </React.Fragment>
    );
  } else if (rol === "PARTNER") {
    return (
      <React.Fragment>
        <Link
          to="/partnerview"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <PersonAddIcon />
            </ListItemIcon>
            <ListItemText primary="Add new Record" />
          </ListItemButton>
        </Link>
        <Link
          to="/partnerseafarers"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DonutSmallIcon />
            </ListItemIcon>
            <ListItemText primary="Partner Seafarers" />
          </ListItemButton>
        </Link>
        <Link
          to="/signout"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="SignOut" />
          </ListItemButton>
        </Link>
      </React.Fragment>
    );
  } else if (rol === "AUDIT") {
    return (
      <React.Fragment>
        <Link
          to="/seafers"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DonutSmallIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        <Link
          to="/crewstatus"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DonutSmallIcon />
            </ListItemIcon>
            <ListItemText primary="Crew Status" />
          </ListItemButton>
        </Link>
        <Link
          to="/embarkationlist"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <DonutSmallIcon />
            </ListItemIcon>
            <ListItemText primary="Embarkations" />
          </ListItemButton>
        </Link>

        <Link
          to="/signout"
          style={{ textDecoration: "none", color: "#121212" }}
        >
          <ListItemButton>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="SignOut" />
          </ListItemButton>
        </Link>
      </React.Fragment>
    );
  }
};

const MultiLevel = ({ item }) => {
  const { items: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};
const SingleLevel = ({ item }) => {
  return (
    <Link to={item.to} style={{ textDecoration: "none", color: "#121212" }}>
      <ListItem button>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItem>
    </Link>
  );
};
