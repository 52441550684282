import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { experimentalStyled as styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",

  color: theme.palette.text.secondary,
}));

export default function AddDocuments({
  open,
  handleOpen,
  handleClose,
  data,
  onChange,
  contrylistdata,
  listtypeofdocument,
  handleChangeSelect,
  handleChangeDoctypeSelect,
  contry,
  handleChangeIssueDate,
  handleChangeValidDate,
  addnew,
  loading,
  setloading,
  handleUpload,
  handleFileChange,
  percent,
  isedit,
  edit,
}) {
  const SelectContryPicker = () => {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="contryIssue"
          id="countryId"
          value={data.contry}
          onChange={handleChangeSelect}
          fullWidth>
          {contrylistdata.map((item) => (
            <MenuItem value={item.name.toUpperCase()} key={item.Id}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectDocTypePicker = () => {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="doctype"
          id="doctype"
          value={data.doctype}
          onChange={handleChangeDoctypeSelect}
          fullWidth>
          {listtypeofdocument.map((item) => (
            <MenuItem value={item.documentname.toUpperCase()} key={item.Id}>
              {item.documentname.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 5,
          }}>
          <Grid>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.id}</Title>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              {isedit ? (
                <Title>EDIT DOCUMENT</Title>
              ) : (
                <Title>ADD DOCUMENT</Title>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItem: "center",
                marginRight: "auto",
                marginLeft: "auto",
                pr: 5,
                pl: 5,
              }}>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Type of Document : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <SelectDocTypePicker />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ alignItems: "center", justifyContent: "center" }}>
                <Typography variant="subtitle2">Country Issue: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <SelectContryPicker />
              </Grid>

              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Issue at (place): </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="issueatplace"
                  required
                  id="issueatplace"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.issueatplace}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">No. </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="no"
                  id="no"
                  autoFocus
                  variant="standard"
                  onChange={onChange}
                  fullWidth
                  value={data.no}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Date of issue </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={data.issuedate}
                  onChange={handleChangeIssueDate}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Valid Until: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={data.validdate}
                  onChange={handleChangeValidDate}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              {/*  <Grid item xs={6}>
                <div>
                  <input
                    type="file"
                    accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                    onChange={handleFileChange}
                  />
                </div>
              </Grid> */}
              <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                {isedit ? (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                    {!loading ? "UPDATE" : <CircularProgress color="inherit" />}
                  </Button>
                ) : (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={addnew}>
                    {!loading ? (
                      "ADD NEW DOCUMENT"
                    ) : (
                      <CircularProgress color="inherit" />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
