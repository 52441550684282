import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const theme = createTheme();

const handleDocName = (data) => {
  if (data["Type of Document"]) return data["Type of Document"];
  if (data["Description of cert / course"])
    return data["Description of cert / course"];
  if (data["COC DOC."]) return data["COC DOC."];
  if (data["Name of education institution / tech. institute"])
    return data["Name of education institution / tech. institute"];
};

const handleDocDate = (data) => {
  if (data["Valid Until"]) return data["Valid Until"];
  if (data["Valid until"]) return data["Valid until"];
  if (data["Date off"]) return data["Date off"];
  if (data["Date of expiry"]) return data["Date of expiry"];
};

export default function ExpiringDocsModal({ open, handleClose, data }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", overflowY: "auto" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper
              sx={{
                width: 0.5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: 5,
              }}
            >
              <Grid>
                <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                  <CloseOutlinedIcon onClick={() => handleClose()} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  <Title sx={{ textTransform: "capitalize" }}>
                    {data.fullname}'s expiring documents
                  </Title>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ textAlign: "center", height: 500, mt: 2, pb: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Title sx={{ textTransform: "capitalize", width: "50%" }}>
                      Document's name
                    </Title>
                    <Title sx={{ textTransform: "capitalize", width: "50%" }}>
                      Document's date off
                    </Title>
                  </Box>
                  <Box sx={{ overflowY: "auto", height: "100%" }}>
                    {data.docs && (
                      <>
                        {data.docs.map((row, index) => {
                          return (
                            <Box
                              key={index}
                              sx={{
                                display: "flex",
                                borderWidth: 0.1,
                                borderColor: "black",
                                borderRadius: 2,
                                borderStyle: "solid",
                                mx: 5,
                                py: 1,
                                my: 1,
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body1"
                                color="initial"
                                sx={{ width: "50%" }}
                              >
                                {handleDocName(row)}
                              </Typography>
                              <Typography
                                variant="body1"
                                color="initial"
                                sx={{ width: "50%" }}
                              >
                                {handleDocDate(row)}
                              </Typography>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </Modal>
  );
}
