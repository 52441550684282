import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Title from "../Commons/Title";
import moment from "moment";
function preventDefault(event) {
	event.preventDefault();
}

export default function Deposits({ total, title = "TOTAL STAFF" }) {
	return (
		<React.Fragment>
			<Title>{title}</Title>
			<Typography
				component="p"
				variant="h4">
				#{total}
			</Typography>
			<Typography
				color="text.secondary"
				sx={{ flex: 1 }}>
				{moment().format("MMM Do YY")}
			</Typography>
		</React.Fragment>
	);
}
