import React, { useEffect } from "react";
import { signOutFn } from "../../Actions/Actions";
import { Routes, Route, useNavigate } from "react-router-dom";

export const SingOut = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    signOutFn(navigate);
  }, []);

  return <div>{props.children}</div>;
};

export default SingOut;
