import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme();

export default function AddTrainings({
  open,
  handleClose,
  list,
  contrylistdata,
  data,
  handleChangeCertSelect,
  handleChangeSelect,
  onChange,
  handleChangeCertIssueDate,
  handleChangeCertExpiredDate,
  loading,
  addnew,
  isedit,
  edit,
}) {
  const SelectCertificates = () => {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="certificatetype"
          id="certificatetype"
          value={data.description}
          onChange={handleChangeCertSelect}
          fullWidth
        >
          {list.map((item, index) => (
            <MenuItem value={item.curse_name} key={index}>
              {item.curse_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const SelectContryPicker = () => {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="contryIssue"
          id="countryId"
          value={data.contry}
          onChange={handleChangeSelect}
          fullWidth
        >
          {contrylistdata.map((item, index) => (
            <MenuItem value={item.name} key={index}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper
              sx={{
                width: 0.5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: 5,
              }}
            >
              <Grid>
                <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                  <CloseOutlinedIcon onClick={() => handleClose()} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  <Title>{data.id}</Title>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  {isedit ? (
                    <Title>UPDATE</Title>
                  ) : (
                    <Title>ADD NEW TRAINING</Title>
                  )}
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                    pr: 5,
                    pl: 5,
                  }}
                >
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">
                      Description of cert/course :{" "}
                    </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <SelectCertificates />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="subtitle2">Country Issue: </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <SelectContryPicker />
                  </Grid>

                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">No. </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <TextField
                      name="number"
                      id="number"
                      autoFocus
                      variant="standard"
                      onChange={onChange}
                      fullWidth
                      value={data.number}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">Date of issue </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={data.issuedate}
                      onChange={handleChangeCertIssueDate}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{}}>
                    <Typography variant="subtitle2">Valid Until: </Typography>
                  </Grid>
                  <Grid item xs={8} sx={{}}>
                    <MobileDatePicker
                      inputFormat="dd/MM/yyyy"
                      value={data.expireddate}
                      onChange={handleChangeCertExpiredDate}
                      renderInput={(params) => (
                        <TextField {...params} variant="standard" />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <div>
                      <input
                        type="file"
                        accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                        onChange={handleFileChange}
                      />
                    </div>
                  </Grid> */}
                  <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                    {isedit ? (
                      <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                        {!loading ? (
                          "UPDATE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ mr: 5 }}
                        onClick={addnew}
                      >
                        {!loading ? (
                          "ADD NEW CERTIFICATE / COURSE"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </Modal>
  );
}
