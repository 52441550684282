import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { PDF_FONT_SIZE } from "../../../constants";

const BasicInfo = ({ names, person }) => {
  const [leng, setLeng] = useState([]);

  useEffect(() => {
    if (person.languages !== "") {
      setLeng(JSON.parse(person.languages));
    }
  }, []);

  const nombreCompleto = names.slice(0,1).join(" ");


  return (
    <View style={{ width: "90%", borderBottom: 0.5, marginLeft: 45 }}>
       <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  1. PERSONAL INFORMATION
                </Text>
      <View style={[styles.row, { marginTop: 5, borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
          NAME
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%", borderRight: 0.5 }]}>
          {names[0]}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%"}]}>
         {names.some((name) => name === "") ? names[1] : `${names[1]} ${names[2]}`}
        </Text>
      </View>
      <View style={[styles.row, {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
          SURNAMES
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%", borderRight: 0.5 }]}>
          {names.some((name) => name === "") ? names[2] : `${names[3]}`}
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "30%"}]}>
          {names.some((name) => name === "") ? names[3] : `${names[4]}`}
        </Text>
      </View>
      <View style={[styles.row, {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, paddingBottom: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
           DATE OF BIRTH
          (MM/DD/YYYY)
        </Text>
        <View style={[styles.cell, { width: "52%", textAlign: "center"  }]}>
          <Text style={styles.normalTableText}>{moment(person.date_of_birth).format("MM/DD/YYYY")}</Text>
        </View>
      </View>
      <View style={[styles.row, {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, paddingRight: 8, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
          IDENTIFICATION      NUMBER
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "52%", textAlign: "center" }]}>
          {person.identification}
        </Text>
      </View>
      <View style={[styles.row, {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
          NATIONALITY 
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "52%", textAlign: "center" }]}>
          {person.place_of_birth}
        </Text>
      </View>
      <View style={[styles.row, , {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
      <Text
          style={[
            styles.normalTableText,
            styles.cell,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
            SEX
          </Text>
          <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%"}
            ]}>
            {person.sex}
            </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", borderRight: 0.5, borderBottom: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          CIVIL STATUS
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%"},
          ]}
        >
          {person.civilstatus}
        </Text>
      </View>
      <View style={[styles.row, , {borderRight: 0.5, borderLeft: 0.5, borderBottom: 0.5 }]}>
      <Text
          style={[
            styles.normalTableText,
            styles.cell,
            { width: "30%", fontWeight: "bold", paddingLeft: 4, textAlign: "left", backgroundColor: "#b8cce4" },
          ]}
        >
            HEIGHT (Ft/in)
          </Text>
          <Text style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%"}
            ]}>
            {person.height}
            </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "25%", fontWeight: "bold", paddingLeft: 4, textAlign: "left",  backgroundColor: "#b8cce4" },
          ]}
        >
          WEIGHT (Lb)
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"},
          ]}
        >
          {person.weight}
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", fontWeight: "bold", paddingLeft: 4, textAlign: "left",  backgroundColor: "#b8cce4" },
          ]}
        >
          BMI
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%"},
          ]}
        >
          {person.bmi}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center"
  },
  cell: {
    display: "flex",
    borderLeft: 0.5,
    borderColor: "black",
    minHeight: 23,
  },
  normalTableText: {
    fontSize: PDF_FONT_SIZE,
    paddingTop: 5,
  },
  verticalCell: {
    display: "flex",
  },
});

export default BasicInfo;
