import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { isEmpty } from "lodash";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useEffect } from "react";

export default function DocumentsTab({
  data,
  addNew,
  deleterecord,
  edit,
  isview,
  disableAdd,
  checkForNewFiles,
  dbData,
}) {
  let datos = isEmpty(data) ? [] : JSON.parse(data);

  const checkfileExist = (obj) => {
    if (isview !== 1) {
      if (dbData[0].type_document === "") return false;
      let documentArray = JSON.parse(dbData[0].type_document);
      const isExist = documentArray.some((item) =>
        Object.entries(obj).every(([key, value]) => item[key] === value)
      );
      return isExist;
    }
    return true;
  };

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="right"
          alignItems="center"
          sx={{ marginBottom: 2 }}>
          {disableAdd ? (
            ""
          ) : (
            <>
              <Button variant="contained" onClick={addNew}>
                ADD +
              </Button>
            </>
          )}
        </Grid>
      </Grid>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type of Document</TableCell>
            <TableCell>Country of Issue</TableCell>
            <TableCell>Issued at (Place)</TableCell>
            <TableCell>No.</TableCell>
            <TableCell>Date of issue</TableCell>
            <TableCell>Valid Until</TableCell>
            {isview !== 1 && (
              <>
                <TableCell>Edit.</TableCell>
                <TableCell>Del.</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {datos.map((row) => {
            return (
              <TableRow key={Math.random()}>
                {checkForNewFiles ? (
                  <>
                    {checkfileExist(row) ? (
                      <>
                        <TableCell>{row["Type of Document"]}</TableCell>
                        <TableCell>{row["Country of Issue"]}</TableCell>
                        <TableCell>{row["Issued at (Place)"]}</TableCell>
                        <TableCell>{row["No."]}</TableCell>
                        <TableCell>{row["Date of Issue"]}</TableCell>
                        <TableCell>{row["Valid Until"]}</TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Type of Document"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Country of Issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Issued at (Place)"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["No."]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Date of Issue"]}
                        </TableCell>
                        <TableCell sx={{ color: "blue" }}>
                          {row["Valid Until"]}
                        </TableCell>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <TableCell>{row["Type of Document"]}</TableCell>
                    <TableCell>{row["Country of Issue"]}</TableCell>
                    <TableCell>{row["Issued at (Place)"]}</TableCell>
                    <TableCell>{row["No."]}</TableCell>
                    <TableCell>{row["Date of Issue"]}</TableCell>
                    <TableCell>{row["Valid Until"]}</TableCell>
                  </>
                )}

                {isview !== 1 && (
                  <>
                    <TableCell>
                      <ModeEditIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          edit(row);
                        }}></ModeEditIcon>
                    </TableCell>
                    <TableCell>
                      <DeleteIcon
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          let resp = window.confirm(
                            "Do you want delete this document?"
                          );
                          if (resp) {
                            deleterecord(datos, row);
                          }
                        }}></DeleteIcon>
                    </TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
