import { borderBottom } from "@mui/system";
import { Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect } from "react";
import logo from "../assets/logo.jpg";
import CheckIcon from "@mui/icons-material/Check";

const ComplaintPdf = ({ user }) => {
  return (
    <>
      <FirstPageBody user={user} />
      <SecondPageBody user={user} />
    </>
  );
};

const FirstPageBody = ({ user }) => {
  let questionFourAnswers = "";
  let questionThreeOptions = [
    "General complaint effecting specific working or living conditions onboard the ship.",
    "Complaint relates to a single seafarer",
  ];
  let questionFourOptions = [
    "Recruitment and placement services (manning Agency)",
    "Seafarers Employment Agreement",
    "Payment of Wages",
    "Hours of Work or Hours of rest",
    "Entitlement to Leave",
    "Repatriation",
    "Accommodation Facilities",
    "Recreational Facilities",
    "Food",
    "Medical Care on board and Ashore",
    "Other",
  ];
  if (user.question4) {
    questionFourAnswers = JSON.parse(user.question4);
  }
  return (
    <Page size="A4" style={{ padding: 10 }}>
      <PdfHeader page={1} />
      <View style={styles.globalContainer}>
        <Text style={styles.bodyNormalText}>Vessel Name: {user.vessel}</Text>
        <Text style={styles.bodyNormalText}>IMO Number: {user.imo}</Text>
        <Text style={styles.bodyBiggerText}>
          Part A. Declaration of Panama Marine Services.
        </Text>
        <Text style={styles.bodyNormalText}>
          Panama Marine Services is committed to ensuring that seafarers who
          provide services on the company's vessels under which personnel are
          recruited have decent working and living conditions, a safe workplace
          and fair employment.
        </Text>
        <Text style={styles.bodyNormalText}>
          Seafarers are encouraged to use the Shipboard Complaint Procedures to
          resolve complaints at the lowest possible level in accordance with MLC
          2006. However, in the event that a complaint cannot be resolved on
          board, Panama Marine Services, as a placement agent provides this
          complaint form for seafarers, can send us the complaints and be able
          to channel them in order to seek a solution for both parties.
        </Text>
        <Text style={styles.bodyNormalText}>
          Please provide the requested information as detailed as possible from
          part B and C of this form.
        </Text>
        <Text style={styles.bodyBiggerText}>Part B. Marine Information:</Text>
        <Text style={styles.bodyNormalText}>Full name: {user.fullname}</Text>
        <Text style={styles.bodyNormalText}>
          Position on board: {user.positiononboard}
        </Text>
        <Text style={styles.bodyNormalText}>
          Contact phone: {user.contactphone}
        </Text>
        <Text style={styles.bodyNormalText}>Email: {user.email}</Text>
        <Text style={styles.bodyNormalText}>
          Seafarer Book Number: {user.seaferbooknumber}
        </Text>
        <Text style={styles.bodyNormalText}>
          Seafarer's Date of Birth: {user.seaferdatebirth}
        </Text>
        <Text style={styles.bodyNormalText}>
          Seafarer's Address: {user.seaferaddress}
        </Text>
        <Text style={styles.bodyBiggerText}>
          Part C. Detail of the Complaint.
        </Text>
        <Text style={styles.bodyNormalText}>
          1. Date on which the complaint occurred: {user.dateocurred}
        </Text>
        <Text style={styles.bodyNormalText}>
          2. Date of registration in the log on board:{" "}
          {user.dateregistrationinlog}
        </Text>
        <Text style={styles.bodyNormalText}>
          3. Please indicate the nature of the complaint by selecting one of the
          following options*
        </Text>
        <Text style={styles.bodyNormalText}>
          {user.question3?.toUpperCase() ===
          questionThreeOptions[0].toUpperCase()
            ? "YES - "
            : "___ - "}
          {questionThreeOptions[0]}
        </Text>
        <Text style={styles.bodyNormalText}>
          {user.question3?.toUpperCase() ===
          questionThreeOptions[1].toUpperCase()
            ? "YES - "
            : "___ - "}
          {questionThreeOptions[1]}
        </Text>
        <Text style={styles.bodyNormalText}>
          4. Does the complaint relate to any of the following matters? {"\n"}
          Note: Please select the area or areas to which the complaint most
          closely applies.
        </Text>
        {questionFourOptions?.map((item, index) => {
          return (
            <Text style={styles.bodyNormalText} key={index}>
              {questionFourAnswers.includes(item) ? "YES - " : "___ - "}
              {item}
            </Text>
          );
        })}
      </View>
    </Page>
  );
};

const SecondPageBody = ({ user }) => {
  return (
    <Page size="A4" style={{ padding: 10 }}>
      <PdfHeader page={2} />
      <View style={styles.globalContainer}>
        <Text style={styles.bodyNormalText}>
          5. Briefly describe the complaint, if you need more spaces, you can
          add another page:
        </Text>
        <Text style={styles.bodyNormalText}>{user.question5}</Text>
        <Text style={styles.bodyNormalText}>
          6. If onboard complaint procedures were not used, provide a brief
          summary of why these procedures should not be exhausted first.
        </Text>
        <Text style={styles.bodyNormalText}>{user.question6}</Text>
        <Text style={styles.bodyBiggerText}>
          Part D: Contact information of the company assigned on the ground.
        </Text>
        <Text style={styles.bodyNormalText}>Name: Panama Marine Services.</Text>
        <Text style={styles.bodyNormalText}>
          Telephones: +507 373-4907 / +507 +373-4908 / +507 788-6592
        </Text>
        <Text style={styles.bodyNormalText}>Email: info@mp-ip.edu.pa</Text>
        <Text style={styles.bodyNormalText}>
          Website: www.panamamarineservices.com
        </Text>
        <Text style={styles.bodyNormalText}>Name: Panama Marine Services.</Text>
        <Text style={styles.bodyNormalText}>Name: Panama Marine Services.</Text>
        <Text style={styles.bodyBiggerText}>
          Part E. Contact information of thecompetent authority for the Flag
          State:
        </Text>
        <Text style={styles.bodyNormalText}>
          Name: Department of Maritime Labor Affairs – Panama Maritime
          Authority.
        </Text>
        <Text style={styles.bodyNormalText}>
          Telephones: +507 501-5059 / +507 501-5067
        </Text>
        <Text style={styles.bodyNormalText}>Email: labormar@amp.gob.pa</Text>
        <Text style={styles.bodyNormalText}>
          The contact information of the competent authority in the seafarers'
          country of residence can be found on the ILO website (click on the
          relevant country):
        </Text>
        <Text style={styles.bodyNormalText}>
          http://www.ilo.org/dyn/normlex/en/f?p=NORMLEXPUB:80001:0::NO
        </Text>
      </View>
    </Page>
  );
};

const PdfHeader = ({ page }) => {
  return (
    <View style={styles.mainContainer}>
      <View
        style={{
          width: "20%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Image src={logo} style={{ height: 50, width: 100 }} />
      </View>
      <View
        style={{
          borderLeft: 1,
          borderColor: "black",
          width: "55%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <View
          style={[
            styles.headerTextContainer,
            { borderBottom: 1, width: "100%" },
          ]}>
          <Text style={styles.headerText}>
            LOGISTIC INTERNATIONAL SERVICES CORP.
          </Text>
        </View>
        <View style={styles.headerTextContainer}>
          <Text style={styles.headerText}>
            ONBOARD COMPLAINT FORM AND PROCEDURE
          </Text>
        </View>
      </View>

      <View
        style={{
          borderLeft: 1,
          borderColor: "black",
          display: "flex",
          flexDirection: "column",
          width: "25%",
        }}>
        <View
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50%",
              borderBottom: 1,
              paddingLeft: 5,
            }}>
            <Text style={styles.smallText}>Code: F-PMSSA-25</Text>
          </View>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50%",
              borderBottom: 1,
              paddingLeft: 5,
            }}>
            <Text style={styles.smallText}>Revision: 01</Text>
          </View>
        </View>
        <View
          style={{
            height: "50%",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50%",
              paddingLeft: 5,
              borderBottom: 1,
            }}>
            <Text style={{ fontSize: 8 }}>Effective date: Aug. 16th, 2021</Text>
          </View>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              height: "50%",
              paddingLeft: 5,
            }}>
            <Text style={styles.smallText}>Page {page} of 2</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    borderWidth: 1,
    borderColor: "black",
    display: "flex",
    flexDirection: "row",
    height: 60,
  },
  globalContainer: {
    paddingHorizontal: 10,
  },
  headerTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    fontSize: 12,
    height: "50%",
    fontWeight: "bold",
  },
  bodyNormalText: {
    fontSize: 10,
    marginVertical: 5,
  },
  bodyBiggerText: { fontSize: 12, marginVertical: 5 },
  smallText: { fontSize: 6 },
});

export default ComplaintPdf;
