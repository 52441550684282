import { Button } from "@mui/material";
import * as XLSX from "xlsx";

const ExportReportBtn = ({ getData }) => {
  const handleDownloadExcel = (headers, data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reportes");
    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
    XLSX.writeFile(workbook, `report.xlsx`);
  };

  const handleClick = () => {
    const [headers, csvData] = getData();
    handleDownloadExcel(headers, csvData);
  };

  return (
    <Button variant="outlined" onClick={handleClick}>
      Export data
    </Button>
  );
};

export default ExportReportBtn;
