/***********DECLARACIÓN DE OBJETOS**************/
export const emptycoc = {
  "COC DOC.": "",
  "Grade of license (if apply)": "",
  contry: "PANAMA",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

export const emptydoc = {
  doctype: "",
  contry: "",
  issueatplace: "",
  no: "",
  issuedate: null,
  validdate: null,
};

export const emptyexperienceonboard = {
  dateon: null,
  dateoff: null,
  companyname: "",
  contactperson: "",
  duties: "",
  "Rank/position": "",
  reasonleaving: "",
};

export const emptyexperienceonshore = {
  "Date on": null,
  "Date off": null,
  "Company name": "",
  "Contact person telephone": "",
  "Duties or responsibilities": "",
  "Rank/position": "",
  "Reason for leaving": "",
};

export const emptycertificate = {
  description: "",
  contry: "",
  number: "",
  issuedate: null,
  expireddate: null,
};

export const emptyotherTraining = {
  "Name of education institution / tech. institute": "",
  "Obtained title or grade": "",
  "Date on": null,
  "Date off": null,
};

export const emptyVaccine = {
  doze: "",
  vaccinationMark: "",
  "Date of issue": "",
};

export const skillArray = [
  "AB",
  "OS",
  "BOSUN",
  "COOK",
  "FITTER",
  "MESSMAN",
  "OILER",
  "WIPER",
  "Able Seaman",
  "Ordinary Seaman",
  "Apprentice Ordinary Seaman",
  "UTILITY COOK",
  "Motorman / Oiler",
  "Apprentice Wiper",
  "Galley steward",
  "Mechanics",
  "Welder",
  "Deck officers",
  "Deck cadet",
  "Engine Officers",
  "Engine Cadet",
  "Electro technical Officer III/6",
  "Electro-technical Rating III/7",
  "Electro-Technical Cadet",
];

export const otherattachment = {
  date: new Date(),
  description: "",
  donwloadkey: "",
  id: "",
  idpersonal: "",
  name: "",
  reference: "",
  source: "FIREBASE",
  type: "OTHER DOCUMENTS",
  url: "",
  attachmentType: "",
};
/*****FIN DECLARACIÓN DE OBJETOS*************/
