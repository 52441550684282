import React, { useEffect, useState } from "react";
import { Box, Button, Container, Paper } from "@mui/material";
import { getCountryCodes, getGapPool, loadCertificates,requestpersonalstatus, getApplications} from "../../Actions/Actions";
import { DataGrid, GridToolbarFilterButton } from "@mui/x-data-grid";
import { size } from "lodash";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import { Document, PDFViewer } from "@react-pdf/renderer";
import PdfDocument from "../PdfDocument";
import ExportReportBtn from "../Commons/ExportReportBtn";
import DropDown from "../DropDown/DropDown";
import { Label } from "../Input/Input";
import { ButtonCleanFilters } from "../Buttons/Button";
import Grid from "@mui/material/Grid";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export default function PartnerSeaferes() {
  const [staffData, setStaffData] = useState([]);
  const [staffAmount, setStaffAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [datachartstatus, setdatachartstatus] = useState([]);
  const [barChar, setBarChar] = useState(null);
  const [pageSize, setPageSize] = React.useState(50);
  const [searchText, setSearchText] = React.useState("");
  const [showPdf, setShowPdf] = useState(false);
  const [person, setperson] = useState([]);
  const [applications, setApplications] = useState([]);
  const [certsData, setCertsData] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [vesselType, setVesselType] = React.useState("Show All");
  const [vesselTypelist, setvesseltypelist] = useState([]);
  const [departmentlist, setdepartmentlist] = useState([]);
  const [positionlist, setpositionlist] = useState([]);
  const [companylist, setcompanylist] = useState([]);
  const [statuslist, setstatuslist] = useState([]);
  const [nationalitylist, setnationalitylist] = useState([]);
  const [vesseltypefilter, setvesseltypefilter] = useState("");
  const [departmentfilter, setdepartmentfilter] = useState("");
  const [positionfilter, setpositionfilter] = useState("");
  const [companyfilter, setcompanyfilter] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [nationalityfilter, setnationalityfilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  

  const getAllData = async () => {
    setIsLoading(true);
    let resultadofinal = [];
    const userdata = JSON.parse(sessionStorage.getItem("userdata"));
    let { rol, company } = userdata;
    let response = await getGapPool();
    let uniquevessel = [...new Set(response.map((item) => item.category_vessel))].filter((vessel, index, array) => vessel !== "" && array.indexOf(vessel) === index);
    let uniquedepartment = response.map(item => item.department.toLowerCase()).filter((department, index, array) => department !== "" && array.indexOf(department) === index);
    let uniqueposition = [...new Set(response.map((item) => item.position_applying))].filter((position, index, array) => position !== "" && array.indexOf(position) === index);;
    let uniquecompany = response.map(item => item.company).filter((company, index, array) => company !== "" && array.indexOf(company.toUpperCase()) === index);
    let uniquestatus = ["EVALUATE AND SELECT THE APPLICANTS", "1st Interview", "2nd Interview", "Gap Pool"]
    let uniquenationality = response.map((item) => item.place_of_birth.toLowerCase()).filter((nationality, index, array) => nationality !== "" && array.indexOf(nationality) === index);;
    setvesseltypelist(uniquevessel);
    setdepartmentlist(uniquedepartment);
    setpositionlist(uniqueposition);
    setcompanylist(uniquecompany);
    setstatuslist(uniquestatus);
    setnationalitylist(uniquenationality);
    const certs = await loadCertificates();
    const dataApplications = await getApplications();
    const filterpartner = userdata.rol === "PARTNER" ? dataApplications.filter((email) => email.partneremail === userdata.email) : dataApplications.filter((email) => email.partneremail)
  
    let graphstatus = await requestpersonalstatus();
    if (!response.length) return;
    //FILTRA DATOS POR COMPAÑIA PARA EL ROL DE AUDITORÍA
    if (rol === "AUDIT") {
      response = response.filter(
        (item) =>
          item.company.trim().toUpperCase() === company.trim().toUpperCase()
      );

      let groupcompany = groupBy(response, "status");
      let claves = Object.keys(groupcompany);

      for (let i = 0; i < claves.length; i++) {
        let clave = claves[i];
        resultadofinal.push({ id: clave, total: size(groupcompany[clave]) });
      }

      graphstatus = resultadofinal;
    }
    setCertsData(certs);
    setStaffData(response);
    setApplications(filterpartner)
    setStaffAmount(size(response));
    setdatachartstatus(graphstatus);
    setIsLoading(false);
  };

  useEffect(() => {
    handleGetCountryCodes();
    getAllData();
  }, []);

  const handleGetCountryCodes = async () => {
    const country_codes = await getCountryCodes();
    setCountryCodes(country_codes);
  };

  useEffect(() => {
    if (!datachartstatus) return;

    const state = {
      options: {
        chart: {
          id: "apexchart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Total",
          data: [],
        },
      ],
    };
    datachartstatus.forEach((item) => {
      state.options.xaxis.categories.push(item.id);
      state.series[0].data.push(item.total);
    });
    setBarChar(state);
  }, [datachartstatus]);

let columns = [];
const userdata = JSON.parse(sessionStorage.getItem("userdata"));

if(userdata.rol === "PMSSA"){
  columns = [
    {
      field: "partnername",
      headerName: "Partner",
      width: 300,
      editable: true,
    },
    {
      field: "Id",
      headerName: "View CV",
      width: 120,
      renderCell: (params) => (
        <PersonIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={() => {
            const countryIndex = Number(params.row.countrycode) - 1;
            const value = countryCodes[countryIndex];
            setperson({ ...params.row, countrycode: value.country_code_name });
            setShowPdf(true);
          }}
        />
      ),
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
      editable: true,
    },
    {
      field: "category_vessel",
      headerName: "Vessel Type",
      width: 150,
    },
    {
      field: "department",
      headerName: "Department",
      width: 300,
      editable: true,
    },
  
    {
      field: "position_applying",
      headerName: "Position",
      width: 300,
      editable: true,
    },
    {
      field: "company",
      headerName: "Company",
      width: 170,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 380,
      editable: true,
    },
    {
      field: "place_of_birth",
      headerName: "Nationality",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 250,
      editable: true,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 150,
      editable: true,
    },
    {
      field: "sex",
      headerName: "Gender",
      width: 100,
      editable: true,
    },
    {
      field: "nearly_airport",
      headerName: "Nerly Airport",
      width: 590,
      editable: true,
    },
  ];
}
else {
  columns = [
    {
      field: "Id",
      headerName: "View CV",
      width: 80,
      renderCell: (params) => (
        <PersonIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={() => {
            const countryIndex = Number(params.row.countrycode) - 1;
            const value = countryCodes[countryIndex];
            setperson({ ...params.row, countrycode: value.country_code_name });
            setShowPdf(true);
          }}
        />
      ),
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
      editable: true,
    },
    {
      field: "category_vessel",
      headerName: "Vessel Type",
      width: 150,
    },
    {
      field: "department",
      headerName: "Department",
      width: 350,
      editable: true,
    },
  
    {
      field: "position_applying",
      headerName: "Position",
      width: 300,
      editable: true,
    },
    {
      field: "company",
      headerName: "Company",
      width: 170,
      editable: true,
    },
    {
      field: "status",
      headerName: "Status",
      width: 380,
      editable: true,
    },
    {
      field: "place_of_birth",
      headerName: "Nationality",
      width: 180,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      width: 250,
      editable: true,
    },
    {
      field: "date_of_birth",
      headerName: "Date of Birth",
      width: 150,
      editable: true,
    },
    {
      field: "sex",
      headerName: "Gender",
      width: 100,
      editable: true,
    },
    {
      field: "nearly_airport",
      headerName: "Nerly Airport",
      width: 590,
      editable: true,
    },
  ];
}

  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);
      setIsLoading(true);
      await getAllData();
      setIsLoading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = staffData.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      setStaffData(filteredRows);
    }
  };

  let csvData = [];
  let headers = [];

  const getCsvData = () => {
  if(userdata.rol === "PMSSA"){
    headers = [
      "Partner",
      "Full name",
      "Vessel Type",
      "Department",
      "Position",
      "Status",
      "Company",
      "Nationality",
      "Email",
      "Phone",
      "Date of Birth",
      "Gender",
      "Nerly Airport"

    ];
    filteredData.forEach((item) => {
      csvData.push({
        partner: item.partnername,
        fullname: item.fullname,
        vesselType:item.category_vessel,
        department: item.department,
        position: item.position_applying,
        status: item.status,
        company: item.company,
        nationality: item.place_of_birth,
        email: item.email,
        phone: item.phone,
        dateofbirth: item.date_of_birth,
        gender: item.sex,
        nearlyAirport: item.nearly_airport,
      });
    });
    return [headers, csvData];
  }
  else {
    headers = [
      "Full name",
      "Vessel Type",
      "Department",
      "Position",
      "Status",
      "Company",
      "Nationality",
      "Email",
      "Phone",
      "Date of Birth",
      "Gender",
      "Nerly Airport"

    ];
    filteredData.forEach((item) => {
      csvData.push({
        fullname: item.fullname,
        vesselType:item.category_vessel,
        department: item.department,
        position: item.position_applying,
        status: item.status,
        company: item.company,
        nationality: item.place_of_birth,
        email: item.email,
        phone: item.phone,
        dateofbirth: item.date_of_birth,
        gender: item.sex,
        nearlyAirport: item.nearly_airport,
      });
    });
    return [headers, csvData];
  }
  };

  useEffect(() => {
    const filterdatabackend = applications.filter((item, value, index) => {
      const filterVessel = vesseltypefilter !== '' ? item.category_vessel.toUpperCase()  === vesseltypefilter.toUpperCase()  : true;
      const filterDepartament = departmentfilter !== '' ? item.department.toUpperCase() === departmentfilter.toUpperCase() : true;
      const filterPosition = positionfilter !== '' ? item.position_applying.toUpperCase()  === positionfilter.toUpperCase()  : true;
      const filterCompany = companyfilter !== '' ? item.company.toUpperCase() === companyfilter.toUpperCase() : true;
      const filterStatus = statusfilter !== '' ? item.status.toUpperCase()  === statusfilter.toUpperCase()  : true;
      const filterNationality = nationalityfilter !== '' ? item.place_of_birth.toUpperCase() === nationalityfilter.toUpperCase() : true;
  
      return (
        filterVessel &&
        filterDepartament &&
        filterPosition &&
        filterCompany &&
        filterStatus &&
        filterNationality
      );
     })
  
     let filteredDataWithSearch = filterdatabackend;
  
     if (searchText !== '') {
      const partesBusqueda = searchText.split(' '); 
      const searchRegex = new RegExp(partesBusqueda.map(escapeRegExp).join('.*'), 'i');
      filteredDataWithSearch = filterdatabackend.filter((row) => {
        return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
      });
    }
  
    const upper = filteredDataWithSearch.map((item) => {
      return { ...item, fullname: item.fullname.toUpperCase() };
    });
  
     setFilteredData(upper)
    
    }, [applications, vesseltypefilter, departmentfilter, positionfilter, companyfilter, statusfilter, nationalityfilter, searchText]);
  
  

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        {showPdf ? (
          <>
            <PDFViewer
              style={{
                width: "90%",
                height: "100vh",
                zIndex: 99999999,
                position: "fixed",
                top: 0,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Document>
                <PdfDocument person={person} certData={certsData} />
              </Document>
            </PDFViewer>
            <Button
              sx={{
                position: "fixed",
                top: 0,
                right: 15,
                color: "white",
                zIndex: 9999999,
                fontSize: 30,
              }}
              onClick={() => setShowPdf(!showPdf)}
            >
              X
            </Button>
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: 999999,
                left: 0,
                top: 0,
                position: "fixed",
                backgroundColor: "black",
                opacity: 0.5,
              }}
            />
          </>
        ) : null}
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          <Grid item>
          <Grid container>
            <Grid item xs={3}>
                <Label 
                  text='Filter by Vessel Type'
                 />
                 <DropDown
                  name='vesseltype'
                  label='vesseltype'
                  value={vesseltypefilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setvesseltypefilter(e.target.value)}
                  lists={vesselTypelist}
                 />
             </Grid>
            <Grid item xs={3}>
                <Label 
                  text='Filter by Department'
                 />
                 <DropDown 
                  name='department'
                  label='department'
                  value={departmentfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setdepartmentfilter(e.target.value)}
                  lists={departmentlist}
                 />
             </Grid>
             <Grid item xs={3}>
               <Label 
                 text='Filter by Position'
                 />
                 <DropDown 
                  name='position'
                  label='position'
                  value={positionfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setpositionfilter(e.target.value)}
                  lists={positionlist}
                 />
            </Grid>
            <Grid item xs={3}>
                <Label 
                  text='Filter by Company'
                 />
                 <DropDown 
                  name='company'
                  label='company'
                  value={companyfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setcompanyfilter(e.target.value)}
                  lists={companylist}
                 />
            </Grid>
            <Grid item xs={3} sx={{ mt: 2 }}>
                <Label 
                 text='Filter by Status'
                 />
                 <DropDown 
                  name='status'
                  label='status'
                  value={statusfilter}
                  style={{ width: 200, mr: 2, mb: 2 }}
                  change={(e) => setstatusfilter(e.target.value)}
                  lists={statuslist}
                 />
            </Grid>
             <Grid item xs={3} sx={{ mt: 2 }}>
                <Label 
                 text='Filter by Nationality'
                 />
                 <DropDown 
                  name='nationality'
                  label='nationality'
                  value={nationalityfilter}
                  style={{ width: 200, mr: 2, mb: 2 }}
                  change={(e) => setnationalityfilter(e.target.value)}
                  lists={nationalitylist}
                 />
            </Grid>
            <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}>
                <ButtonCleanFilters 
                   style={{ mr: 2 }}
                   click={() => {
                    setStaffData(staffData);
                    setvesseltypefilter("");
                    setdepartmentfilter("");
                    setpositionfilter("");
                    setcompanyfilter("");
                    setstatusfilter("");
                    setnationalityfilter("");
                    setSearchText("");
                   }}     
                  />
            <ExportReportBtn getData={getCsvData} />
            </Grid>
            </Grid>
            </Grid>
          {/* NEW CHART HEREtt */}
          {staffData && (
            <Box sx={{ p: 3 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 2,
                }}
              >
              </Box>
              <Box sx={{ height: "70vh" }}>
                <DataGrid
                  rows={filteredData}
                  columns={columns}
                  sx={{
                    boxShadow: 2,
                  }}
                  loading={isLoading}
                  pagination
                  rowsPerPageOptions={[20, 50, 100]}
                  pageSize={pageSize}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  initialState={{
                    pagination: {
                      pageSize: pageSize,
                    },
                  }}
                  components={{ Toolbar: QuickSearchToolbar }}
                  componentsProps={{
                    toolbar: {
                      value: searchText,
                      onChange: (event) => {
                        requestSearch(event.target.value);
                      },
                      clearSearch: () => requestSearch(""),
                    },
                    pagination: { classes: null },
                  }}
                />
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </>
  );
}
