import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { PDF_FONT_SIZE } from "../../../constants";
import moment from "moment";

const VaccinationBook = ({ documents, COC, person, lightGrey, hideCoC }) => {
    const [vaccines, setVaccines] = useState([]);
    const [docsList, setDocsList] = useState([]);
    const filteredCoc = COC.filter(item => Object.values(item).some(value => value !== ''))
    const newDocumentsarray = documents.map(item => {
      return {...item, 'Date of issue': item['Date of Issue'] }
    })
    const combinedArray = newDocumentsarray.concat(vaccines)
    const filteryellowfever = combinedArray.filter(row => row['Type of Document'] === 'YELLOW FEVER' && row['Date of issue']?.length > 0)
    const filtercovidvaccines = combinedArray.filter(row => row.Doze)
    const  vaccinescovid_nuevo = filtercovidvaccines.map(objeto => {
      return {...objeto, 'Type of Document': 'COVID BOOK'}
    })
    const vaccionation_new = vaccinescovid_nuevo.concat(filteryellowfever)

 
    
    useEffect(() => {
      if (person.vaccination_info !== "") {
        setVaccines(JSON.parse(person.vaccination_info));
      } else {
        const emptyVaccines = [
          {
            Doze: "First Doze",
            Type: "COVID BOOK",
          },
          {
            Doze: "Second Doze",
            Type: "COVID BOOK",
          },
          {
            Doze: "Booster",
            Type: "COVID BOOK",
          },
        ];
        setVaccines(emptyVaccines);
      }
    }, [documents]);


  return (
    <>
    <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  8. VACCINATION BOOK
                </Text>
    <View style={styles.row}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", paddingTop: 5, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          VACCINATION BOOK
        </Text>
      </View>
    <View
    style={[
      styles.row,
      { border: 0.5 },
    ]}
  >
  <View style={{ width: "25%" }}>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { minHeight: 20, backgroundColor: "#b8cce4" },
      ]}
    >
      TYPE OF VACCINE
    </Text>
    <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {vaccionation_new.length > 0 ? (
          <>
            {vaccionation_new.map((row, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { textAlign: "center", minHeight: 20, width: "100%", backgroundColor: "#b8cce4" },
                    ]}
                  >
                    {row["Type of Document"]}
                  </Text>
                </View>
              );
            })}
          </>
        ) : (
          <View style={styles.row}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { textAlign: "center", minHeight: 20, width: "100%", backgroundColor: "#b8cce4" },
                    ]}
                  >
                    {""}
                  </Text>
                </View>
        )}
      </View>
    </View>
    <View style={{ width: "25%" }}>
      <Text
        style={[
          styles.cell,
          styles.normalTableText,
          { minHeight: 20, backgroundColor: "#b8cce4" },
        ]}
      >
        COUNTRY
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {vaccionation_new.length > 0 ? (
          <>
            {vaccionation_new.map((row, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { textAlign: "center",  minHeight: 20, width: "100%" },
                    ]}
                  >
                    
                  </Text>
                </View>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </View>
      </View>
    <View style={{ width: "25%" }}>
      <Text
        style={[
          styles.cell,
          styles.normalTableText,
          { minHeight: 20, backgroundColor: "#b8cce4" },
        ]}
      >
        DOZE
      </Text>
      <View
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {vaccionation_new.length > 0 ? (
          <>
            {vaccionation_new.map((row, index) => {
              return (
                <View key={index} style={styles.row}>
                  <Text
                    style={[
                      styles.cell,
                      styles.normalTableText,
                      { textAlign: "center", minHeight: 20, width: "100%", backgroundColor: "#b8cce4" },
                    ]}
                  >
                    {row.Doze}
                  </Text>
                </View>
              );
            })}
          </>
        ) : (
          <View style={styles.row}>
          <Text
            style={[
              styles.cell,
              styles.normalTableText,
              { textAlign: "center", minHeight: 20, width: "100%", backgroundColor: "#b8cce4" },
            ]}
          >
            {""}
          </Text>
        </View>
        )}
      </View>
    </View>
    <View style={{ width: "35%" }}>
    <View style={{ width: "100%", display: "flex", flexDirection: "row" }}>
      <Text
        style={[
          styles.cell,
          styles.normalTableText,
          { width: "55%",  minHeight: 20, backgroundColor: "#b8cce4" },
        ]}
      >
        DATE OF ISSUE
       (MM/DD/YYYY)
      </Text>
      <Text
        style={[
          styles.cell,
          styles.normalTableText,
          { width: "55%",  minHeight: 20, backgroundColor: "#b8cce4" },
        ]}
      >
        VACCINATION MARK
      </Text>
      </View>
      {vaccionation_new.length > 0 ? (
        <>
          {vaccionation_new.map((row, index) => {
            return (
              <View key={index} style={styles.row}>
                <Text
                  style={[
                    styles.normalTableText,
                    styles.cell,
                    { width: "55%", minHeight: 20 },
                  ]}
                >
                  {row["Date of issue"] ? moment(row["Date of issue"]).format("MM/DD/YYYY") : ''}
                </Text>
                <Text
                  style={[
                    styles.normalTableText,
                    styles.cell,
                    { textAlign: "center", width: "55%", minHeight: 20 },
                  ]}
                >
                  {row["Vaccination Mark"]
                    ? row["Vaccination Mark"]
                    : row["Vaccine Brand"]}
                </Text>
              </View>
            );
          })}
        </>
      ) : (
        <>
         <View style={styles.row}>
                <Text
                  style={[
                    styles.normalTableText,
                    styles.cell,
                    { width: "55%", minHeight: 20 },
                  ]}
                >
                  {""}
                </Text>
                <Text
                  style={[
                    styles.normalTableText,
                    styles.cell,
                    { textAlign: "center", width: "55%", minHeight: 20 },
                  ]}
                >
                  {""}
                </Text>
              </View>
        </>
      )}
    </View>
    </View>
    </>
  )
}

const styles = StyleSheet.create({
    mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
    topSectionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    firstTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 20,
    },
    icon: {
      width: 70,
      height: 90,
      borderWidth: 0.5,
      borderColor: "black",
    },
    row: {
      borderTop: 0.5,
      borderColor: "black",
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    cell: {
      borderLeft: 0.5,
      borderColor: "black",
      textAlign: "center",
      minHeight: 23,
      overflow: "hidden",
    },
    normalTableText: {
      fontSize: PDF_FONT_SIZE,
    },
    verticalCell: {
      display: "flex",
    },
  });
  

export default VaccinationBook