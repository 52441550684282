import React, { Component } from "react";
import Chart from "react-apexcharts";
import Title from "../Commons/Title";
import Grid from "@mui/material/Grid";

export default function CircularPanel({ data, title }) {
  let labels = [];
  let series = [];

  data.map((item) => {
    labels.push(item.id);
    series.push(item.total);
  });

  const datos = {
    options: {
      labels: labels,
    },
    series: series,
  };

  const barstate = {
    options: {
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      xaxis: {
        categories: labels,
      },
    },
    series: [
      {
        data: series,
      },
    ],
  };

  return (
    <Grid container>
      <Grid item>
        <div className="donut">
          <Title>{title}</Title>

          <Chart
            options={datos.options}
            series={datos.series}
            type="donut"
            width="500"
          />
        </div>
      </Grid>
      <Grid item>
        <div className="bar">
          <Chart
            options={barstate.options}
            series={barstate.series}
            type="bar"
            width="500"
          />
        </div>
      </Grid>
    </Grid>
  );
}
