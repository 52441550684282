import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingProgress from "../Commons/LoadingProgress";
import { useNavigate } from "react-router-dom";
import { TEMPLATE } from "../../emails/Emails";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import { size } from "lodash";
import Paper from "@mui/material/Paper";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ErrorBoundary from "../Commons/ErrorBoundary";

import {
  addRecord,
  getCurrentUser,
  sendEmail,
  getCompanies,
  userExists,
  getDatabyId,
  getData,
  updateRecord,
  getRecordById,
  updateUserPassword,
} from "../../Actions/Actions";
import HideAndShowPassword from "../Buttons/HideAndShowPassword";
import { decryptPassword, encryptPassword } from "../../utils/utils";
const theme = createTheme();

const userdata = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  status: true,
};

export default function EditUser() {
  const [company, setcompany] = useState("PMSSA");
  const [state, setstate] = useState(userdata);
  const [rol, setrol] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [initialLoadingIcon, setInitialLoadingIcon] = useState(true);
	const [isHideText, setIsHideText] = useState(true);
  const [initialEmail, setInitialEmail] = useState("");

  const [companylist, setcompanylist] = useState([]);
  let QUERY = window.location.search;
  useEffect(() => {
    const loadData = async () => {
      let id = QUERY.replace("?id=", "");
      const resultadouser = await getRecordById("Users", id);
      let resultado = await getData("Companies");
      resultado = resultado.filter((item) => item.status === true);
      setcompanylist(resultado);
      setcompany(resultadouser.company);
      setrol(resultadouser.rol);
      setInitialEmail(resultadouser.email)
      setstate({...resultadouser, 
        password: decryptPassword(resultadouser.password)
      });
      setInitialLoadingIcon(false)
    };

    loadData();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const handleChange = (event) => {
    setcompany(event.target.value);
  };

  const handleChangerol = (event) => {
    setrol(event.target.value);
  };

  const onChange = (event) => {
    setstate({ ...state, [event.target.name]: event.target.value });
  };

  const updateUser = async () => {

    let id = QUERY.replace("?id=", "");
    setLoading(true);
    const user = getCurrentUser();

    if (!state.firstName) {
      showToast("Please Introduce a Name", 2);
    } else if (!state.lastName) {
      showToast("Please Introduce a Last Name", 2);
    } else if (!state.email) {
      showToast("Please Introduce an Email", 2);
    } else if (!validaremail(state.email)) {
      showToast("Please Introduce a Valid Email", 2);
    } else if (!state.password) {
      showToast("Please Introduce an Password", 2);
    } else if (!rol) {
      showToast("Please Choose a Rol", 2);
    } else {
      const data = {
        ...state,
        email: state.email ? state.email.toLowerCase() : state.email,
        id: new Date().getTime(),
        company: company,
        updatedAt: new Date(),
        password: encryptPassword(state.password),
        rol: rol,
      };
      const { response } = await updateUserPassword(initialEmail, state.email, state.password)
      if (!response){
        showToast("Error when trying to update user", 2);
      }else{
        const addresult = await updateRecord("Users", id, data);
        showToast("User was update sucesfull", 1);
      }
    }
    setLoading(false);
  };

  const validaremail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(text) === false) {
      return false;
    } else {
      return true;
    }
  };

  const showToast = (message, type) => {
    if (type === 1) {
      toast.success(message, { position: "top-center" });
    } else if (type === 2) {
      toast.warn(message, { position: "top-center" });
    } else if (type === 3) {
      toast.error(message, { position: "top-center" });
    }
  };

  const Company = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Company</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={company}
          label="Company"
          onChange={handleChange}
          fullWidth
        >
          {companylist.map((item) => (
            <MenuItem value={item.company} key={item.id}>
              {item.company}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const Rol = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="rol-select-label"> Rol</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="rol-select"
          value={rol}
          label="Company"
          onChange={handleChangerol}
          fullWidth
        >
          <MenuItem value="PMSSA" key="1">
            PMSSA
          </MenuItem>
          <MenuItem value="EXTERNAL" key="2">
            EXTERNAL
          </MenuItem>
          <MenuItem value="PARTNER" key="3">
            PARTNER
          </MenuItem>
          <MenuItem value="AUDIT" key="4">
            AUDIT
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <Box sx={{ display: "flex" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper>
              <Box
                sx={{
                  marginTop: 8,
                  padding: 5,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Grid container>
                  <ArrowBackIosNewRoundedIcon
                    autoReverse={true}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </Grid>
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}></Avatar>
                <Typography component="h1" variant="h5">
                  Update User
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 3 }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        autoFocus
                        value={state.firstName}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        value={state.lastName}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={state.email}
                        onChange={(e) => onChange(e)}
                      />
                    </Grid>

                    <Grid container spacing={4} item xs={12} sm={6}>
                      <Grid item xs={10} sm={9} md={10}>
												<TextField
													required
													fullWidth
													name="password"
													label="Change in Recovery Password on Sign In Screen"
													type={ isHideText ? "password" : "text"}  
													id="password"
													autoComplete="new-password"
													value={state.password}
													onChange={(e) => onChange(e)}
												/>
                      </Grid>
                      <Grid item xs={1} sm={1} justifyContent="center" alignItems="center" container>
                        <HideAndShowPassword setIsHideText={setIsHideText} isHideText={isHideText}/>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Company fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Rol />
                    </Grid>
                    <Grid item xs={12}>
                      Status
                      <Switch
                        checked={state.status}
                        onChange={() => {
                          setstate({ ...state, status: !state.status });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={updateUser}
                    disabled={loading}
                  >
                    {!loading ? (
                      "Update User"
                    ) : (
                      <CircularProgress color="secondary" />
                    )}
                  </Button>
                </Box>
              </Box>
              <ToastContainer />
            </Paper>
          </Container>
        </Box>
      </ErrorBoundary>
      <LoadingProgress loading={initialLoadingIcon} />
    </ThemeProvider>
  );
}
