import {
  Image,
  Text,
  View,
  StyleSheet,
  Page,
  Document,
} from "@react-pdf/renderer";
import { useState, useEffect } from "react";
import { getRecordById, getSkills } from "../Actions/Actions";
import img from "../assets/logo.jpg";
import "./Pdf.css";
import BasicInfo from "./Commons/PdfForms/BasicInfo";
import PersonalInformation from "./Commons/PdfForms/PersonalInformation";
import MarlinTest from "./Commons/PdfForms/MarlinTest";
import EmergencyContact from "./Commons/PdfForms/EmergencyContact";
import Certificates from "./Commons/PdfForms/Certificates";
import OtherCertificates from "./Commons/PdfForms/OtherCertificates";
import CocDocuments from "./Commons/PdfForms/CocDocuments";
import VaccinationBook from "./Commons/PdfForms/VaccinationBook";
import ExpOnBoard from "./Commons/PdfForms/ExpOnBoard";
import ExpOnShore from "./Commons/PdfForms/ExpOnShore";
import Observations from "./Commons/PdfForms/Observations";
import Skills from "./Commons/PdfForms/Skills";
import {
  AB_POSITIONS,
  BOSUN_POSITIONS,
  COOK_POSITIONS,
  DOCUMUMENT_LIST,
  DOCUMUMENT_LIST_OFFICERS,
  FITTER_POSITIONS,
  MESSMAN_POSITIONS,
  OFFICER_POSITIONS,
  OILER_POSITIONS,
  PDF_FONT_SIZE,
  abOsSkill,
  bosunSkill,
  cookSkill,
  fitterSkill,
  messmanSkill,
  oiler,
  skillArray,
  wiper,
} from "../constants";

const PdfDocument = ({ person, certData }) => {
  const [names, setNames] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [documents, setdocuments] = useState([]);
  const [COC, setCOC] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [otherTraining, setOtherTraining] = useState([]);
  const [expOnShore, setExpOnShore] = useState([]);
  const [expOnBoard, setExpOnBoard] = useState([]);
  const [skillTable, setSkillTable] = useState([]);
  const [sosContacts, setSosContacts] = useState([]);
  const [idProcessAnswers, setIdProcessAnswers] = useState([]);
  const [idProcessQuestions, setIdProcessQuestions] = useState([]);

  const getSkillFromIdProcess = async (idprocessdata) => {
    let filteredQuestions = [];
    if (person.idproceso !== "") {
      //TO DO MAKE THE PROCESS ID QUESTIONStttt
      idprocessdata.fields.forEach((item) => {
        if (
          Object.keys(item).some(function (k) {
            return ~k.indexOf("Answer");
          })
        ) {
          filteredQuestions.push(item);
        }
      });
    }
    if (
      AB_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let ABOS_1 = filteredQuestions.slice(0, 18);
      let ABOS_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...ABOS_1, ...ABOS_2]);
      setIdProcessQuestions(abOsSkill);
    }
    if (
      BOSUN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let BOSUN_1 = filteredQuestions.slice(18, 36);
      let BOSUN_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...BOSUN_1, ...BOSUN_2]);
      setIdProcessQuestions(bosunSkill);
    }
    if (
      COOK_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let COOK = filteredQuestions.slice(36, 80);
      setIdProcessAnswers(COOK);
      setIdProcessQuestions(cookSkill);
    }
    if (
      MESSMAN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let MESSMAN = filteredQuestions.slice(88, 105);
      setIdProcessAnswers(MESSMAN);
      setIdProcessQuestions(messmanSkill);
    }
    if (
      FITTER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let FITTER_1 = filteredQuestions.slice(71, 88);
      let FITTER_2 = filteredQuestions.slice(118, 140);
      setIdProcessAnswers([...FITTER_1, ...FITTER_2]);
      setIdProcessQuestions(fitterSkill);
    }
    if (
      OILER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying.toUpperCase()
      )
    ) {
      let OILER_1 = filteredQuestions.slice(96, 100);
      let OILER_2 = filteredQuestions.slice(105, 127);
      setIdProcessAnswers([...OILER_1, ...OILER_2]);
      setIdProcessQuestions(oiler);
    }
    if (person.position_applying === "WIPER") {
      let WIPER_1 = filteredQuestions.slice(100, 127);
      setIdProcessAnswers(WIPER_1);
      setIdProcessQuestions(wiper);
    }
  };

  useEffect(() => {
    const getSkillRecord = async () => {
      let hasFirebaseSkills = false;
      await getRecordById("Skills", person.Id.toString()).then((res) => {
        if (Array.isArray(res.skills) && res.skills.length > 0) {
          hasFirebaseSkills = true;
          setSkillTable(res.skills);
        }
      });
      if (hasFirebaseSkills) return;
      if (person.idproceso !== "") {
        const idProcessData = await getSkills(person.idproceso);
        getSkillFromIdProcess(idProcessData);
        return;
      }
    };
    getSkillRecord();
    if (person.emergencycontact !== "") {
      setSosContacts(JSON.parse(person.emergencycontact));
    }
    let [firstName, secName, secLastName, firstLastName, LastName2] =
      person.fullname.split(" ");
    LastName2 = LastName2 === undefined ? "" : LastName2;
    setNames([firstName, secName, secLastName, firstLastName, LastName2]);
    const usableDocumentList = OFFICER_POSITIONS.includes(
      person.position_applying
    )
      ? DOCUMUMENT_LIST_OFFICERS
      : DOCUMUMENT_LIST;
    if (person.type_document !== "") {
      let localDocs = JSON.parse(person.type_document);
      usableDocumentList.forEach((document) => {
        let exists = localDocs.some(
          (obj) =>
            obj["Type of Document"]?.toUpperCase() === document.toUpperCase()
        );
        if (!exists) {
          localDocs.push({ "Type of Document": document });
        }
      });
      setdocuments(localDocs);
    } else {
      const empty_doc_list = usableDocumentList.map((item) => {
        return { "Type of Document": item };
      });
      setdocuments(empty_doc_list);
    }
    if (person.COC !== "") {
      setCOC(JSON.parse(person.COC));
    }
    if (person.lic_sbook_stcw_certificates !== "") {
      const filteredArray = certData.filter((obj) => {
        const keys = Object.keys(obj).map((item) => {
          return item.toUpperCase();
        });
        if (
          OFFICER_POSITIONS.map((item) => item.toUpperCase()).includes(
            person.position_applying.toUpperCase()
          )
        ) {
          return obj["officer"] === "1";
        } else if (person.position_applying.toUpperCase().includes("COOK")) {
          return obj["cook"] === "1";
        } else if (!keys.includes(person.position_applying.toUpperCase())) {
          return obj["hotelstaff"] === "1";
        } else {
          return Object.keys(obj).some((key) => {
            const value = obj[key];
            return (
              typeof value === "string" &&
              key.toUpperCase() === person.position_applying.toUpperCase() &&
              value === "1"
            );
          });
        }
      });

      const courseNames = filteredArray.map((item) =>
        item.curse_name?.toUpperCase()
      );
      const localCourses = JSON.parse(person.lic_sbook_stcw_certificates);

      courseNames.forEach((course) => {
        let exists = localCourses.some(
          (obj) =>
            obj["Description of cert / course"]?.toUpperCase() ===
            course.toUpperCase()
        );
        if (!exists) {
          localCourses.push({ "Description of cert / course": course });
        }
      });
      setCertificates(localCourses);
    }
    if (person.other_training !== "") {
      setOtherTraining(JSON.parse(person.other_training));
    }
    if (person.experience_onshore !== "") {
      setExpOnShore(JSON.parse(person.experience_onshore));
    }
    if (person.experience_onboard !== "") {
      setExpOnBoard(JSON.parse(person.experience_onboard));
    }
    fixCoCs();
  }, [person]);

  const fixCoCs = () => {
    if (
      AB_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-C");
      fixCocFunction(["COC II/4", "COC II/5"]);
    } else if (
      BOSUN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-D");
      fixCocFunction(["COC II/5"]);
    } else if (
      OFFICER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-A");
      fixCocFunction(["COC"]);
    } else if (
      COOK_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-B");
      fixCocFunction(["COC"]);
    } else if (
      MESSMAN_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-E");
      fixCocFunction(["COC II/4", "COC VI/1"]);
    } else if (
      FITTER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-F");
      fixCocFunction(["COC III/5", "COC VI/6-2"]);
    } else if (
      OILER_POSITIONS.map((item) => item.toUpperCase()).includes(
        person.position_applying?.toUpperCase()
      )
    ) {
      setCodigo("F-PMSSA-01-G");
      fixCocFunction(["COC III/4", "COC III/5"]);
    } else if (person.position_applying?.toUpperCase() === "WIPER") {
      setCodigo("F-PMSSA-01-G");
      fixCocFunction(["COC III/4", "COC III/5"]);
    } else {
      setCodigo("F-PMSSA-01");
      fixCocFunction(["COC"]);
    }
  };

  const fixCocFunction = (abskills) => {
    if (person.COC) {
      const coc = JSON.parse(person.COC);
      const updatedCOC = [...coc];

      abskills.forEach((skill) => {
        const foundItem = coc.find((item) => item["COC DOC."] === skill);
        if (!foundItem) {
          updatedCOC.push({ "COC DOC.": skill });
        }
      });
      setCOC(updatedCOC);
    } else {
      const updatedCOC = [];
      abskills.forEach((skill) => {
        updatedCOC.push({ "COC DOC.": skill });
      });
      setCOC(updatedCOC);
    }
  };

  const lightGrey = "#e9e9e9";
  const limitedContacts = sosContacts.slice(0, 2);
  const cleanexponshore = expOnShore.filter((objeto) => {
    return Object.values(objeto).some((valor) => valor !== "");
  });

  return (
    <>
      <Page
        size="Legal"
        style={{
          padding: 40,
          paddingBottom: 90,
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <View style={styles.mainContainer}>
          {/* TOP SECTION */}
          <View style={styles.topSectionContainer}>
            <Image src={img} style={{ width: 100 }} />
          </View>
          {/* TEXT BEFORE THE TABLEt */}
          {/* TABLE ITSELFttttt  */}
          <View>
            <View
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text style={{ fontSize: 16 }}>
                {person.position_applying.toUpperCase() === "FITTER"
                  ? "FISEAFARER APPLICATION FORM"
                  : "SEAFARER APPLICATION FORM"}
              </Text>
              <Text style={{ marginTop: 10, fontSize: 10 }}>
                POSITION APPLYING FOR RANK:{" "}
                <Text style={{ textDecoration: "underline" }}>
                  {person.position_applying}
                </Text>
              </Text>
            </View>
            <View></View>
          </View>
          <View style={styles.topSectionContainer}>
            <View style={styles.icon}>
              {person.user_selfie ? (
                <Image
                  style={{
                    objectFit: "cover",
                    objectPosition: "center center",
                    width: "80",
                    height: "100",
                    borderWidth: 0.5,
                    alignSelf: "center",
                    borderColor: "black",
                  }}
                  src={person.user_selfie}
                  alt="User selfie"
                />
              ) : (
                <>
                  <Text style={{ fontSize: 10 }}>
                    PHOTO IN COLOR AND IN PROFESSIONAL DRESS. NO CASUAL PHOTOS
                  </Text>
                </>
              )}
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <BasicInfo names={names} person={person} />
            </View>
          </View>
        </View>
        <View style={[styles.topSectionContainer]}>
          <PersonalInformation names={names} person={person} />
        </View>
        <View style={[styles.topSectionContainer]}>
          <MarlinTest names={names} person={person} />
        </View>
        <View style={[styles.topSectionContainer]}>
          <EmergencyContact
            names={names}
            person={person}
            sosContacts={limitedContacts}
          />
        </View>
        <View style={styles.pageNumber} fixed>
          <Text style={{ fontSize: 8 }}>Code: {codigo}</Text>
          <Text style={{ fontSize: 8 }}>Revision: 00</Text>
          <Text style={{ fontSize: 8 }}>Date: May 17, 2022</Text>
          <Text
            style={{ fontSize: 8 }}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
            fixed
          />
        </View>
      </Page>
      {expOnBoard.length > 17 ? (
        <Page
          size="Legal"
          style={{
            padding: 40,
            paddingBottom: 90,
            flexDirection: "column",
          }}
        >
          <View style={styles.topSectionContainer}>
            <ExpOnBoard
              styles={styles}
              person={person}
              expOnBoard={expOnBoard}
            />
          </View>
          <View style={[styles.topSectionContainer, { marginTop: 20 }]}>
            <CocDocuments
              person={person}
              documents={documents}
              COC={COC}
              lightGrey={lightGrey}
            />
          </View>
          <View style={{ padding: 10, marginTop: 20 }}>
            <Certificates
              styles={styles}
              person={person}
              certificates={certificates}
              otherTraining={otherTraining}
              lightGrey={lightGrey}
            />
          </View>
          <View style={styles.pageNumber} fixed>
            <Text style={{ fontSize: 8 }}>Code: {codigo}</Text>
            <Text style={{ fontSize: 8 }}>Revision: 00</Text>
            <Text style={{ fontSize: 8 }}>Date: May 17, 2022</Text>
            <Text
              style={{ fontSize: 8 }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} of ${totalPages}`
              }
              fixed
            />
          </View>
          <View style={{ padding: 10, marginTop: 20 }}>
            <ExpOnShore
              styles={styles}
              person={person}
              expOnShore={expOnShore}
            />
          </View>
          <View style={{ padding: 10, marginTop: 20 }}>
            <OtherCertificates
              styles={styles}
              person={person}
              certificates={certificates}
              otherTraining={otherTraining}
              lightGrey={lightGrey}
            />
          </View>
          <View style={{ padding: 10, marginTop: 20 }}>
            <VaccinationBook
              person={person}
              documents={documents}
              COC={COC}
              lightGrey={lightGrey}
            />
          </View>
          {person.position_applying === "ENGINE CADET" ||
          person.position_applying === "DECK CADET" ||
          person.position_applying === "DECK OFFICERS" ||
          person.position_applying === "ENGINE OFFICERS" ||
          person.position_applying === "ENGINE OFFICER" ||
          person.position_applying === "DECK OFFICER" ||
          person.position_applying === "ELECT. CADET" ? null : (
            <View style={{ padding: 10 }}>
              <Skills
                person={person}
                skillArray={skillArray}
                skillTable={skillTable}
                idProcessQuestions={idProcessQuestions}
                idProcessAnswers={idProcessAnswers}
              />
            </View>
          )}
        </Page>
      ) : (
        <>
          <Page
            size="Legal"
            style={{
              padding: 40,
              paddingBottom: 80,
              flexDirection: "column",
            }}
          >
            <View style={styles.topSectionContainer}>
              <ExpOnBoard
                styles={styles}
                person={person}
                expOnBoard={expOnBoard}
              />
            </View>
            <View style={styles.topSectionContainer}>
              <CocDocuments
                person={person}
                documents={documents}
                COC={COC}
                lightGrey={lightGrey}
              />
            </View>
            <View style={{ padding: 10, marginTop: 10 }}>
              <Certificates
                styles={styles}
                person={person}
                certificates={certificates}
                otherTraining={otherTraining}
                lightGrey={lightGrey}
              />
            </View>
            <View style={styles.pageNumber} fixed>
              <Text style={{ fontSize: 8 }}>Code: {codigo}</Text>
              <Text style={{ fontSize: 8 }}>Revision: 00</Text>
              <Text style={{ fontSize: 8 }}>Date: May 17, 2022</Text>
              <Text
                style={{ fontSize: 8 }}
                render={({ pageNumber, totalPages }) =>
                  `Page ${pageNumber} of ${totalPages}`
                }
                fixed
              />
            </View>
            <View style={{ padding: 10, marginTop: 10 }}>
              <ExpOnShore
                styles={styles}
                person={person}
                expOnShore={cleanexponshore}
              />
            </View>
            <View style={{ padding: 10, marginTop: 10 }}>
              <OtherCertificates
                styles={styles}
                person={person}
                certificates={certificates}
                otherTraining={otherTraining}
                lightGrey={lightGrey}
              />
            </View>
            <View style={{ padding: 10 }}>
              <VaccinationBook
                person={person}
                documents={documents}
                COC={COC}
                lightGrey={lightGrey}
              />
            </View>
            {person.position_applying === "ENGINE CADET" ||
            person.position_applying === "DECK CADET" ||
            person.position_applying === "DECK OFFICERS" ||
            person.position_applying === "ENGINE OFFICERS" ||
            person.position_applying === "ENGINE OFFICER" ||
            person.position_applying === "DECK OFFICER" ||
            person.position_applying === "ELECT. CADET" ? null : (
              <View style={{ padding: 10 }}>
                <Skills
                  person={person}
                  skillArray={skillArray}
                  skillTable={skillTable}
                  idProcessQuestions={idProcessQuestions}
                  idProcessAnswers={idProcessAnswers}
                />
              </View>
            )}
          </Page>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 0,
  },
  mainExperienceOnboard: {
    width: "100%",
    marginTop: 0,
    paddingTop: 0,
  },
  mainExperienceOnshore: {
    width: "100%",
    marginTop: 0,
    paddingTop: 0,
  },
  mainCoc: {
    marginTop: 0,
    paddingTop: 0,
    border: 0.5,
  },
  mainCerticates: {
    width: "100%",
    marginTop: 0,
    paddingTop: 0,
  },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: 10,
  },
  firstTextContainer: {
    padding: 20,
  },
  icon: {
    width: 80,
    height: 100,
    borderWidth: 0.5,
    borderColor: "black",
    gridcolumn: 1,
    marginLeft: 20,
  },
  row: {
    borderTop: 0,
    borderRight: 0.5,
    borderColor: "black",
    width: "100%",
    minHeight: 0,
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderLeft: 0.5,
    borderColor: "black",
    textAlign: "center",
    minHeight: 0,
    paddingHorizontal: 2,
  },
  normalTableText: {
    fontSize: PDF_FONT_SIZE,
    paddingTop: 0,
  },
  verticalCell: {
    display: "flex",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 35,
    left: 0,
    right: 30,
    textAlign: "right",
    color: "grey",
  },
  container: {
    display: "grid",
    gridtemplatecolumns: "repeat(2, 1fr)",
    position: "relative",
  },
  leftColumn: {
    width: "100%",
  },
  rightColumn: {
    width: "100%",
  },
  forcedPageBreak: {
    pageBreakAfter: "avoid",
  },
});

export default PdfDocument;
