import React from 'react'
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { PDF_FONT_SIZE } from "../../../constants";

const Observations = ({ name, person }) => {
  return (
    <View style={{ width: "96%", borderRight: 0.5, borderBottom: 0.5 }}>
  <View style={styles.row}>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "40%", minHeight: 20, backgroundColor: "#b8cce4" },
      ]}
    >
      DATE
    </Text>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "40%", minHeight: 20, backgroundColor: "#b8cce4" },
      ]}
    >
      COMMENTS
    </Text>
    <Text
      style={[
        styles.cell,
        styles.normalTableText,
        { width: "40%", minHeight: 20, borderRight: 0.5, backgroundColor: "#b8cce4" },
      ]}
    >
      VALIDATED BY
    </Text>
  </View>
  <View style={styles.row}>
        <Text style={[styles.cell, styles.normalTableText, { width: "40%", minHeight: 10 }]}>
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "40%", minHeight: 10 }]}>
        </Text>
        <Text style={[styles.cell, styles.normalTableText, { width: "40%", minHeight: 10 }]}>
        </Text>
      </View>
</View>
  )
}

const styles = StyleSheet.create({
    mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
    topSectionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    firstTextContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      marginTop: 20,
    },
    icon: {
      width: 70,
      height: 90,
      borderWidth: 0.5,
      borderColor: "black",
    },
    row: {
      borderTop: 0.5,
      borderColor: "black",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      textAlign: "center"
    },
    cell: {
      display: "flex",
      borderLeft: 0.5,
      borderColor: "black",
      minHeight: 23,
    },
    normalTableText: {
      fontSize: PDF_FONT_SIZE,
      paddingTop: 5,
    },
    verticalCell: {
      display: "flex",
    },
  });

export default Observations