import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import {
  getData,
  getGapPool,
  deleteRecord,
  server,
  infoUpdateRequest,
  getDatabyId,
  updateRecord,
} from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import View from "@mui/icons-material/ViewAgenda";
import EditIcon from "@mui/icons-material/Edit";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { createStyles, makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import DocViewerComponent from "../Commons/PdfForms/DocViewerComponent";

const mdTheme = createTheme();

const download = (evt) => {
  const link = document.createElement("a");
  link.href = evt.target.attributes.value.url;
  link.setAttribute("download", "test");
  link.setAttribute("target", "new");
  document.body.appendChild(link);
  link.click();
};

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "end",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 20,
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  const VesselSelect = () => {
    return (
      <Box sx={{ minWidth: 250 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            value={props.selectValue}
            label="TYPE FILTER"
            onChange={(e) => {
              props.handleSelectChange(e);
            }}
          >
            <MenuItem value="ALL">SHOW ALL</MenuItem>
            {props.selectOptions.map((item, index) => {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <div className={classes.root}>
      <div>{/* <GridToolbarFilterButton /> */}</div>
      <VesselSelect />
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? "visible" : "hidden" }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

export const AttachmentTable = ({ data, setdatos, marine, embarcations }) => {
  const [pageSize, setPageSize] = React.useState(50);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectOptions, setSelectOptions] = React.useState([]);
  const [selectValue, setSelectValue] = React.useState("ALL");
  const [searchText, setSearchText] = React.useState("");
  const [loading, setloading] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [hiddenAttachs, setHiddenAttachs] = useState([]);

  const getHiddenAttachments = async () => {
    setIsLoading(true);
    const attachmentsData = await getDatabyId("HiddenAttachments", marine.id);
    if (attachmentsData && attachmentsData.length < 1) {
      setHiddenAttachs([]);
    } else {
      setHiddenAttachs(attachmentsData[0]?.attachmentsId);
    }
    const newList = [];
    data.forEach((item) => {
      if (!attachmentsData[0]?.attachmentsId.includes(item.id)) {
        newList.push(item);
      }
    });
    setPageData(newList);
    setIsLoading(false);
  };

  useEffect(() => {
    getHiddenAttachments();
  }, [data]);

  const columns = [
    {
      field: "download",
      headerName: "DOWNLOAD",
      width: 100,
      renderCell: (params) => (
        <DownloadForOfflineIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={async () => {
            const { downloadkey, name, reference, description, source, url } =
              params.row;
            if (source === "FLOKZU") {
              const docUri = await fetch(
                `https://api-portal.mp-ip.edu.pa/getattachments?xdownkey=${downloadkey}&reference=${reference}&field=${encodeURIComponent(
                  description
                )}&attachment=${name}`
              );
              window.open(
                `https://api-portal.mp-ip.edu.pa/getattachments?xdownkey=${downloadkey}&reference=${reference}&field=${encodeURIComponent(
                  description
                )}&attachment=${name}`
              );
            } else {
              window.open(url);
              return <DocViewerComponent url={url} />;
            }

            //llamar a la api que descarga la información.
          }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "DEL.",
      width: 50,
      renderCell: (params) => (
        <DeleteIcon
          style={{ fontSize: 25 }}
          color="primary"
          onClick={async () => {
            if (params.row.source !== "FLOKZU") {
              const { id } = params.row;
              setloading(true);
              await deleteRecord("Attachment", id);
              toast.success("Document deleted sucesfull");
              let localDataList = [...data];
              let filteredList = [];

              localDataList.forEach((item, index) => {
                if (item.id !== id) {
                  filteredList.push(item);
                }
              });
              setdatos(filteredList);
              setPageData(filteredList);
              setloading(false);
            } else {
              await updateRecord("HiddenAttachments", marine.id, {
                id: Number(marine.id),
                attachmentsId: [...hiddenAttachs, params.row.id],
              });
              getHiddenAttachments();
            }
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: "DOCUMENT NAME",
      width: 350,
      editable: true,
    },
    {
      field: "attachmentType",
      headerName: "TYPE",
      width: 200,
      editable: true,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 500,
      editable: true,
      renderCell: (params) => (
        <Typography style={{ overflowWrap: "break-word", size: 10 }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "type",
      headerName: "FLOKZU ID",
      width: 200,
      editable: true,
    },
    {
      field: "date",
      headerName: "CREATED ON",
      width: 200,
      editable: true,
    },
    {
      field: "id",
      headerName: "id",
      width: 100,
      hide: true,
    },
  ];

  const addSelectOptions = () => {
    let baseOptions = ["APPLICATION PROCESS", "HIRING PROCESS"];
    if (embarcations.length <= 3) {
      setSelectOptions([...baseOptions, "VESSEL 1", "VESSEL 2", "VESSEL 3"]);
      return;
    }
    embarcations.forEach((item, index) => {
      baseOptions.push(`VESSEL ${index + 1}`);
    });
    setSelectOptions(baseOptions);
  };

  useEffect(() => {
    addSelectOptions();
    /* let uniques = [];
    data.forEach((item) => {
      if (uniques.includes(item.attachmentType)) return;
      uniques.push(item.attachmentType);
    });
    setSelectOptions(uniques); */
  }, [data]);

  const requestSearch = async (searchValue) => {
    if (searchValue === "") {
      setSearchText(searchValue);

      setloading(true);
      const resultado = await getGapPool();
      const array = [];
      data.forEach((item) => {
        if (!hiddenAttachs.includes(item.id)) {
          array.push(item);
        }
      });
      setPageData(array);
      setloading(false);
    } else {
      setSearchText(searchValue);
      const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
      const filteredRows = data.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
      const array = [];
      filteredRows.forEach((item) => {
        if (!hiddenAttachs.includes(item.id)) {
          array.push(item);
        }
      });
      setPageData(array);
    }
  };

  const handleSelectChange = (event) => {
    setSelectValue(event.target.value);
    let value = event.target.value;
    let tempData = [...data];
    let newFilteredArray = [];
    if (value === "ALL") {
      const array = [];
      tempData.forEach((item) => {
        if (!hiddenAttachs.includes(item.id)) {
          array.push(item);
        }
      });
      setPageData(array);
      return;
    }
    data.forEach((item) => {
      if (item.attachmentType !== value) return;
      if (!hiddenAttachs.includes(item.id)) {
        newFilteredArray.push(item);
      }
    });
    setPageData(newFilteredArray);
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            width: "100%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="5rem" />
        </Box>
      ) : (
        <>
          <DataGrid
            rows={pageData}
            columns={columns}
            sx={{
              boxShadow: 2,
            }}
            pagination
            rowsPerPageOptions={[20, 50, 100]}
            components={{ Toolbar: QuickSearchToolbar }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            initialState={{
              pagination: {
                pageSize: pageSize,
              },
            }}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => {
                  requestSearch(event.target.value);
                },
                clearSearch: () => requestSearch(""),
                selectOptions: selectOptions,
                selectValue: selectValue,
                handleSelectChange: handleSelectChange,
              },
              pagination: { classes: null },
            }}
            loading={loading}
          />
        </>
      )}
    </Paper>
  );
};

export default AttachmentTable;
