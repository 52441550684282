import {
  Container,
  Paper,
  Typography,
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getGapPool, getEmbarkations } from "../../Actions/Actions";
import { styled } from "@mui/material/styles";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import GetAppIcon from "@mui/icons-material/GetApp";

import LoadingProgress from "../Commons/LoadingProgress";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Button from "@mui/material/Button";
import { toast, ToastContainer } from "react-toastify";
import { ConnectedTvOutlined } from "@mui/icons-material";
import { size } from "lodash";
import { createStyles, makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { createTheme } from "@mui/material/styles";
import { ButtonCleanFilters } from "../Buttons/Button";
import { Label, Search } from "../Input/Input";
import DropDown from "../DropDown/DropDown";
import { DEPARTAMENT } from "../../constants";


const defaultTheme = createTheme();

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <Search
       value={props.value}
       change={props.onChange}
       className={classes.textField}
      />
    </div>
  );
}

const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

export default function CrewStatus() {
  const [crewdata, setcrewdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [puredata, setpuredata] = useState([]);
  const [filterDate, setFilterDate] = useState({ m: "", y: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [companylist, setcompanylist] = useState([]);
  const [departmentlist, setdepartmentlist] = useState([]);
  const [nationalitylist, setnationalitylist] = useState([]);
  const [statuslist, setstatuslist] = useState([]);
  const [companyfilter, setcompanyfilter] = useState("");
  const [departmentfilter, setdepartmentfilter] = useState("");
  const [nationalityfilter, setnationalityfilter] = useState("");
  const [statusfilter, setstatusfilter] = useState("");

  const getAllData = async () => {
    setIsLoading(true);
    let datapersonal = await getGapPool();
    const dataembarques = await getEmbarkations();
    const userdata = JSON.parse(sessionStorage.getItem("userdata"));
    let { rol, company } = userdata;

    datapersonal = datapersonal.filter(
      (item) =>
        item.status.trim() === "MEDICAL LEAVE" ||
        item.status.trim() === "VACATION" ||
        item.status.trim() === "PRE-EMBARKATION" ||
        item.status.trim() === "ON BOARD"
    );

    //si el marino no tiene PMSSA ID NO se le coloca el ID del registro.
    datapersonal.map((item) => {
      if (!item["PMSSA ID NO"]) {
        item["PMSSA ID NO"] = item.Id;
      }
    });

    datapersonal.map((item, index) => {
      //1. Obtener último embarque  de la persona.
      item.id = item["PMSSA ID NO"];
      let allpersonembarcation = dataembarques
        .filter(
          (itemebarcation) =>
            Number(itemebarcation.IDpmsa) === Number(item["PMSSA ID NO"])
        )
        .sort(compare);
      datapersonal[index].department = item.department;
      //inicio
      item.company = item.company && item.company.toUpperCase();
      item.age = getEdad(item.date_of_birth);
      item.numberofcontracts = size(allpersonembarcation);
      item.position_applying = item.position_applying
        ? item.position_applying.toUpperCase()
        : item.position_applying;
      item.fullname = item.fullname.toUpperCase();

      item.place_of_birth = item.place_of_birth
        ? item.place_of_birth.toUpperCase()
        : item.place_of_birth;
      if (size(allpersonembarcation) > 0) {
        let embarcacion = { ...allpersonembarcation[0] };
        item.doa = embarcacion.doareturndate;
        item.returndate = moment(item.doareturndate).isValid() ? moment(item.doareturndate).format('YYYY/MM/DD') : '';
        item.vessel = embarcacion.VesselName
          ? embarcacion.VesselName.toUpperCase()
          : embarcacion.VesselName;
        item.signon = embarcacion.signondate;
        item.signoff = embarcacion.signoffdate;
        item.signoffreason = embarcacion.signoffreason_detail
          ? embarcacion.signoffreason_detail.toUpperCase()
          : "";

        item.time = dateDiff(
          new Date(item.signon),
          item.signoff ? new Date(item.signoff) : new Date()
        );
      }
    });

    let uniquecompany = [...new Set(datapersonal.map((item) => item.company))];
    let uniquedepartment = datapersonal.map(item => item.department.toLowerCase()).filter((department, index, array) => department !== "" && array.indexOf(department) === index);
    let uniquenationality = [...new Set(datapersonal.map((item)=> item.place_of_birth))];
    let uniquestatus = [...new Set(datapersonal.map((item) => item.status))];

    if (rol === "AUDIT") {
      datapersonal = datapersonal.filter(
        (item) =>
          item.company.trim().toUpperCase() === company.trim().toUpperCase()
      );

      uniquecompany = uniquecompany.filter(
        (item) => item.toUpperCase() === company.trim().toUpperCase()
      );
    }

    setcompanylist(uniquecompany);
    setdepartmentlist(uniquedepartment);
    setnationalitylist(uniquenationality);
    setstatuslist(uniquestatus);
    setpuredata(datapersonal);
    setcrewdata(datapersonal);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllData();
  }, []);

  function getEdad(dateString) {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

  function escapeRegExp(value) {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }

   useEffect(() => {
    const filterdatabackend = puredata.filter((item) => {
    const filterCompany = companyfilter !== '' ? item.company.toUpperCase()  === companyfilter.toUpperCase()  : true;
    const filterDepartament = departmentfilter !== '' ? item.department.toUpperCase() === departmentfilter.toUpperCase() : true;
    const filterNationality = nationalityfilter !== '' ? item.place_of_birth.toUpperCase()  === nationalityfilter.toUpperCase()  : true;
    const filterStatus = statusfilter !== '' ? item.status.toUpperCase()  === statusfilter.toUpperCase()  : true;

    return (
      filterCompany&&
      filterDepartament &&
      filterNationality &&
      filterStatus 
    );
   })

   let filteredDataWithSearch = filterdatabackend;

   if (searchText !== '') {
    const partesBusqueda = searchText.split(' ')
    const searchRegex = new RegExp(partesBusqueda.map(escapeRegExp).join('.*'), 'i');
    filteredDataWithSearch = filterdatabackend.filter((row) => {
      return Object.keys(row).some((field) => {
      return searchRegex.test(row[field].toString());
    });
    });
  }

  const upper = filteredDataWithSearch.map((item) => {
    return { ...item, fullname: item.fullname.toUpperCase() };
  });

  setFilteredData(upper)
  
  }, [puredata, companyfilter, departmentfilter, nationalityfilter, statusfilter, searchText]);


  function dateDiff(date1, date2) {
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff /= 60 * 60 * 24;
    let days = Math.abs(Math.round(diff));
    let months = Math.floor(days / 30);
    days = days - months * 30;
    return `${months}M  ${days}D`;
  }

  function compare(a, b) {
    if (a.signondate > b.signondate) {
      return -1;
    }
    if (a.signondate < b.signondate) {
      return 1;
    }
    return 0;
  }

  let groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };


  const handleDownloadExcel = () => {
    let excelData = [];
    if (crewdata.length < 1) return;
    filteredData.forEach((item) => {
      excelData.push({
        no: item["PMSSA ID NO"],
        company: item.company,
        rank: item.position_applying,
        name: item.fullname,
        place_of_birth: item.place_of_birth,
        sex: item.sex,
        age: item.age,
        numberofcontracts: item.numberofcontracts,
        time: item.time,
        doa: item.doa,
        returndate: item.returndate,
        hiringcompanycode: item.hiringcompanycode,
        vessel: item.vessel,
        signon: item.signon,
        signoff: item.signoff,
        status: item.status,
        signoffreason: item.signoffreason,
      });
    });

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CrewStatus");
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          "Sl No.",
          "Company",
          "Rank",
          "Name",
          "Nationality",
          "Gender",
          "Age",
          "Number of contracts (In Total)",
          "Sea time",
          "DOA",
          "Return Date",
          "Hiring Company Code",
          "Vessel",
          "Sign-on Date",
          "Sign-off Date",
          "Status",
          "Sign-off Reason Detail",
        ],
      ],
      { origin: "A1" }
    );
    // const max_width = excelData.reduce(
    //  (w, r) => Math.max(w, r.nombre.length),
    //  10
    // );
    // worksheet["!cols"] = [{ wch: max_width }];
    XLSX.writeFile(workbook, "CrewStatus.xlsx");
  };

  const columns = [
    {
      field: "PMSSA ID NO",
      headerName: "Sl No.",

      width: 50,
    },
    {
      field: "company",
      headerName: "Company",

      width: 300,
    },
    {
      field: "hiringcompanycode",
      headerName: "Hiring Company Code",

      width: 300,
    },
    {
      field: "department",
      headerName: "Departament",

      width: 150,
    },
    {
      field: "position_applying",
      headerName: "Rank",

      width: 300,
    },
    {
      field: "fullname",
      headerName: "Full name",
      width: 300,
      editable: false,
    },
    {
      field: "place_of_birth",
      headerName: "Nationality",

      width: 120,
    },
    {
      field: "sex",
      headerName: "Gender",

      width: 80,
    },
    {
      field: "age",
      headerName: "Age",

      width: 80,
    },
    {
      field: "numberofcontracts",
      headerName: "Number of contracts (In Total)",
      width: 240,
    },

    {
      field: "time",
      headerName: "Sea time",

      width: 150,
    },
    {
      field: "vessel",
      headerName: "Vessel",

      width: 200,
    },

    {
      field: "signon",
      headerName: "Sign-on Date",

      width: 180,
    },
    {
      field: "signoff",
      headerName: "Sign-off Date",

      width: 200,
    },
    {
      field: "doa",
      headerName: "DOA",

      width: 150,
    },
    {
      field: "returndate",
      headerName: "Return Date",

      width: 150,
    },
    {
      field: "status",
      headerName: "Status",

      width: 200,
    },
    {
      field: "signoffreason",
      headerName: "Sign off Reason",
      sortable: false,
      width: 300,
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 1 }}>
        <Paper
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            mt: 2,
          }}
        >
          {/* NEW CHART HERE */}
          <Box fullWidth>
            <Typography variant="h5" color="primary">
              Crew Status Active
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={2} sx={{ mt: 2 }}>
                <Item>
                  <Typography sx={{ fontWeight: "Bold" }}>
                    {size(filteredData)} Records{" "}
                  </Typography>
                </Item>
              </Grid>
              <Grid item xs={3}>
                 <Label 
                  text='Filter by Company'
                 />
                 <DropDown 
                  name='company'
                  label='company'
                  value={companyfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setcompanyfilter(e.target.value)}
                  lists={companylist}
                 />
              </Grid>
              <Grid item xs={3}>
               <Label 
                text='Filter by Department'
               />
               <DropDown 
                  name='department'
                  label='department'
                  value={departmentfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setdepartmentfilter(e.target.value)}
                  lists={departmentlist}
                 />
              </Grid>
              <Grid item xs={3}>
                <Label 
                 text='Filter by Nationality'
                />
                <DropDown 
                  name='nationality'
                  label='nationality'
                  value={nationalityfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setnationalityfilter(e.target.value)}
                  lists={nationalitylist}
                 />
              </Grid>
              <Grid item xs={3}>
                <Label 
                 text='Filter by Status'
                />
                <DropDown 
                  name='status'
                  label='status'
                  value={statusfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setstatusfilter(e.target.value)}
                  lists={statuslist}
                 />
              </Grid>
              <Grid
               item 
               xs={12}
               container
               direction="row"
               justifyContent="right"
               alignItems="center"
               sx={{ marginBottom: 2 }}
              >
              <ButtonCleanFilters 
                 sytle={{ ml: 2, cursor: "pointer" }}
                 click={() => {
                  setcrewdata(puredata);
                  setcompanyfilter("");
                  setdepartmentfilter("");
                  setnationalityfilter("");
                  setstatusfilter("");
                  setSearchText("");
                 }}   
                />
                <Button
                  variant="outlined"
                  startIcon={<GetAppIcon fontSize="large" color="sucess" />}
                  sx={{ ml: 2, cursor: "pointer" }}
                  onClick={handleDownloadExcel}
                  color="success"
                >
                  Export to Excel
                </Button>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", height: "80vh" }}>
              <DataGrid
                rows={filteredData}
                columns={columns}
                sx={{
                  boxShadow: 2,
                }}
                pageSize={pageSize}
                loading={isLoading}
                rowsPerPageOptions={[20, 50, 100]}
                pagination
                getRowId={(row) => row.fullname}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                initialState={{
                  pagination: {
                    pageSize: pageSize,
                  },
                }}
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: searchText,
                    onChange: (event) => {setSearchText(event.target.value)},
                  },
                  pagination: { classes: null },
                }}
              />
            </Box>
          </Box>
        </Paper>
        <LoadingProgress loading={isLoading} />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
        />
      </Container>
    </>
  );
}

