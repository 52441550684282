import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";

export default function AddNewExperienceOnBoard({
  open,
  handleClose,
  data,
  onChange,
  vesseltypedetails,
  handleChangeSelect,
  handleChangeDateoff,
  handleChangeDateon,
  addnew,
  loading,
  isedit,
  edit,
}) {
  const SelectVesselPicker = () => {
    return (
      <FormControl variant="standard" fullWidth>
        <Select
          labelId="vesseltype"
          id="vesseltype"
          value={data.typevessel}
          onChange={handleChangeSelect}
          fullWidth
        >
          {vesseltypedetails.map((item) => (
            <MenuItem
              value={item.vessel_name.toUpperCase()}
              key={item.vessel_name}
            >
              {item.vessel_name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 5,
          }}
        >
          <Grid>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.id}</Title>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              {isedit ? (
                <Title>UPDATE</Title>
              ) : (
                <Title>ADD NEW EXPERIENCE ON BOARD</Title>
              )}
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItem: "center",
                marginRight: "auto",
                marginLeft: "auto",
                pr: 5,
                pl: 5,
              }}
            >
              <Grid
                item
                xs={4}
                sx={{ alignItems: "center", justifyContent: "center" }}
              >
                <Typography variant="subtitle2">Company Name: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="companyname"
                  required
                  id="companyname"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.companyname}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">Vessel Name: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="vesselname"
                  required
                  id="vesselname"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.vesselname}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2">IMO #: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="imo"
                  required
                  id="imo"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.imo}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">GT / HP : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="gthp"
                  required
                  id="gthp"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data.gthp}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Type of Vessel : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <SelectVesselPicker />
              </Grid>

              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Rank Position : </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <TextField
                  name="Rank/position"
                  required
                  id="Rank/position"
                  variant="standard"
                  fullWidth
                  onChange={onChange}
                  value={data["Rank/position"]}
                />
              </Grid>

              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Date on: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={data.dateon}
                  onChange={handleChangeDateon}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={4} sx={{}}>
                <Typography variant="subtitle2">Date off: </Typography>
              </Grid>
              <Grid item xs={8} sx={{}}>
                <MobileDatePicker
                  inputFormat="dd/MM/yyyy"
                  value={data.dateoff}
                  onChange={handleChangeDateoff}
                  renderInput={(params) => (
                    <TextField {...params} variant="standard" />
                  )}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                {isedit ? (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                    {!loading ? "UPDATE" : <CircularProgress color="inherit" />}
                  </Button>
                ) : (
                  <Button variant="outlined" sx={{ mr: 5 }} onClick={addnew}>
                    {!loading ? (
                      "ADD NEW EXPERIENCE ON BOARD"
                    ) : (
                      <CircularProgress color="inherit" />
                    )}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
