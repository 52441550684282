import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DrawerComponent from "./Components/DrawerComponent";
import PrivateRoute from "./Components/PrivateRoute";
import SignIn from "./Components/views/SignIn";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import ExternalUpdate from "./Components/DBComponets/ExternalUpdate";
import { GlobalProvider } from "./context/GlobalContext";
const mdTheme = createTheme({
  typography: {
    fontFamily: ["Poppins"],
    // fontFamily: "Work Sans",

    subtitle2: {
      fontWeight: "bold",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={mdTheme}>
      <GlobalProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Router>
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route
                path="/updateinfo"
                element={<ExternalUpdate isview={1} />}
              />
              <Route path="*" element={<PrivateRoute />} />
            </Routes>
          </Router>
        </LocalizationProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
