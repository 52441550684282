import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function AlertDialog({
  open,
  handleClose,
  completeRequest,
  handleSubmit,
  loading,
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle id="alert-dialog-title">
            Have you forgotten the password?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              __________________________________________________
            </DialogContentText>

            <TextField
              margin="normal"
              required
              fullWidth
              id="emailrecovery"
              label="Email Address"
              name="emailrecovery"
              autoComplete="emailrecovery"
              autoFocus
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus type="submit" variant="contained">
              {!loading ? (
                "Restore Password"
              ) : (
                <CircularProgress color="secondary" />
              )}
            </Button>
            <Button onClick={handleClose}>Back</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
