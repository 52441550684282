import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDF_FONT_SIZE } from "../../../constants";

const Skills = ({ person, skillArray, skillTable, idProcessQuestions, idProcessAnswers}) => {
  return (
    <>
    {skillArray
        .map((item) => item.toUpperCase())
        .includes(person.position_applying.toUpperCase()) ? (
        <>
          {skillTable.length > 0 ? (
            <>
              <View style={styles.mainContainer}>
                <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  9. SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE /
                  ACHIEVEMENTS
                </Text>
                <View>
                  <View style={{ borderBottom: 0.5 }}>
                    {skillTable.map((section, index) => {
                      return (
                        <View key={index}>
                          <View
                            style={[styles.row, { backgroundColor: "#b8cce4" }]}
                          >
                            <Text
                              style={[
                                styles.cell,
                                styles.normalTableText,
                                {
                                  width: "80%",
                                  textAlign: "left",
                                  paddingLeft: 7,
                                },
                              ]}
                            >
                              {section.skill}
                            </Text>
                            <Text
                              style={[
                                styles.cell,
                                styles.normalTableText,
                                { width: "10%" },
                              ]}
                            >
                              Yes
                            </Text>
                            <Text
                              style={[
                                styles.cell,
                                styles.normalTableText,
                                { width: "10%", borderRight: 0.5 },
                              ]}
                            >
                              No
                            </Text>
                          </View>
                          {/* TABLE BEGINS */}
                          {section.questions.map((row, i) => {
                            return (
                              <View style={[styles.row]} key={i}>
                                <Text
                                  style={[
                                    styles.cell,
                                    styles.normalTableText,
                                    {
                                      width: "80%",
                                      textAlign: "left",
                                      paddingLeft: 7,
                                      paddingBottom: 5,
                                      border: 0.5
                                    },
                                  ]}
                                >
                                  {row.question}
                                </Text>
                                <Text
                                  style={[
                                    styles.cell,
                                    styles.normalTableText,
                                    { width: "10%", border: 0.5 },
                                  ]}
                                >
                                  {row.isTrue ? "YES" : ""}
                                </Text>
                                <Text
                                  style={[
                                    styles.cell,
                                    styles.normalTableText,
                                    { width: "10%", border: 0.5 },
                                  ]}
                                >
                                  {row.isTrue ? "" : "NO"}
                                </Text>
                              </View>
                            );
                          })}
                        </View>
                      );
                    })}
                  </View>
                </View>
                {/* TABLE BEGINStttttttt */}
              </View>
            </>
          ) : (
            <>
              <View
                style={[
                  styles.mainContainer,
                  {
                    marginTop: 10,
                   
                  },
                ]}
              >
                {idProcessQuestions.length > 0 && (
                  <Text
                    style={[
                      styles.normalTableText,
                      {
                        textAlign: "left",
                        marginBottom: 2,
                      },
                    ]}
                  >
                    9. SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE /
                    ACHIEVEMENTS
                  </Text>
                )}
                <View>
                  <View style={{ border: 0.5 }}>
                    {idProcessQuestions.map((item, index) => {
                      return (
                        <View
                          key={index}
                          style={[
                            styles.row,
                            { width: "100%", fontSize: PDF_FONT_SIZE, my: 4 },
                          ]}
                        >
                          <View style={{ width: "80%", border: 0.5 }}>
                            <Text
                              style={{
                                paddingVertical: 4,
                                paddingHorizontal: 6,
                              }}
                            >
                              {item}
                            </Text>
                          </View>
                          {idProcessAnswers.length > 0 ? (
                            <>
                              <View
                                style={{
                                  width: "20%",
                                  justifyContent: "space-evenly",
                                  border: 0.5
                                }}
                              >
                                <Text
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                  }}
                                >
                                  {Object.values(idProcessAnswers[index])[0] ===
                                  "true"
                                    ? "YES"
                                    : "NO"}
                                </Text>
                              </View>
                            </>
                          ) : (
                            <></>
                          )}
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            </>
          )}
        </>
      ) : null}
      </>
  )
}

const styles = StyleSheet.create({
    mainContainer: {
      marginTop: 0
    },
    mainExperienceOnboard: {
      width: "100%",
      marginTop: 0,
      paddingTop: 0,
    },
    mainExperienceOnshore: {
      width: "100%",
      marginTop: 0,
      paddingTop: 0,
    },
    mainCoc: {
      marginTop: 0,
      paddingTop: 0,
      border: 0.5,
    },
    mainCerticates: {
      width: "100%",
      marginTop: 0,
      paddingTop: 0,
    },
    topSectionContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    firstTextContainer: {
      padding: 20,
    },
    icon: {
      width: 70,
      height: 90,
      borderWidth: 0.5,
      borderColor: "black",
      gridcolumn: 1,
      marginLeft: 20,
    },
    row: {
      borderTop: 0,
      borderRight: 0.5,
      borderColor: "black",
      width: "100%",
      minHeight: 0,
      display: "flex",
      flexDirection: "row",
    },
    cell: {
      borderLeft: 0.5,
      borderColor: "black",
      textAlign: "center",
      minHeight: 0,
      paddingHorizontal: 2,
    },
    normalTableText: {
      fontSize: PDF_FONT_SIZE,
      paddingTop: 2,
    },
    verticalCell: {
      display: "flex",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 35,
      left: 0,
      right: 30,
      textAlign: "right",
      color: "grey",
    },
    container: {
      display: "grid",
      gridtemplatecolumns: "repeat(2, 1fr)", 
      position: "relative"
    },
    leftColumn: {
      width: '100%'
    },
    rightColumn: {
      width: '100%'
    },
  });

export default Skills;