import * as React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Title from "./Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const columns = [
 {
    field: "id",
    headerName: "#",
    width: 20,
    hide: true
 },
 {
    field: "department",
    headerName: "Department",
    width: 300
 },
 {
    field: "position_name",
    headerName: "Position Name",
    width: 200
 },

];


export default function ModalPositionsByUsers({ open, handleClose, data, setpositionByUsers }){
    return(
     <Modal open={open} onClose={handleClose}>
        <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
          <Paper
            sx={{
              width: 0.9,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            <Grid container spacing={1}>
              <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                <CloseOutlinedIcon onClick={() => handleClose()} />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Title>Positions Selected</Title>
              </Grid>
  
              <Grid item xs={12}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  autoPageSize={true}
                  sx={{
                    boxShadow: 2,
                    height: "60vh",
                    marginRight: 5,
                    marginLeft: 5,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Modal>   
    )
}