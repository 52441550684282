import React, { Component } from "react";
import Chart from "react-apexcharts";
import Title from "../Commons/Title";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { size } from "lodash";

export default function CircularPanel({ data, title, vessels }) {
	let labels = [];
	let series = [];

	let datatotal = size(data) > 0 ? [...data] : [];
	let passengerdata = size(vessels) > 0 ? [...vessels?.passengerData] : [];
	let merchantdata = size(vessels) > 0 ? [...vessels?.merchantData] : [];

	datatotal = size(datatotal) > 0 ? datatotal.sort(compare) : [];
	passengerdata = size(passengerdata) > 0 ? passengerdata.sort(compare) : [];
	merchantdata = size(merchantdata) > 0 ? merchantdata.sort(compare) : [];
	const columns = [
		{
			field: "id",
			headerName: "DEPARTMENT-POSITIONS",
			width: 500,
		},
		{
			field: "total",
			headerName: "TOTAL STAFF",
			width: 100,
		},
	];

	const columnsmin = [
		{
			field: "id",
			headerName: "DEPARTMENT-POSITIONS",
			width: 250,
		},
		{
			field: "total",
			headerName: "TOTAL STAFF",
			width: 100,
		},
	];

	data.forEach((item) => {
		labels.push(item.id);
		series.push(item.total);
	});

	const datos = {
		options: {
			labels: labels,
		},
		series: series,
	};

	const barstate = {
		options: {
			dataLabels: {
				enabled: true,
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			xaxis: {
				categories: labels,
			},
		},
		series: [
			{
				data: series,
			},
		],
	};

	function compare(a, b) {
		if (a.total > b.total) {
			return -1;
		}
		if (a.total < b.total) {
			return 1;
		}
		return 0;
	}

	return (
		size(data) > 0 && (
			<Grid container>
				<Title>{title}</Title>

				<Grid item sx={{ mt: 2, alignItems: "center" }} xs={12}>
					{/* <div className="bar">
					<Chart
						options={barstate.options}
						series={barstate.series}
						type="bar"
						width="850"
					/>
				</div> */}
					<DataGrid
						rows={datatotal}
						columns={columns}
						pageSize={50}
						sx={{
							boxShadow: 2,
							height: "60vh",
							marginRight: 5,
							marginLeft: 5,
							marginBottom: 5,
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Title>Merchant Vessels</Title>
					<DataGrid
						rows={merchantdata}
						columns={columns}
						pageSize={50}
						sx={{
							boxShadow: 2,
							height: "60vh",
							marginRight: 5,
							marginLeft: 5,
							marginBottom: 5,
						}}
					/>
					{/* <Chart
						options={{
							dataLabels: {
								enabled: true,
							},
							plotOptions: {
								bar: {
									horizontal: true,
								},
							},
							xaxis: {
								categories: vessels.merchantData?.map((item) => {
									return item.id;
								}),
							},
						}}
						series={[
							{
								data: vessels.merchantData?.map((item) => {
									return item.total;
								}),
							},
						]}
						type="bar"
					/> */}
				</Grid>
				<Grid item xs={12}>
					<Title>Passenger Vessels</Title>

					<DataGrid
						rows={passengerdata}
						columns={columns}
						pageSize={50}
						sx={{
							boxShadow: 2,
							height: "60vh",
							marginRight: 5,
							marginLeft: 5,
							marginBottom: 5,
						}}
					/>
					{/* <Chart
					options={{
						dataLabels: {
							enabled: true,
						},
						plotOptions: {
							bar: {
								horizontal: true,
							},
						},
						xaxis: {
							categories: vessels.passengerData?.map((item) => {
								return item.id;
							}),
						},
					}}
					series={[
						{
							data: vessels.passengerData?.map((item) => {
								return item.total;
							}),
						},
					]}
					type="bar"
				/> */}
				</Grid>
				<Title>
					There are {vessels.emptyData} users without a category vessel
				</Title>
				<Grid item xs={12} sx={{ mt: 3 }}>
					<Title>Total Staff</Title>

					<div className="donut">
						<Chart
							options={datos.options}
							series={datos.series}
							type="donut"
							width="1000"
						/>
					</div>
				</Grid>
			</Grid>
		)
	);
}
