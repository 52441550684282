import { Box, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Title from "../Commons/Title";
import { getLanguages } from "../../Actions/Actions";

export default function LenguagesTab({ data, setData }) {
  const [lengData, setLengData] = useState([]);
  const [lenguages, setLenguages] = useState([]);

  const getLenguagesData = async () => {
    const lengDataResponse = await getLanguages();
    setLenguages(lengDataResponse);
  };

  useEffect(() => {
    if (data.languages) {
      setLengData(JSON.parse(data.languages));
    } else {
      const newLengData = [
        {
          Languages: "ENGLISH",
          "%": "0",
        },
        {
          Languages: "SPANISH",
          "%": "0",
        },
      ];
      setLengData(newLengData);
      setData({ ...data, languages: JSON.stringify(newLengData) });
    }
    getLenguagesData();
  }, []);

  const handleChange = (e) => {
    setData({ ...data, otherlanguajes: e.target.value });
  };

  const handlePercentageChange = (e, index) => {
    const { value } = e.target;
    const localData = lengData;
    localData[index]["%"] = value;
    setData({ ...data, languages: [...localData] });
    setLengData([...localData]);
  };

  return (
    <Box>
      <Title>Lenguages</Title>
      {lengData.map((item, index) => {
        return (
          <Box
            key={index}
            sx={{ display: "flex", gap: 3, alignItems: "center" }}
          >
            <TextField
              id="standard-basic"
              label="Languages"
              variant="standard"
              disabled={true}
              value={item.Languages?.toUpperCase()}
            />
            <TextField
              id="standard-basic"
              name={item.Languages}
              label="Percentage"
              variant="standard"
              value={item["%"]}
              onChange={(e) => handlePercentageChange(e, index)}
            />
            %
          </Box>
        );
      })}
      <Box sx={{ mt: 3 }}>
        <Title>Other Lenguages</Title>
      </Box>

      <Box sx={{ display: "flex", gap: 3, mb: 5, alignItems: "center" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={data?.otherlanguajes?.toUpperCase()}
          label="Lenguage"
          onChange={handleChange}
          sx={{ width: 300 }}
        >
          {lenguages.map((item, index) => {
            return (
              <MenuItem key={index} value={item.languages_name?.toUpperCase()}>
                {item.languages_name?.toUpperCase()}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          id="standard-basic"
          label="Percentage"
          variant="standard"
          type="number"
          value={data.percentagelanguajes}
          onChange={(e) => {
            if (Number(e.target.value) > 100) {
              setData({ ...data, percentagelanguajes: "100" });
            } else if (Number(e.target.value) < 0) {
              setData({ ...data, percentagelanguajes: "0" });
            } else {
              setData({ ...data, percentagelanguajes: e.target.value });
            }
          }}
        />
        %
      </Box>
      <Title>Marlins Test</Title>
    </Box>
  );
}
