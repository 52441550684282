export const COUNTRY_CODE = [
  { country: "Afghanistan", code: "93", iso: "AF" },
  { country: "Albania", code: "355", iso: "AL" },
  { country: "Algeria", code: "213", iso: "DZ" },
  { country: "American Samoa", code: "1-684", iso: "AS" },
  { country: "Andorra", code: "376", iso: "AD" },
  { country: "Angola", code: "244", iso: "AO" },
  { country: "Anguilla", code: "1-264", iso: "AI" },
  { country: "Antarctica", code: "672", iso: "AQ" },
  { country: "Antigua and Barbuda", code: "1-268", iso: "AG" },
  { country: "Argentina", code: "54", iso: "AR" },
  { country: "Armenia", code: "374", iso: "AM" },
  { country: "Aruba", code: "297", iso: "AW" },
  { country: "Australia", code: "61", iso: "AU" },
  { country: "Austria", code: "43", iso: "AT" },
  { country: "Azerbaijan", code: "994", iso: "AZ" },
  { country: "Bahamas", code: "1-242", iso: "BS" },
  { country: "Bahrain", code: "973", iso: "BH" },
  { country: "Bangladesh", code: "880", iso: "BD" },
  { country: "Barbados", code: "1-246", iso: "BB" },
  { country: "Belarus", code: "375", iso: "BY" },
  { country: "Belgium", code: "32", iso: "BE" },
  { country: "Belize", code: "501", iso: "BZ" },
  { country: "Benin", code: "229", iso: "BJ" },
  { country: "Bermuda", code: "1-441", iso: "BM" },
  { country: "Bhutan", code: "975", iso: "BT" },
  { country: "Bolivia", code: "591", iso: "BO" },
  { country: "Bosnia and Herzegovina", code: "387", iso: "BA" },
  { country: "Botswana", code: "267", iso: "BW" },
  { country: "Brazil", code: "55", iso: "BR" },
  { country: "British Indian Ocean Territory", code: "246", iso: "IO" },
  { country: "British Virgin Islands", code: "1-284", iso: "VG" },
  { country: "Brunei", code: "673", iso: "BN" },
  { country: "Bulgaria", code: "359", iso: "BG" },
  { country: "Burkina Faso", code: "226", iso: "BF" },
  { country: "Burundi", code: "257", iso: "BI" },
  { country: "Cambodia", code: "855", iso: "KH" },
  { country: "Cameroon", code: "237", iso: "CM" },
  { country: "Canada", code: "1", iso: "CA" },
  { country: "Cape Verde", code: "238", iso: "CV" },
  { country: "Cayman Islands", code: "1-345", iso: "KY" },
  { country: "Central African Republic", code: "236", iso: "CF" },
  { country: "Chad", code: "235", iso: "TD" },
  { country: "Chile", code: "56", iso: "CL" },
  { country: "China", code: "86", iso: "CN" },
  { country: "Christmas Island", code: "61", iso: "CX" },
  { country: "Cocos Islands", code: "61", iso: "CC" },
  { country: "Colombia", code: "57", iso: "CO" },
  { country: "Comoros", code: "269", iso: "KM" },
  { country: "Cook Islands", code: "682", iso: "CK" },
  { country: "Costa Rica", code: "506", iso: "CR" },
  { country: "Croatia", code: "385", iso: "HR" },
  { country: "Cuba", code: "53", iso: "CU" },
  { country: "Curacao", code: "599", iso: "CW" },
  { country: "Cyprus", code: "357", iso: "CY" },
  { country: "Czech Republic", code: "420", iso: "CZ" },
  { country: "Democratic Republic of the Congo", code: "243", iso: "CD" },
  { country: "Denmark", code: "45", iso: "DK" },
  { country: "Djibouti", code: "253", iso: "DJ" },
  { country: "Dominica", code: "1-767", iso: "DM" },
  { country: "Dominican Republic", code: "1-809, 1-829, 1-849", iso: "DO" },
  { country: "East Timor", code: "670", iso: "TL" },
  { country: "Ecuador", code: "593", iso: "EC" },
  { country: "Egypt", code: "20", iso: "EG" },
  { country: "El Salvador", code: "503", iso: "SV" },
  { country: "Equatorial Guinea", code: "240", iso: "GQ" },
  { country: "Eritrea", code: "291", iso: "ER" },
  { country: "Estonia", code: "372", iso: "EE" },
  { country: "Ethiopia", code: "251", iso: "ET" },
  { country: "Falkland Islands", code: "500", iso: "FK" },
  { country: "Faroe Islands", code: "298", iso: "FO" },
  { country: "Fiji", code: "679", iso: "FJ" },
  { country: "Finland", code: "358", iso: "FI" },
  { country: "France", code: "33", iso: "FR" },
  { country: "French Polynesia", code: "689", iso: "PF" },
  { country: "Gabon", code: "241", iso: "GA" },
  { country: "Gambia", code: "220", iso: "GM" },
  { country: "Georgia", code: "995", iso: "GE" },
  { country: "Germany", code: "49", iso: "DE" },
  { country: "Ghana", code: "233", iso: "GH" },
  { country: "Gibraltar", code: "350", iso: "GI" },
  { country: "Greece", code: "30", iso: "GR" },
  { country: "Greenland", code: "299", iso: "GL" },
  { country: "Grenada", code: "1-473", iso: "GD" },
  { country: "Guam", code: "1-671", iso: "GU" },
  { country: "Guatemala", code: "502", iso: "GT" },
  { country: "Guernsey", code: "44-1481", iso: "GG" },
  { country: "Guinea", code: "224", iso: "GN" },
  { country: "Guinea-Bissau", code: "245", iso: "GW" },
  { country: "Guyana", code: "592", iso: "GY" },
  { country: "Haiti", code: "509", iso: "HT" },
  { country: "Honduras", code: "504", iso: "HN" },
  { country: "Hong Kong", code: "852", iso: "HK" },
  { country: "Hungary", code: "36", iso: "HU" },
  { country: "Iceland", code: "354", iso: "IS" },
  { country: "India", code: "91", iso: "IN" },
  { country: "Indonesia", code: "62", iso: "ID" },
  { country: "Iran", code: "98", iso: "IR" },
  { country: "Iraq", code: "964", iso: "IQ" },
  { country: "Ireland", code: "353", iso: "IE" },
  { country: "Isle of Man", code: "44-1624", iso: "IM" },
  { country: "Israel", code: "972", iso: "IL" },
  { country: "Italy", code: "39", iso: "IT" },
  { country: "Ivory Coast", code: "225", iso: "CI" },
  { country: "Jamaica", code: "1-876", iso: "JM" },
  { country: "Japan", code: "81", iso: "JP" },
  { country: "Jersey", code: "44-1534", iso: "JE" },
  { country: "Jordan", code: "962", iso: "JO" },
  { country: "Kazakhstan", code: "7", iso: "KZ" },
  { country: "Kenya", code: "254", iso: "KE" },
  { country: "Kiribati", code: "686", iso: "KI" },
  { country: "Kosovo", code: "383", iso: "XK" },
  { country: "Kuwait", code: "965", iso: "KW" },
  { country: "Kyrgyzstan", code: "996", iso: "KG" },
  { country: "Laos", code: "856", iso: "LA" },
  { country: "Latvia", code: "371", iso: "LV" },
  { country: "Lebanon", code: "961", iso: "LB" },
  { country: "Lesotho", code: "266", iso: "LS" },
  { country: "Liberia", code: "231", iso: "LR" },
  { country: "Libya", code: "218", iso: "LY" },
  { country: "Liechtenstein", code: "423", iso: "LI" },
  { country: "Lithuania", code: "370", iso: "LT" },
  { country: "Luxembourg", code: "352", iso: "LU" },
  { country: "Macao", code: "853", iso: "MO" },
  { country: "Macedonia", code: "389", iso: "MK" },
  { country: "Madagascar", code: "261", iso: "MG" },
  { country: "Malawi", code: "265", iso: "MW" },
  { country: "Malaysia", code: "60", iso: "MY" },
  { country: "Maldives", code: "960", iso: "MV" },
  { country: "Mali", code: "223", iso: "ML" },
  { country: "Malta", code: "356", iso: "MT" },
  { country: "Marshall Islands", code: "692", iso: "MH" },
  { country: "Mauritania", code: "222", iso: "MR" },
  { country: "Mauritius", code: "230", iso: "MU" },
  { country: "Mayotte", code: "262", iso: "YT" },
  { country: "Mexico", code: "52", iso: "MX" },
  { country: "Micronesia", code: "691", iso: "FM" },
  { country: "Moldova", code: "373", iso: "MD" },
  { country: "Monaco", code: "377", iso: "MC" },
  { country: "Mongolia", code: "976", iso: "MN" },
  { country: "Montenegro", code: "382", iso: "ME" },
  { country: "Montserrat", code: "1-664", iso: "MS" },
  { country: "Morocco", code: "212", iso: "MA" },
  { country: "Mozambique", code: "258", iso: "MZ" },
  { country: "Myanmar", code: "95", iso: "MM" },
  { country: "Namibia", code: "264", iso: "NA" },
  { country: "Nauru", code: "674", iso: "NR" },
  { country: "Nepal", code: "977", iso: "NP" },
  { country: "Netherlands", code: "31", iso: "NL" },
  { country: "Netherlands Antilles", code: "599", iso: "AN" },
  { country: "New Caledonia", code: "687", iso: "NC" },
  { country: "New Zealand", code: "64", iso: "NZ" },
  { country: "Nicaragua", code: "505", iso: "NI" },
  { country: "Niger", code: "227", iso: "NE" },
  { country: "Nigeria", code: "234", iso: "NG" },
  { country: "Niue", code: "683", iso: "NU" },
  { country: "North Korea", code: "850", iso: "KP" },
  { country: "Northern Mariana Islands", code: "1-670", iso: "MP" },
  { country: "Norway", code: "47", iso: "NO" },
  { country: "Oman", code: "968", iso: "OM" },
  { country: "Pakistan", code: "92", iso: "PK" },
  { country: "Palau", code: "680", iso: "PW" },
  { country: "Palestine", code: "970", iso: "PS" },
  { country: "Panama", code: "507", iso: "PA" },
  { country: "Papua New Guinea", code: "675", iso: "PG" },
  { country: "Paraguay", code: "595", iso: "PY" },
  { country: "Peru", code: "51", iso: "PE" },
  { country: "Philippines", code: "63", iso: "PH" },
  { country: "Pitcairn", code: "64", iso: "PN" },
  { country: "Poland", code: "48", iso: "PL" },
  { country: "Portugal", code: "351", iso: "PT" },
  { country: "Puerto Rico", code: "1-787, 1-939", iso: "PR" },
  { country: "Qatar", code: "974", iso: "QA" },
  { country: "Republic of the Congo", code: "242", iso: "CG" },
  { country: "Reunion", code: "262", iso: "RE" },
  { country: "Romania", code: "40", iso: "RO" },
  { country: "Russia", code: "7", iso: "RU" },
  { country: "Rwanda", code: "250", iso: "RW" },
  { country: "Saint Barthelemy", code: "590", iso: "BL" },
  { country: "Saint Helena", code: "290", iso: "SH" },
  { country: "Saint Kitts and Nevis", code: "1-869", iso: "KN" },
  { country: "Saint Lucia", code: "1-758", iso: "LC" },
  { country: "Saint Martin", code: "590", iso: "MF" },
  { country: "Saint Pierre and Miquelon", code: "508", iso: "PM" },
  { country: "Saint Vincent and the Grenadines", code: "1-784", iso: "VC" },
  { country: "Samoa", code: "685", iso: "WS" },
  { country: "San Marino", code: "378", iso: "SM" },
  { country: "Sao Tome and Principe", code: "239", iso: "ST" },
  { country: "Saudi Arabia", code: "966", iso: "SA" },
  { country: "Senegal", code: "221", iso: "SN" },
  { country: "Serbia", code: "381", iso: "RS" },
  { country: "Seychelles", code: "248", iso: "SC" },
  { country: "Sierra Leone", code: "232", iso: "SL" },
  { country: "Singapore", code: "65", iso: "SG" },
  { country: "Sint Maarten", code: "1-721", iso: "SX" },
  { country: "Slovakia", code: "421", iso: "SK" },
  { country: "Slovenia", code: "386", iso: "SI" },
  { country: "Solomon Islands", code: "677", iso: "SB" },
  { country: "Somalia", code: "252", iso: "SO" },
  { country: "South Africa", code: "27", iso: "ZA" },
  { country: "South Korea", code: "82", iso: "KR" },
  { country: "South Sudan", code: "211", iso: "SS" },
  { country: "Spain", code: "34", iso: "ES" },
  { country: "Sri Lanka", code: "94", iso: "LK" },
  { country: "Sudan", code: "249", iso: "SD" },
  { country: "Suriname", code: "597", iso: "SR" },
  { country: "Svalbard and Jan Mayen", code: "47", iso: "SJ" },
  { country: "Swaziland", code: "268", iso: "SZ" },
  { country: "Sweden", code: "46", iso: "SE" },
  { country: "Switzerland", code: "41", iso: "CH" },
  { country: "Syria", code: "963", iso: "SY" },
  { country: "Taiwan", code: "886", iso: "TW" },
  { country: "Tajikistan", code: "992", iso: "TJ" },
  { country: "Tanzania", code: "255", iso: "TZ" },
  { country: "Thailand", code: "66", iso: "TH" },
  { country: "Togo", code: "228", iso: "TG" },
  { country: "Tokelau", code: "690", iso: "TK" },
  { country: "Tonga", code: "676", iso: "TO" },
  { country: "Trinidad and Tobago", code: "1-868", iso: "TT" },
  { country: "Tunisia", code: "216", iso: "TN" },
  { country: "Turkey", code: "90", iso: "TR" },
  { country: "Turkmenistan", code: "993", iso: "TM" },
  { country: "Turks and Caicos Islands", code: "1-649", iso: "TC" },
  { country: "Tuvalu", code: "688", iso: "TV" },
  { country: "U.S. Virgin Islands", code: "1-340", iso: "VI" },
  { country: "Uganda", code: "256", iso: "UG" },
  { country: "Ukraine", code: "380", iso: "UA" },
  { country: "United Arab Emirates", code: "971", iso: "AE" },
  { country: "United Kingdom", code: "44", iso: "GB" },
  { country: "United States", code: "1", iso: "US" },
  { country: "Uruguay", code: "598", iso: "UY" },
  { country: "Uzbekistan", code: "998", iso: "UZ" },
  { country: "Vanuatu", code: "678", iso: "VU" },
  { country: "Vatican", code: "379", iso: "VA" },
  { country: "Venezuela", code: "58", iso: "VE" },
  { country: "Vietnam", code: "84", iso: "VN" },
  { country: "Wallis and Futuna", code: "681", iso: "WF" },
  { country: "Western Sahara", code: "212", iso: "EH" },
  { country: "Yemen", code: "967", iso: "YE" },
  { country: "Zambia", code: "260", iso: "ZM" },
  { country: "Zimbabwe", code: "263", iso: "ZW" },
];

export const STAGES = [
  "1st Interview",
  "2nd Interview",
  "Hiring Process - Stage 10",
  "Marlin Test",
  "Reembarkation anomalies",
  "Interview with the Ship owner",
  "EVALUATE AND SELECT THE APPLICANTS",
];

export const STATUS = [
  "APPROVED",
  "DISAPPROVED",
];

export const STATUSBARKATION = [
  "COMPLETED",
  "ON BOARD",
];

export const ENDOFCONTRACT = [
  "9 Months",
  "8 Months",
  "7 Months",
  "6 Months",
  "5 Months",
  "4 Months",
  "3 Months",
  "2 Months",
];

export const DOCUMUMENT_LIST = [
  "Seaman's Book (National)",
  "Passport",
  "US Visa C1-D",
  "B1/ B2",
  "MCV",
  "YELLOW FEVER",
  "Flag Certificates",
  "Flag Seamanbook",
];

export const DOCUMUMENT_LIST_OFFICERS = [
  "Seaman's Book (National)",
  "Passport",
  "US Visa C1-D",
  "B1/ B2",
  "MCV",
  "YELLOW FEVER",
  "Flag Certificates",
  "Flag Seamanbook",
  "ENDORSEMENT (PORTUGAL, BELGUIM, CYPRUS & MALTA)",
  "Seaman's Book (Cadet)",
  "GMDSS SB PANAMA",
];

export const COURSES_LIST = [
  "Basic Safety Maritime Training Course (BST)",
  "Proficiency in personal Survival Techniques 1.19",
  "Fire prevention and firefighting 1.20",
  "Elementary first Aid 1.13",
  "Personal Safety and social responsibilities 1.21",
  "Security Awareness Training for all seafarers Course 3.27",
  "Security Awareness Training for all seafarers with designated security Duties Course 3.26",
  "Safety training for personnel proving direct services to passenger in passenger spaces 1.44",
  "Passenger ship Crowd Management Training 1.41",
  "Passenger ship crisis management training 1.42",
  "Passenger Safety, cargo safety and Hull Integrity Training. 1.29",
  "Proficiency in the Management of Survival Crafts and rescue boats Course 1.23",
  "Basic Cargo Training operations for oil and chemical tanker Course 1.01",
  "Advanced Fire Fighting 2.03",
  "Deck Rating Course / WDR",
];

export const AB_POSITIONS = [
  "AB",
  "OS",
  "Able Seaman",
  "Ordinary Seaman",
  "Apprentice Ordinary Seaman",
];

export const COOK_POSITIONS = ["UTILITY COOK", "SHIP COOK", "COOK", "ASSITANT COOK", "ASSISTANT COOK BAKER",
"ASSISTANT COOK BUTCHER",
"ASSISTANT COOK FISH",
"ASSISTANT COOK PANTRY",
"ASSISTANT COOK PASTRY",
"ASSISTANT COOK VEGETABLE",
"ASSISTANT COOK"
];

export const BOSUN_POSITIONS = ["BOSUN"];

export const OILER_POSITIONS = [
  "OILER",
  "Motorman / Oiler",
  "Apprentice Wiper",
];

export const MESSMAN_POSITIONS = ["Galley steward", "Messman"];

export const FITTER_POSITIONS = ["Fitter", "Mechanics", "Welder"];

export const OFFICER_POSITIONS = [
  "Deck officers",
  "Deck cadet",
  "Engine Officers",
  "Engine Officer",
  "Engine Cadet",
  "Electro technical Officer III/6",
  "Electro-technical Rating III/7",
  "Electro-Technical Cadet",
];

export const DEPARTAMENT = [
  "ENGINE",
  "DECK",
];

export const STATUSGAPPOOl = [
 "CONTRACT REVOKED",
 "ON BOARD",
 "UNAVAILABLE",
 "VACATION",
 "MEDICAL LEAVE",
 "MIND CHANGE/VACATION ANOMALY",
 "REFUSED - OWN REQUEST",
 "GAP POOL ST/BY",
 "HIRING PROCESS",
];

export const STATUSGAPPOOlUPDATE = [
  "CONTRACT REVOKED",
  "GAP POOL ST/BY",
  "HIRING PROCESS",
  "MEDICAL LEAVE",
  "ON BOARD",
  "VACATION",
  "WORK PERMITE LEAVE",
  "MIND CHANGE/VACATION ANOMALY",
  "TRANSFER",
  "REFUSED - OWN REQUEST",
  "UNAVAILABLE",
 ];

 export const DEPARTAMENTGAPPOOL = [
  "Engine",
  "Deck",
  "Food Service",
  "Guest Relations",
  "Facilities",
  "Human Resources",
  "Galley",
  "Beverage",
  "Entertainment and cruise programs",
  "Inventory",
  "Medical",
  "Security Guard",
  "Other Services and Personal care departments",
  "Shore Excursion Department",
  "Housekeeping",
  "Information Technology",
  "Casino",
  "Security",
  "Galley cleaning",
  "Financial Operations",
  "Marine Technician",
 ];


export const skillArray = [
  "AB",
  "OS",
  "BOSUN",
  "COOK",
  "FITTER",
  "MESSMAN",
  "OILER",
  "WIPER",
  "Able Seaman",
  "Ordinary Seaman",
  "Apprentice Ordinary Seaman",
  "UTILITY COOK",
  "SHIP COOK",  
  "Motorman / Oiler",
  "Apprentice Wiper",
  "Galley steward",
  "Mechanics",
  "Welder",
  "Deck officers",
  "Deck cadet",
  "Engine Officers",
  "Engine Cadet",
  "Electro technical Officer III/6",
  "Electro-technical Rating III/7",
  "Electro-Technical Cadet",
  "ASSITANT COOK", 
  "ASSISTANT COOK BAKER",
  "ASSISTANT COOK BUTCHER",
  "ASSISTANT COOK FISH",
  "ASSISTANT COOK PANTRY",
  "ASSISTANT COOK PASTRY",
  "ASSISTANT COOK VEGETABLE",
  "ASSISTANT COOK"
];

export const bosunSkill = [
  "Skills professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
  "Have you carried out the task on board related to the able boded seaman (Abs) and ordinary Seaman (Oss) for work assignment?",
  "Do you consider yourself to have the leadership qualities necessary to manage a multicultural crew?",
  "Have you performed operations relevant to port cargo?",
  "Do you have Knowledge of inventory management and ensure that there are sufficient inventories of all supplies and tools used by the deck department?",
  "Do you have control of the operations carried out in the docking and undocking port have been supervised, including mooring ropes and/or anchor chains are properly fastened?",
  "•	Anchor windlass, chains, and anchors",
  "•	Mooring winches, ropes, and springs",
  "•	Cranes, derricks and associated auxiliary equipment",
  "•	Lifeboats, davits and associated auxiliary equipment.",
  "Supervises crew to ensure that all deck-planned maintenance is carried out within the specified time periods given by deck Maintenance officer.",
  "Responsible for ensuring that all working routines and procedures associated with entering and working in confined spaces are strictly adhered to.",
  "Have you have knowledge on how to report and fill out accident/incident reports?",
  "Do you understand of the Company Safety & environmental protection quality management program and the responsibilities in the safety organization according to the emergency evacuation plan, as well as station bill?",
  "Have you collaborated in carrying out firefighting drills on board the ship?",
  "Have you ensured that crew is using at all times the proper safety and protection equipment in the daily deck operations and the critical working areas are appropriately and adequately isolated?",
  "Do you have knowledge of the care for the hazardous material and chemicals and all areas where these area in use within the deck department?",
  "Have you known of the proper handling and storage of chemicals and hazards? (Including the correct storage of the paint)",
  "Time management",
  "Team worker",
  "Good leader",
  "Honest and hardworking",
  "Can work effectively on team or independently",
  "Neat and well organized",
  "Can effectively perform with less or without supervision",
  "Respect and good treatment towards to my other colleagues.",
  "Have your ever been nominated employee of the month",
];
export const cookSkill = [
  "Do you have experience in the management and supervision of the kitchen personnel?",
  "Are you able to work with multicultural teams and be able to assign tasks to them in the kitchen?",
  "Can You maintain order, galley cleaning schedule established and discipline for the crew in charge, after each service, exercising proper methods to minimize equipment damages?",
  "Are you aware that everything related to overtime must be coordinated with the captain, who is responsible for its authorization?",
  "Do you know how the reports that you must present to the captain are carried out, related to the catering department to determine product availability and current food cost?",
  "Do you know that the cook position in no way states or implies that these are the only duties to be performed by the shipboard employee occupying this position shipboard employees will be required to perform any other job-related duties assigned by master?",
  "Do you known know about the established quality standards and company policies in order to supervise galley staff? ",
  "Do you have experience keeping the galley, pantries, mess rooms and provision rooms clean and prepared for rough weather?",
  "Understands and follows a recipe, has knowledge of nutrition, raw materials, preparation techniques, and implements these skills in the planning of menus",
  "Understand the importance of the daily/weekly menus and organize and prepare these menus",
  "Understand the interaction between meals and the pace of daily work on board and its importance for the practical aspects of food and snack service",
  "Do you have experience in planning and preparing orders for provisions keeping in mind food allowance?",
  "Maintains good personal hygiene, understands the importance of frequent hand washing, and how to perform it thoroughly, what kind of clothes to bring in the kitchen, and factors that put the health of others",
  "Do you know of how to break the chain of foodborne diseases?",
  "Understand the importance and knowledge of how to clean and disinfect kitchens, dining rooms and pantries, and why it is important to do so",
  "Do you have knowledge of how to handle refrigerated and frozen products?",
  "Organizes and applies appropriate working methods that allow the correct flow of products, as well as cleaning at all times, avoiding cross-contamination of food",
  "Maintains adequate control to ensure the safety of food when refrigerating leftovers, especially the importance of putting date in food for reuse",
  "Are you aware of the causes of allergies, how to prevent skin infections, and how to prevent skin allergies derived from food consumption, as well as how to identify the symptoms?",
  "Know the importance of keeping proper maintenance control for drinking water equipment in order to prevent the proliferation of bacteria in the system",
  "Do you have knowledge of how to store very perishable foods, perishables, and durations?",
  "Procedure for store food safely and safely, especially once the container is opened.",
  "Ensure the handling of food products at the time of delivery and how to place them on the shelves (first come, first out system) is carried according to procedures",
  "Are you aware that the food products to be bought, according to the composition of the menu plan, is according to the use of raw materials and the financial established?",
  "Do you have experience in prepare and submit a monthly stock of provisions and a galley consumables equipment inventory?",
  "Do you have ability to calculate figures and amounts such as discounts, interest, commissions, tips, proportions, percentages?",
  "Can you maintain an adequate control of the quantity of provisions according to the number of crew members and the duration and nature of the voyages?",
  "Do you Know the importance of food supply, and maintains control through systematization and periodic reviews to monitor the quantity and quality of food?",
  "Are you able to draw up a menu plan for an extended period?",
  "It supplies enough water to drink and prepare food.",
  "Experience in how to manage the waste on board the ship in accordance with the provisions of Annex V of the MARPOL Convention, on waste management plans, kitchen waste should be handled and stored separately from provisions for food, raw materials and drinking water.",
  "maintain the security in the storage of the provisions of the strong surge, while avoiding subjecting the body to an unnecessary physical effort",
  "Are you familiar with the IGS Code and safety management systems?",
  "Are you capable of providing first aid, especially specific kitchen area accidents, such as fires, machine-related accidents, cuts, scalds, caustic burns and crush injuries?",
  "Do you know of how to deal with fires in kitchens, and the use of firefighting equipment?",
];
export const fitterSkill = [
  "Have you contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as fitter?",
  "Do you have knowledge and experience in equipment maintenance?",
  "Do you have welding certificate by one of the following LR, ABS, DNV?",
  "Do you have knowledge and experience in welding, including TIG and MIG?",
  "Do you have knowledge in the handling and storage of the equipment used on board?",
  "Have you worked under the supervision of various departments, for example deck and engine department?",
  "Are you aware that all overtime performed must be authorized and reported to the officer in charge, depending on the area and nature of the work performed?",
  "Have you worked with supervisors and subordinates to understand and comply with the company´s environmental policies and be committed to safeguarding the environment?",
  "Are you aware of the mechanisms to inform your superior about any situation on board that puts the environment at risk or environmental system does not function properly onboard?",
  "Are you aware that it is your responsibility to keep the equipment and tools in good working order?",
  "Do you have Knowledge in overhauling of pumps?",
  "Do you have Knowledge in operating lathe machine?",
  "Do you have Knowledge in carrying out must steelwork, including stainless steel? ",
  "Do you have Knowledge in Pipe installation and fitting?",
  "Do you have experience in assist with overhauling of diesel engines and auxiliary systems?",
  "Do you have experience to taking all reading on main engine and generators, and auxiliary equipment?",
  "Do you always follow up all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE?",
  "Time management",
  "Team Worker",
  "Good Leader",
  "Honest and hardworking",
  "Can work effectively on team or independently",
  "Neat and well Organized",
  "Can effectively perform with less or without supervision",
  "Respect and good treatment towards to my other colleagues.",
  "Have your ever been nominated employee of the month?",
];
export const messmanSkill = [
  "Hard worked",
  "Well Organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling",
  "Ability to work positively and cooperatively in a diverse team environment to meet the entire housekeeping operation.",
  "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness. ",
  "Always in compliance with the company´s environmental policies and be committed to safeguarding the environment and performed all related duties and worn the proper PPE as required at all times.",
  "Active worker and responsible Seaman able to adjust to a variety of activities such as: cleaning and sanitizing cabins, uploading and downloading provision, manipulate laundry equipment, handle cleaning machines, such as: Scrubbing machine, suction machine, shampooing machine, steaming machine, dealing with chemicals, doing the fogging, delivering food in quarantine areas, etc. ",
  "So friendly, open minded, organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling. ",
  "Ability to work every day cooperatively by using too much common sense in a multicultural environment to meet the entire housekeeping operation.",
];
export const oiler = [
  "I have contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as motorman / oiler",
  "I was also responsible for the inspection, operation, and testing, as required, of all machinery and equipment under may responsibility also assisting engineers during the machinery maintenance schedule",
  "Hard working team player and quick to learn individual",
  "I was also responsible for helping the EWO or Engineer advised regarding the status of the vessel and assigned equipment, always in compliance with the company´s environmental policies and be committed to safeguarding the environmental.",
  "Main engine",
  "Purifiers room",
  "Auxiliary Engine and Generators",
  "I.G. Fans",
  "Central Coolers",
  "Main Engine Air Coolers",
  "Painting & Cleaning Engine room and tanks",
  "Assisting on transferring and sounding fuel / oil / sludge",
  "Assisting over hauling pumps, valves, and others.",
  "Engine inventory and storing",
  "Taking all reading on main engine and generators, and auxiliary equipment",
  "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
  "Loading and unloading",
  "Time management",
  "Team Worker",
  "Good Leader",
  "Honest and hardworking",
  "Can work effectively on team or independently",
  "Neat and well Organized",
  "Can effectively perform with less or without supervision",
  "Respect and good treatment towards to my other colleagues.",
  "Have your ever been nominated employee of the month?",
];
export const wiper = [
  "Highly motivated and keen to learn from superiors",
  "As wiper always keeping all the machinery spaces clean, tidy, and sanitized at all times.",
  "Also participating in maintenance work, under supervision. Washing and rinse the floors, in daily basis, and painted at all times",
  "In the event something went wrong it was reported immediately to the EOW. Ensures that relevant signs are posted, and advance notice was given when maintenance, repair, or cleaning operations",
  "Performed al related duties and worn the proper PPE as required at all times",
  "Main engine",
  "Purifiers room",
  "Auxiliary Engine and Generators",
  "I.G. Fans",
  "Central Coolers",
  "Main Engine Air Coolers",
  "Painting & Cleaning Engine room and tanks",
  "Assisting on transferring and sounding fuel / oil / sludge",
  "Assisting over hauling pumps, valves, and others.",
  "Engine inventory and storing",
  "Taking all reading on main engine and generators, and auxiliary equipment",
  "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
  "Loading and unloading",
  "Time management",
  "Team Worker",
  "Good Leader",
  "Honest and hardworking",
  "Can work effectively on team or independently",
  "Neat and well Organized",
  "Can effectively perform with less or without supervision",
  "Respect and good treatment towards to my other colleagues.",
  "Have your ever been nominated employee of the month?",
];
export const abOsSkill = [
  "Skill professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
  "Perform operations relevant to port cargo.",
  "Performed a safe lookout. ",
  "Stood wheel duty when required and followed helm orders from the master, watchkeeping officer or the pilot",
  "Stood duties at port by the gangway following company´s security policy.",
  "Anchor windlass, chains, and anchors",
  "Mooring winches, ropes, and springs",
  "Cranes, derricks and associated auxiliary equipment",
  "Lifeboats, davits and associated auxiliary equipment",
  "Cleaning and maintaining other lifesaving appliances",
  "Outer deck railings, wires, superstructures, deck hull, fire lockers, life raft stations, paint locker, hazmat, and chemicals.",
  "Deck Maintenance",
  "Cut surface engrave 		",
  "Loading and unloading		",
  "Painting",
  "Fast Rescue boats Handling		",
  "Survival Craft Boat handling		",
  "Deck Inventory / Storing",
  "Time management",
  "Team worker",
  "Good leader",
  "Honest and hardworking",
  "Can work effectively on team or independently",
  "Neat and well organized",
  "Can effectively perform with less or without supervision",
  "Respect and good treatment towards to my other colleagues.",
  "Have your ever been nominated employee of the month",
];

export const bosunSkillTable = [
  {
    skill:
      "Mark the follow skills/ responsibilities/ learning experience / achievements if you have knowledge, competence, and experience about:",
    questions: [
      {
        question:
          "Skills professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
        isTrue: false,
      },
      {
        question:
          "Have you carried out the task on board related to the able boded seaman (Abs) and ordinary Seaman (Oss) for work assignment?",
        isTrue: false,
      },
      {
        question:
          "Do you consider yourself to have the leadership qualities necessary to manage a multicultural crew?",
        isTrue: false,
      },
      {
        question: "Have you performed operations relevant to port cargo?",
        isTrue: false,
      },
      {
        question:
          "Do you have Knowledge of inventory management and ensure that there are sufficient inventories of all supplies and tools used by the deck department?",
        isTrue: false,
      },
      {
        question:
          "Do you have control of the operations carried out in the docking and undocking port have been supervised, including mooring ropes and/or anchor chains are properly fastened?",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "Do you have full knowledge of all day-to-day deck operations related to maintenance and deck procedures including?",
    questions: [
      {
        question: "•	Anchor windlass, chains, and anchors",
        isTrue: false,
      },
      {
        question: "•	Mooring winches, ropes, and springs",
        isTrue: false,
      },
      {
        question: "•	Cranes, derricks and associated auxiliary equipment",
        isTrue: false,
      },
      {
        question: "•	Lifeboats, davits and associated auxiliary equipment.",
        isTrue: false,
      },
      {
        question:
          "Supervises crew to ensure that all deck-planned maintenance is carried out within the specified time periods given by deck Maintenance officer.",
        isTrue: false,
      },
      {
        question:
          "Responsible for ensuring that all working routines and procedures associated with entering and working in confined spaces are strictly adhered to.",
        isTrue: false,
      },
      {
        question:
          "Have you have knowledge on how to report and fill out accident/incident reports?",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "Do you always followed all working routines and procedures associated with safety & environmental procedures?",
    questions: [
      {
        question:
          "Do you understand of the Company Safety & environmental protection quality management program and the responsibilities in the safety organization according to the emergency evacuation plan, as well as station bill?",
        isTrue: false,
      },
      {
        question:
          "Have you collaborated in carrying out firefighting drills on board the ship?",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "Do you have Knowledge of the responsibility that the crew in charge properly use the appropriate protective equipment?",
    questions: [
      {
        question:
          "Have you ensured that crew is using at all times the proper safety and protection equipment in the daily deck operations and the critical working areas are appropriately and adequately isolated?",
        isTrue: false,
      },
      {
        question:
          "Do you have knowledge of the care for the hazardous material and chemicals and all areas where these area in use within the deck department?",
        isTrue: false,
      },
      {
        question:
          "Have you known of the proper handling and storage of chemicals and hazards? (Including the correct storage of the paint)",
        isTrue: false,
      },
    ],
  },
  {
    skill: "Exceptional quality of work with outstanding results",
    questions: [
      {
        question: "Time management",
        isTrue: false,
      },
      {
        question: "Team worker",
        isTrue: false,
      },
      {
        question: "Good leader",
        isTrue: false,
      },
      {
        question: "Honest and hardworking",
        isTrue: false,
      },
      {
        question: "Can work effectively on team or independently",
        isTrue: false,
      },
      {
        question: "Neat and well organized",
        isTrue: false,
      },
      {
        question: "Can effectively perform with less or without supervision",
        isTrue: false,
      },
      {
        question: "Respect and good treatment towards to my other colleagues.",
        isTrue: false,
      },
      {
        question: "Have your ever been nominated employee of the month",
        isTrue: false,
      },
    ],
  },
];

export const abOsSkillTable = [
  {
    skill:
      "Mark the follow skills/ responsibilities/ learning experience / achievements if you have knowledge, competence, and experience about:",
    questions: [
      {
        question:
          "Skill professional sailor, responsible, reliable, proactive and well organized with good managerial and organizational skills on the maintenance and conservation of the vessel´s decks and superstructures.",
        isTrue: false,
      },
      {
        question: "Perform operations relevant to port cargo.",
        isTrue: false,
      },
      {
        question: "Performed a safe lookout. ",
        isTrue: false,
      },
      {
        question:
          "Stood wheel duty when required and followed helm orders from the master, watchkeeping officer or the pilot",
        isTrue: false,
      },
      {
        question:
          "Stood duties at port by the gangway following company´s security policy.",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "I have procedures of the supported and carried out in all deck-planned maintenance including",
    questions: [
      {
        question: "Anchor windlass, chains, and anchors",
        isTrue: false,
      },
      {
        question: "Mooring winches, ropes, and springs",
        isTrue: false,
      },
      {
        question: "Cranes, derricks and associated auxiliary equipment",
        isTrue: false,
      },
      {
        question: "Lifeboats, davits and associated auxiliary equipment",
        isTrue: false,
      },
      {
        question: "Cleaning and maintaining other lifesaving appliances",
        isTrue: false,
      },
      {
        question:
          "Outer deck railings, wires, superstructures, deck hull, fire lockers, life raft stations, paint locker, hazmat, and chemicals.",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
    questions: [
      {
        question: "Deck Maintenance",
        isTrue: false,
      },
      {
        question: "Cut surface engrave ",
        isTrue: false,
      },
      {
        question: "Loading and unloading",
        isTrue: false,
      },
      {
        question: "Painting",
        isTrue: false,
      },
      {
        question: "Fast Rescue boats Handling",
        isTrue: false,
      },
      {
        question: "Survival Craft Boat handling",
        isTrue: false,
      },
      {
        question: "Deck Inventory / Storing",
        isTrue: false,
      },
    ],
  },
  {
    skill: "Exceptional quality of work with outstanding results",
    questions: [
      {
        question: "Time management",
        isTrue: false,
      },
      {
        question: "Team worker",
        isTrue: false,
      },
      {
        question: "Good leader",
        isTrue: false,
      },
      {
        question: "Honest and hardworking",
        isTrue: false,
      },
      {
        question: "Can work effectively on team or independently",
        isTrue: false,
      },
      {
        question: "Neat and well organized",
        isTrue: false,
      },
      {
        question: "Can effectively perform with less or without supervision",
        isTrue: false,
      },
      {
        question: "Respect and good treatment towards to my other colleagues.",
        isTrue: false,
      },

      {
        question: "Have your ever been nominated employee of the month",
        isTrue: false,
      },
    ],
  },
];

export const cookSkillTable = [
  {
    skill:
      "Mark the follow skills /responsibilities / leaning experience / achievements if you have knowledge, competence, and experience about:",
    questions: [
      {
        question:
          "Do you have experience in the management and supervision of the kitchen personnel?",
        isTrue: false,
      },
      {
        question:
          "Are you able to work with multicultural teams and be able to assign tasks to them in the kitchen?",
        isTrue: false,
      },
      {
        question:
          "Can You maintain order, galley cleaning schedule established and discipline for the crew in charge, after each service, exercising proper methods to minimize equipment damages?",
        isTrue: false,
      },
      {
        question:
          "Are you aware that everything related to overtime must be coordinated with the captain, who is responsible for its authorization?",
        isTrue: false,
      },
      {
        question:
          "Do you know how the reports that you must present to the captain are carried out, related to the catering department to determine product availability and current food cost?",
        isTrue: false,
      },
      {
        question:
          "Do you know that the cook position in no way states or implies that these are the only duties to be performed by the shipboard employee occupying this position shipboard employees will be required to perform any other job-related duties assigned by master?",
        isTrue: false,
      },
      {
        question:
          "Do you known know about the established quality standards and company policies in order to supervise galley staff? ",
        isTrue: false,
      },
      {
        question:
          "Do you have experience keeping the galley, pantries, mess rooms and provision rooms clean and prepared for rough weather?",
        isTrue: false,
      },
    ],
  },
  {
    skill: "PRACTICAL KITCHEN KNOWLEDGE",
    questions: [
      {
        question:
          "Understands and follows a recipe, has knowledge of nutrition, raw materials, preparation techniques, and implements these skills in the planning of menus",
        isTrue: false,
      },
      {
        question:
          "Understand the importance of the daily/weekly menus and organize and prepare these menus",
        isTrue: false,
      },
      {
        question:
          "Understand the interaction between meals and the pace of daily work on board and its importance for the practical aspects of food and snack service",
        isTrue: false,
      },
      {
        question:
          "Do you have experience in planning and preparing orders for provisions keeping in mind food allowance?",
        isTrue: false,
      },
    ],
  },
  {
    skill: "FOOD HYGIENE AND PERSONAL",
    questions: [
      {
        question:
          "Maintains good personal hygiene, understands the importance of frequent hand washing, and how to perform it thoroughly, what kind of clothes to bring in the kitchen, and factors that put the health of others",
        isTrue: false,
      },
      {
        question:
          "Do you know of how to break the chain of foodborne diseases?",
        isTrue: false,
      },
      {
        question:
          "Understand the importance and knowledge of how to clean and disinfect kitchens, dining rooms and pantries, and why it is important to do so",
        isTrue: false,
      },
      {
        question:
          "Do you have knowledge of how to handle refrigerated and frozen products?",
        isTrue: false,
      },
      {
        question:
          "Organizes and applies appropriate working methods that allow the correct flow of products, as well as cleaning at all times, avoiding cross-contamination of food",
        isTrue: false,
      },
      {
        question:
          "Maintains adequate control to ensure the safety of food when refrigerating leftovers, especially the importance of putting date in food for reuse",
        isTrue: false,
      },
      {
        question:
          "Are you aware of the causes of allergies, how to prevent skin infections, and how to prevent skin allergies derived from food consumption, as well as how to identify the symptoms?",
        isTrue: false,
      },
      {
        question:
          "Know the importance of keeping proper maintenance control for drinking water equipment in order to prevent the proliferation of bacteria in the system",
        isTrue: false,
      },
    ],
  },
  {
    skill: "STORAGE OF FOOD",
    questions: [
      {
        question:
          "Do you have knowledge of how to store very perishable foods, perishables, and durations?",
        isTrue: false,
      },
      {
        question:
          "Procedure for store food safely and safely, especially once the container is opened.",
        isTrue: false,
      },
      {
        question:
          "Ensure the handling of food products at the time of delivery and how to place them on the shelves (first come, first out system) is carried according to procedures",
        isTrue: false,
      },
    ],
  },
  {
    skill: "MANAGEMENT OF RESERVATIONS",
    questions: [
      {
        question:
          "Are you aware that the food products to be bought, according to the composition of the menu plan, is according to the use of raw materials and the financial established?",
        isTrue: false,
      },
      {
        question:
          "Do you have experience in prepare and submit a monthly stock of provisions and a galley consumables equipment inventory?",
        isTrue: false,
      },
      {
        question:
          "Do you have ability to calculate figures and amounts such as discounts, interest, commissions, tips, proportions, percentages?",
        isTrue: false,
      },
      {
        question:
          "Can you maintain an adequate control of the quantity of provisions according to the number of crew members and the duration and nature of the voyages?",
        isTrue: false,
      },
      {
        question:
          "Do you Know the importance of food supply, and maintains control through systematization and periodic reviews to monitor the quantity and quality of food?",
        isTrue: false,
      },
      {
        question: "Are you able to draw up a menu plan for an extended period?",
        isTrue: false,
      },
      {
        question: "It supplies enough water to drink and prepare food.",
        isTrue: false,
      },
    ],
  },
  {
    skill: "ENVIRONMENTAL PROTECTION",
    questions: [
      {
        question:
          "Experience in how to manage the waste on board the ship in accordance with the provisions of Annex V of the MARPOL Convention, on waste management plans, kitchen waste should be handled and stored separately from provisions for food, raw materials and drinking water.",
        isTrue: false,
      },
    ],
  },
  {
    skill: "SAFETY AND HEALTH OF FONDA SERVICE",
    questions: [
      {
        question:
          "maintain the security in the storage of the provisions of the strong surge, while avoiding subjecting the body to an unnecessary physical effort",
        isTrue: false,
      },
      {
        question:
          "Are you familiar with the IGS Code and safety management systems?",
        isTrue: false,
      },
      {
        question:
          "Are you capable of providing first aid, especially specific kitchen area accidents, such as fires, machine-related accidents, cuts, scalds, caustic burns and crush injuries?",
        isTrue: false,
      },
      {
        question:
          "Do you know of how to deal with fires in kitchens, and the use of firefighting equipment?",
        isTrue: false,
      },
    ],
  },
];

export const messmanSkillTable = [
  {
    skill: "SKILLS / RESPONSIBILITIES / LEARNING EXPERIENCE / ACHIEVEMENTS",
    questions: [
      {
        question: "Hard worked",
        isTrue: false,
      },
      {
        question:
          "Well Organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling",
        isTrue: false,
      },
      {
        question:
          "Ability to work positively and cooperatively in a diverse team environment to meet the entire housekeeping operation.",
        isTrue: false,
      },
      {
        question:
          "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness. ",
        isTrue: false,
      },
      {
        question:
          "Always in compliance with the company´s environmental policies and be committed to safeguarding the environment and performed all related duties and worn the proper PPE as required at all times.",
        isTrue: false,
      },
      {
        question:
          "Active worker and responsible Seaman able to adjust to a variety of activities such as: cleaning and sanitizing cabins, uploading and downloading provision, manipulate laundry equipment, handle cleaning machines, such as: Scrubbing machine, suction machine, shampooing machine, steaming machine, dealing with chemicals, doing the fogging, delivering food in quarantine areas, etc. ",
        isTrue: false,
      },
      {
        question:
          "So friendly, open minded, organized and effective support skills, being able to take the initiative with jobs at hand. Proper cleaning techniques and chemical handling. ",
        isTrue: false,
      },
      {
        question:
          "Ability to work every day cooperatively by using too much common sense in a multicultural environment to meet the entire housekeeping operation.",
        isTrue: false,
      },
      {
        question:
          "Demonstrated aptitude and monitors at all times company´s OPP procedures for sanitation and cleanliness.",
        isTrue: false,
      },
    ],
  },
];

export const fitterSkillTable = [
  {
    skill:
      "Mark the follow skills / responsibilities / learning experience / achievements if you have knowledge, competence, and experience about:",
    questions: [
      {
        question:
          "Have you contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as fitter?",
        isTrue: false,
      },
      {
        question:
          "Do you have knowledge and experience in equipment maintenance?",
        isTrue: false,
      },
      {
        question:
          "Do you have welding certificate by one of the following LR, ABS, DNV?",
        isTrue: false,
      },
      {
        question:
          "Do you have knowledge and experience in welding, including TIG and MIG?",
        isTrue: false,
      },
      {
        question:
          "Do you have knowledge in the handling and storage of the equipment used on board?",
        isTrue: false,
      },
      {
        question:
          "Have you worked under the supervision of various departments, for example deck and engine department?",
        isTrue: false,
      },
      {
        question:
          "Are you aware that all overtime performed must be authorized and reported to the officer in charge, depending on the area and nature of the work performed?",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "Do you always followed all working routines and procedures associated with safety & environmental procedures?",
    questions: [
      {
        question:
          "Have you worked with supervisors and subordinates to understand and comply with the company´s environmental policies and be committed to safeguarding the environment?",
        isTrue: false,
      },
      {
        question:
          "Are you aware of the mechanisms to inform your superior about any situation on board that puts the environment at risk or environmental system does not function properly onboard?",
        isTrue: false,
      },
      {
        question:
          "Are you aware that it is your responsibility to keep the equipment and tools in good working order?",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "I have procedures of the supported and carried out in all engine-planned maintenance including:",
    questions: [
      {
        question: "Do you have Knowledge in overhauling of pumps?",
        isTrue: false,
      },
      {
        question: "Do you have Knowledge in operating lathe machine?",
        isTrue: false,
      },
      {
        question:
          "Do you have Knowledge in carrying out must steelwork, including stainless steel? ",
        isTrue: false,
      },
      {
        question: "Do you have Knowledge in Pipe installation and fitting?",
        isTrue: false,
      },
      {
        question:
          "Do you have experience in assist with overhauling of diesel engines and auxiliary systems?",
        isTrue: false,
      },
      {
        question:
          "Do you have experience to taking all reading on main engine and generators, and auxiliary equipment?",
        isTrue: false,
      },
      {
        question:
          "Do you always follow up all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE?",
        isTrue: false,
      },
    ],
  },
  {
    skill: "Exceptional quality of work with outstanding results:",
    questions: [
      {
        question: "Time management",
        isTrue: false,
      },
      {
        question: "Team Worker",
        isTrue: false,
      },
      {
        question: "Honest and hardworking",
        isTrue: false,
      },
      {
        question: "Can work effectively on team or independently",
        isTrue: false,
      },
      {
        question: "Neat and well Organized",
        isTrue: false,
      },
      {
        question: "Can effectively perform with less or without supervision",
        isTrue: false,
      },
      {
        question: "Respect and good treatment towards to my other colleagues.",
        isTrue: false,
      },
      {
        question: "Have your ever been nominated employee of the month?",
        isTrue: false,
      },
    ],
  },
];

export const oilerWiperSkillTable = [
  {
    skill: "For: Oiler",
    questions: [],
  },
  {
    skill:
      "Mark the follow skills / responsibilities / learning experience / achievements if you have knowledge, competence, and experience about:",
    questions: [
      {
        question:
          "I have contributed, supported, and performed all machinery space watches always following company´s procedures, rules, and regulations as motorman / oiler",
        isTrue: false,
      },
      {
        question:
          "I was also responsible for the inspection, operation, and testing, as required, of all machinery and equipment under may responsibility also assisting engineers during the machinery maintenance schedule",
        isTrue: false,
      },
      {
        question: "Hard working team player and quick to learn individual",
        isTrue: false,
      },
      {
        question:
          "I was also responsible for helping the EWO or Engineer advised regarding the status of the vessel and assigned equipment, always in compliance with the company´s environmental policies and be committed to safeguarding the environmental.",
        isTrue: false,
      },
    ],
  },
  {
    skill: "For: Wiper",
    questions: [
      {
        question: "Highly motivated and keen to learn from superiors",
        isTrue: false,
      },
      {
        question:
          "As wiper always keeping all the machinery spaces clean, tidy, and sanitized at all times.",
        isTrue: false,
      },
      {
        question:
          "Also participating in maintenance work, under supervision. Washing and rinse the floors, in daily basis, and painted at all times",
        isTrue: false,
      },
      {
        question:
          "In the event something went wrong it was reported immediately to the EOW. Ensures that relevant signs are posted, and advance notice was given when maintenance, repair, or cleaning operations",
        isTrue: false,
      },
      {
        question:
          "Performed al related duties and worn the proper PPE as required at all times",
        isTrue: false,
      },
    ],
  },
  {
    skill:
      "I have procedures of the supported and carried out in all engine-planned maintenance including:",
    questions: [
      {
        question: "Main engine",
        isTrue: false,
      },
      {
        question: "Purifiers room",
        isTrue: false,
      },
      {
        question: "Auxiliary Engine and Generators",
        isTrue: false,
      },
      {
        question: "I.G. Fans",
        isTrue: false,
      },
      {
        question: "Central Coolers",
        isTrue: false,
      },
      {
        question: "Main Engine Air Coolers",
        isTrue: false,
      },
      {
        question: "Painting & Cleaning Engine room and tanks",
        isTrue: false,
      },
      {
        question: "Assisting on transferring and sounding fuel / oil / sludge",
        isTrue: false,
      },
      {
        question: "Assisting over hauling pumps, valves, and others.",
        isTrue: false,
      },
      {
        question: "Engine inventory and storing",
        isTrue: false,
      },
      {
        question: "Loading and unloading",
        isTrue: false,
      },
      {
        question:
          "Taking all reading on main engine and generators, and auxiliary equipment",
        isTrue: false,
      },
      {
        question:
          "Always followed all working routines and procedures associated with entering and working in confined spaces, and donning hard helmets, safety belts and other PPE.",
        isTrue: false,
      },
    ],
  },
  {
    skill: "Exceptional quality of work with outstanding results:",
    questions: [
      {
        question: "Time management",
        isTrue: false,
      },
      {
        question: "Team Worker",
        isTrue: false,
      },
      {
        question: "Good Leader",
        isTrue: false,
      },
      {
        question: "Honest and hardworking",
        isTrue: false,
      },
      {
        question: "Can work effectively on team or independently",
        isTrue: false,
      },
      {
        question: "Neat and well Organized",
        isTrue: false,
      },
      {
        question: "Can effectively perform with less or without supervision",
        isTrue: false,
      },
      {
        question: "Respect and good treatment towards to my other colleagues.",
        isTrue: false,
      },
      {
        question: "Have your ever been nominated employee of the month?",
        isTrue: false,
      },
    ],
  },
];

export const PDF_FONT_SIZE = 9;

export const HEADERS_CONFIGURATION_FUNCTIONS = {
  method: "GET",
  // mode: "no-cors",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
  redirect: "follow",
  referrerPolicy: "no-referrer",
};
