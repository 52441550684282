import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function HideAndShowPassword({ setIsHideText, isHideText }) {
  return (
    <Button
      variant="contained"
      style={{ height: "95%", width: "80&" }}
      onClick={() => {
        setIsHideText(!isHideText);
      }}
    >
      {isHideText ? <VisibilityIcon /> : <VisibilityOffIcon />}
    </Button>
  );
}

export default HideAndShowPassword;
