import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "../Commons/ErrorBoundary";

const columns = [
  {
    field: "company",
    headerName: "Company",
    width: 250,
  },

  {
    field: "contact",
    headerName: "Contact Name",
    width: 150,
    editable: true,
  },

  {
    field: "email",
    headerName: "Email",
    width: 200,
    editable: true,
  },
  {
    field: "address",
    headerName: "Address",
    width: 150,
    editable: true,
  },
  {
    field: "status",
    headerName: "Active",
    width: 150,
    editable: true,
  },
  {
    field: "id",
    headerName: "Acciones",
    width: 120,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/editcompany?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument
      >
        <EditIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function Companies() {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadResults = async () => {
      setloading(true);
      const resultado = await getData("Companies");
      setdata(resultado);
      setloading(false);
    };

    loadResults();
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 1,
              display: "flex",
              flexDirection: "column",
              height: "80vh",
            }}
          >
            <Title>Companies</Title>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/addcompany");
                  }}
                  startIcon={<AddCircleOutlineRoundedIcon />}
                >
                  ADD NEW COMPANY
                </Button>
              </Grid>
            </Grid>
            <ErrorBoundary>
              <DataGrid
                rows={data}
                columns={columns}
                autoPageSize={true}
                sx={{
                  boxShadow: 2,
                }}
                components={{
                  Toolbar: CustomToolbar,
                }}
                loading={loading}
              />
            </ErrorBoundary>
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
}
