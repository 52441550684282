import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

export const Partner = (props) => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  let user = sessionStorage.getItem("user");
  let result = JSON.parse(user)
  const displayName = encodeURIComponent(result.displayName);
  const url = `https://app.flokzu.com/public/0386bLCODSOL?embedded=true&partneremail=${result.email}&partnername=${displayName}`

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <iframe
              width="1024"
              height="3000"
              src={url}
              frameborder="0"
            ></iframe>
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
};

export default Partner;
