import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { numberNextLimiter } from "../../../utils/utils";
import { PDF_FONT_SIZE } from "../../../constants";
import moment from "moment";

const CocDocuments = ({ documents, COC, person, lightGrey, hideCoC }) => {
  const [vaccines, setVaccines] = useState([]);
  const [docsList, setDocsList] = useState([]);

  useEffect(() => {
    if (person.vaccination_info !== "") {
      setVaccines(JSON.parse(person.vaccination_info));
    } else {
      const emptyVaccines = [
        {
          Doze: "First Doze",
        },
        {
          Doze: "Second Doze",
        },
        {
          Doze: "Booster",
        },
      ];
      setVaccines(emptyVaccines);
    }
  }, [documents]);



  const filteredCoc = COC.filter(item => Object.values(item).some(value => value !== ''))
  const filterDocuments = documents.filter((document) => document['Type of Document'] !== 'YELLOW FEVER')
  const arrayDocumentsnew = filterDocuments.filter(item => Object.values(item).some(value => value !== ''))
  const sortDocuments = arrayDocumentsnew.sort((a,b) => {
    const descA = a['Type of Document'].toUpperCase()
    const descB = b['Type of Document'].toUpperCase()
    return descA.localeCompare(descB)
  })
  

  return (
    <View style={{borderBottom: 0.5}}>
          <Text
                  style={[
                    styles.normalTableText,
                    {
                      textAlign: "left",
                    },
                  ]}
                >
                  4. PERSONAL DOCUMENTATION / SEAFARER DOCUMENTATION
                </Text>
      <View style={[styles.row, { borderRight: 0.5, borderBottom: 0.5 }]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "100%", height: 20, borderRight: 0.5, backgroundColor: "#b8cce4" },
          ]}
        >
          PERSONAL DOCUMENTATION / SEAFARER DOCUMENTATION
        </Text>
      </View>
      <View style={[styles.row, {border: 0.5}]}>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "15%", height: 20, backgroundColor: "#b8cce4" },
          ]}
        >
          TYPE OF DOCUMENT / ID 
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%",  height: 20, backgroundColor: "#b8cce4" },
          ]}
        >
          COUNTRY OF ISSUE
        </Text>
        <Text
          style={[
            styles.cell,
            styles.normalTableText,
            { width: "20%",  height: 20, backgroundColor: "#b8cce4" },
          ]}
        >
          NO.
        </Text>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 20,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          <Text style={styles.normalTableText}>ISSUED AT</Text>
          <Text style={styles.normalTableText}>(PLACE)</Text>
        </View>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 20,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          <Text style={styles.normalTableText}>DATE OF ISSUE (MM / DD / YYYY)</Text>
        </View>
        <View
          style={[
            styles.cell,
            {
              width: "15%",
              height: 20,
              borderRight: 0.5,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#b8cce4",
            },
          ]}
        >
          <Text style={styles.normalTableText}>VALID UNTIL (MM / DD / YYYY)</Text>
        </View>
      </View>
      {/* ### TABLE ROWS ### */}
      {person.type_document === "" && person.COC === "" ? (
        <>
          <View style={styles.row}>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "15%" }]}
            ></Text>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "20%" }]}
            ></Text>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "20%" }]}
            ></Text>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "15%" }]}
            ></Text>
            <Text
              style={[styles.cell, styles.normalTableText, { width: "15%" }]}
            ></Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%", borderRight: 0.5 },
              ]}
            ></Text>
          </View>
        </>
      ) : (
        <>
          {!hideCoC && (
            <>
              {filteredCoc.map((item, index) => {
                return (
                  <View style={styles.row} key={index}>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%", backgroundColor: "#b8cce4" },
                      ]}
                    >
                      {item["COC DOC."]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "20%" },
                      ]}
                    >
                      {item["Country of issue"]}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "20%" },
                      ]}
                    >
                      {numberNextLimiter(item["No."], 15)}
                    </Text>
                    <Text
                      style={[
                        styles.cell,
                        styles.normalTableText,
                        { width: "15%" },
                      ]}
                    >
                      {item["Issue at (place)"]}
                    </Text>
                    <View
                  style={[
                    styles.cell,
                    { width: "15%", borderRight: 0.5  },
                  ]}
                >   
                {item.hasOwnProperty("Date of issue") && item["Date of issue"] !== '' && (
                        <Text
                          style={[styles.normalTableText]}
                        >
                          {moment(item["Date of issue"]).format("MM/DD/YYYY")}
                        </Text>
                    )}
                  </View>
                    <View
                  style={[
                    styles.cell,
                    { width: "15%", borderRight: 0.5  },
                  ]}
                >   
                {item.hasOwnProperty("Valid until") && item["Valid until"] !== '' && (
                        <Text
                          style={[styles.normalTableText]}
                        >
                          {moment(item["Valid until"]).format("MM/DD/YYYY")}
                        </Text>
                    )}
                  </View>
                  </View>
                );
              })}
            </>
          )}
          

          {arrayDocumentsnew.map((item, index) => {
            return (
              <View style={[styles.row, { flexWrap: "wrap" }]} key={index}>
                <View
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%", backgroundColor: "#b8cce4" },
                  ]}
                >   
                  <Text>{item["Type of Document"]?.toUpperCase()}</Text>
                </View>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "20%" },
                  ]}
                >
                  {item["Country of Issue"]}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "20%" },
                  ]}
                >
                  {numberNextLimiter(item["No."], 15)}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.normalTableText,
                    { width: "15%", borderRight: 0.5 },
                  ]}
                >
                  {item["Issued at (Place)"]}
                </Text>
                <View
                  style={[
                    styles.cell,
                    { width: "15%", borderRight: 0.5  },
                  ]}
                >   
                {item.hasOwnProperty("Date of Issue") && item["Date of Issue"] !== '' && (
                        <Text
                          style={[styles.normalTableText]}
                        >
                          {moment(item["Date of Issue"]).format("MM/DD/YYYY")}
                        </Text>
                    )}
                  </View>
                  <View
                  style={[
                    styles.cell,
                    { width: "15%", borderRight: 0.5},
                  ]}
                >   
                {item.hasOwnProperty("Valid Until") && item["Valid Until"] !== '' && (
                        <Text
                          style={[styles.normalTableText]}
                        >
                          {moment(item["Valid Until"]).format("MM/DD/YYYY")}
                        </Text>
                    )}
                    </View>
              </View>
            );
          })}
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: { paddingHorizontal: 30, paddingVertical: 20 },
  topSectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  firstTextContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 20,
  },
  icon: {
    width: 70,
    height: 90,
    borderWidth: 0.5,
    borderColor: "black",
  },
  row: {
    borderTop: 0.5,
    borderColor: "black",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderLeft: 0.5,
    borderColor: "black",
    textAlign: "center",
    minHeight: 15,
    overflow: "hidden",
  },
  normalTableText: {
    fontSize: PDF_FONT_SIZE,
    paddingTop: 0,
  },
  verticalCell: {
    display: "flex",
  },
});

export default CocDocuments;
