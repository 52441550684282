import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link } from "react-router-dom";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Title from "./Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { experimentalStyled as styled } from "@mui/material/styles";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",

  color: theme.palette.text.secondary,
}));

export default function ModalPerson({ open, handleOpen, handleClose, data }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={{ flexGrow: 1, flexDirection: "column" }}>
        <Paper
          sx={{
            width: 0.5,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            paddingBottom: 10,
          }}>
          <Grid>
            <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
              <CloseOutlinedIcon onClick={() => handleClose()} />
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.id}</Title>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "center" }}>
              <Title>{data.fullname}</Title>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid
              container
              xs={12}
              sx={{
                marginRight: "auto",
                marginLeft: "auto",
              }}>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Sex: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.sex}</Typography>
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Date of Birth: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.date_of_birth}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Nationality: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.place_of_birth}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Application Date: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.application_date}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Category Vessel: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.category_vessel}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Company: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.company}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Department: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.department}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Email: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.email}</Typography>
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Nearly Airport: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.nearly_airport}
                </Typography>
              </Grid>

              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Phone: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.phone}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right" }}>
                <Typography variant="subtitle2">Position: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">
                  {data.position_applying}
                </Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right", mb: 1 }}>
                <Typography variant="subtitle2">Whatsapp: </Typography>
              </Grid>
              <Grid item xs={3} sx={{}}>
                <Typography variant="subtitle2">{data.whatsapp}</Typography>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "right" }}></Grid>
              <Grid item xs={3} sx={{}}></Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </Modal>
  );
}
