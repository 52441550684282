import { Box, Grid, TextField, Typography } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { useState } from "react";

const MarlinsTestTab = ({ data, setdata, showToast }) => {
  const handleChange = (event) => {
    if (event.target.value > 100 && event.target.name !== "mt_placeofissue") {
      setdata({ ...data, [event.target.name]: "100" });
      showToast("Input limit is 100%", 3);
      return;
    }
    setdata({ ...data, [event.target.name]: event.target.value });
  };

  const handleDateBirth = (value) => {
    setdata({ ...data, mt_issuedate: new Date(value) });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Total % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_total"
              label=""
              name="mt_total"
              value={data.mt_total}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Listening % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_listening"
              label=""
              name="mt_listening"
              value={data.mt_listening}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Grammar % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_listening"
              label=""
              name="mt_grammar"
              value={data.mt_grammar}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Vocabulary % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_vocabulary"
              label=""
              name="mt_vocabulary"
              value={data.mt_vocabulary}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Issue Date </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <MobileDatePicker
              label=""
              inputFormat="MM/dd/yyyy"
              value={data.mt_issuedate}
              onChange={handleDateBirth}
              name="mt_issuedate"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  fullWidth
                  error={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Time and Numbers % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_timeandnumber"
              label=""
              name="mt_timeandnumber"
              value={data.mt_timeandnumber}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Reading % </Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              type="number"
              required
              id="mt_reading"
              label=""
              name="mt_reading"
              value={data.mt_reading}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Place of issue</Typography>
          </Grid>
          <Grid item xs={4} sx={{ pr: 2 }}>
            <TextField
              required
              id="mt_placeofissue"
              label=""
              name="mt_placeofissue"
              value={data.mt_placeofissue}
              onChange={handleChange}
              variant="standard"
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MarlinsTestTab;
