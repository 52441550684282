import { Text, View } from "@react-pdf/renderer";
import { sortExperiencesByDate } from "../../../utils/utils";
import moment from "moment";

const ExpOnShore = ({ styles, person, expOnShore }) => {
  const experiencesColumn = [
    {
      name: "DATE ON (MM/DD/YYYY)",
      width: "15%",
    },
    {
      name: "DATE OFF (MM/DD/YYYY)",
      width: "15%",
    },
    {
      name: "COMPANY NAME / SHIP-OWNER",
      width: "20%",
    },
    {
      name: "DUTIES OR RESPONSABILITIES",
      width: "25%",
    },
    {
      name: "RANK / POSITION",
      width: "18%",
    },
    {
      name: "REASON FOR LEAVING",
      width: "10%",
    },
    {
      name: "NAME OF CONTACT PERSON & TELEPHONE NUMBER",
      width: "18%",
    },
  ];

  return (
    <View>
      <Text style={[styles.normalTableText, { textAlign: "left" }]}>
        6. WORK EXPERIENCE ONSHORE
      </Text>
      <View style={{ borderBottom: 0.5 }}>
        <View style={[styles.row, { border: 0.5 }]}>
          {experiencesColumn.map((item, index) => (
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                {
                  width: item.width,
                  border: 0.5,
                  borderColor: "black",
                  backgroundColor: "#b8cce4",
                },
              ]}
              key={index}
            >
              {item.name}
            </Text>
          ))}
        </View>
        {sortExperiencesByDate(expOnShore).length > 0 ? (
          sortExperiencesByDate(expOnShore).map((item, index) => (
            <View style={styles.row} key={index}>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", border: 0.5 },
                ]}
              >
                {item["Date on"]
                  ? moment(item["Date on"]).isValid()
                    ? moment(item["Date on"]).format("MM/DD/YYYY")
                    : ""
                  : ""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "15%", border: 0.5 },
                ]}
              >
                {item["Date off"]
                  ? moment(item["Date off"]).isValid()
                    ? moment(item["Date off"]).format("MM/DD/YYYY")
                    : ""
                  : ""}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "20%", border: 0.5 },
                ]}
              >
                {item["Company name"]}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "25%", maxHeight: 205, border: 0.5 },
                ]}
              >
                {item["Duties or responsibilities"]}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "18%", border: 0.5 },
                ]}
              >
                {item["Rank/position"]
                  ? item["Rank/position"]
                  : item["Rank/ position"]}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "10%", border: 0.5 },
                ]}
              >
                {item["Reason for leaving"]}
              </Text>
              <Text
                style={[
                  styles.cell,
                  styles.normalTableText,
                  { width: "18%", border: 0.5 },
                ]}
              >
                {item["Contact person telephone"]
                  ? item["Contact person telephone"]
                  : item["Name of Contact Person & Telephone number"]}
              </Text>
            </View>
          ))
        ) : (
          <View style={styles.row}>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "15%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "20%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "25%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "18%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "10%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
            <Text
              style={[
                styles.cell,
                styles.normalTableText,
                { width: "18%", minHeight: 30 },
              ]}
            >
              {""}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default ExpOnShore;
