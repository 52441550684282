import React, { useEffect, useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MainListComponent, secondaryListItems } from "./listItems";
import { userExists } from "../Actions/Actions";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import "react-toastify/dist/ReactToastify.css";
import OrderStaff from "./OrderStaff";
import SignUp from "./views/SignUp";
import Users from "./Users/Users";
import Requests from "./Requests/Requests";
import GapPool from "./DBComponets/GapPool";
import SignOut from "./views/SignOut";
import EditUser from "./Users/EditUser";
import RequestDetails from "./Requests/RequestDetails";
import AddCompany from "./Companies/AddCompany";
import Companies from "./Companies/Companies";
import EditCompany from "./Companies/EditCompany";
import Graph from "./Dashboard/Graph";
import UpdatePersonalInfo from "./DBComponets/UpdatePersonalInfo";
import ExpiredDocuments from "./ExpiredDocuments/ExpiredDocuments";
import EmbarkationReport from "./Embarkations/EmbarkationReport";
import EmbarcationList from "./Embarkations/EmbarcationList";
import AssignPositionsAvailables from "./Users/AssignPositionsAvailables";
import InitialView from "./views/InitialView";
import logo from "../assets/logo.jpg";
import NoPermisionPage from "./NoPermisionPage";
import PartnerView from "./PartnerView/PartnerView";
import UpdateRequestsList from "./UpdateRequests/UpdateRequestsList";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AddStaff from "./Users/AddStaff";
import PendingUpdate from "./Users/PendingUpdate";
import ExternalUpdate from "./DBComponets/ExternalUpdate";
import ApprovedDissaproved from "./Reports/ApprovedDissaproved";
import Seaferers from "./Reports/Seaferers";
import CompanyReports from "./Reports/CompanyReports";
import Complaints from "./Reports/Complaints";
import Mitradel from "./Reports/Mitradel";
import CrewStatus from "./Reports/CrewStatus";
import Applications from "../Applications/Applications";
import PartnerSeaferes from "./PartnerView/PartnerSeaferes";
import { PendingUpdateReport } from "./UpdateRequests/PendingUpdate";
const drawerWidth = 350;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

const mdTheme = createTheme({
	typography: {
		fontFamily: "Poppins",
	},
});
export default function DrawerComponent({ title }) {
	const [open, setOpen] = React.useState(true);
	const [rol, setrol] = useState("");
	const navigate = useNavigate();
	const toggleDrawer = () => {
		setOpen(!open);
	};

	useEffect(() => {
		const loadResults = async () => {
			await getRol();
		};

		loadResults();
	}, []);

	const getRol = async () => {
		const account = sessionStorage.getItem("user");
		const user = JSON.parse(account);
		const data = await userExists(user.email);
		let rol = _.get(data[0], "rol", "");
		data[0].rol = rol;
		sessionStorage.setItem("userdata", JSON.stringify(data[0]));
		setrol(rol);
	};
	const mdTheme = createTheme();
	return (
		<Box sx={{ display: "flex", height: "100vh" }}>
			<CssBaseline />

			<AppBar position="absolute" open={open}>
				<Toolbar
					sx={{
						pr: "24px", // keep right padding when drawer closed
					}}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						sx={{
							marginRight: "36px",
							...(open && { display: "none" }),
						}}>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1 }}>
						LOGISTIC INTERNATIONAL SERVICES CORP.
					</Typography>
					<IconButton color="inherit">
						{/* <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge> */}
					</IconButton>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<Toolbar
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "flex-end",
						px: [1],
					}}>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				<img
					src={logo}
					style={{
						width: open ? 200 : 60,
						height: open ? 90 : 30,
						marginLeft: "auto",
						marginRight: "auto",
						marginTop: 10,
						marginBottom: 10,
					}}
				/>
				<Divider />

				<List component="nav">
					<MainListComponent rol={rol} />
				</List>
			</Drawer>

			<Box
				component="main"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === "light"
							? theme.palette.grey[100]
							: theme.palette.grey[900],
					flexGrow: 1,
					overflow: "auto",
					height: "100vh",
				}}>
				<Routes>
					<Route exact path="/" element={<InitialView />} />
					<Route exact path="/orderstaff" element={<OrderStaff />} />
					<Route path="/createuser" element={<SignUp />} />
					<Route path="/users" element={<Users />} />
					<Route path="/addstaff" element={<AddStaff />} />
					<Route
						path="/pendingupdate"
						element={
							<Box sx={{}}>
								<ExternalUpdate />
							</Box>
						}
					/>
					<Route path="/requests" element={<Requests />} />
					<Route path="/staffdatabase" element={<GapPool />} />
					<Route path="/applications" element={<Applications />} />
					<Route path="/partnerseafarers" element={<PartnerSeaferes />} />
					<Route path="/request_details" element={<RequestDetails />} />
					<Route path="/edituser" element={<EditUser />} />
					<Route path="/signout" element={<SignOut />} />
					<Route path="/addcompany" element={<AddCompany />} />
					<Route path="/companies" element={<Companies />} />
					<Route path="/editcompany" element={<EditCompany />} />
					<Route path="/graph" element={<Graph />} />
					<Route path="/expireddocuments" element={<ExpiredDocuments />} />
					<Route path="/updatepersonalinfo" element={<UpdatePersonalInfo />} />
					<Route path="/embarkationreport" element={<EmbarkationReport />} />
					<Route path="/embarkationlist" element={<EmbarcationList />} />
					<Route
						path="/approveddissaproved"
						element={<ApprovedDissaproved />}
					/>
					<Route path="/seafers" element={<Seaferers />} />
					<Route path="/companyreports" element={<CompanyReports />} />
					<Route path="/complaints" element={<Complaints />} />
					<Route path="/mitradel" element={<Mitradel />} />
					<Route
						path="/pendingupdatereport"
						element={<PendingUpdateReport />}
					/>
					<Route path="/crewstatus" element={<CrewStatus />} />

					<Route
						path="/asignpositionsavailable"
						element={<AssignPositionsAvailables />}
					/>
					<Route path="/noaccess" element={<NoPermisionPage />} />
					<Route path="/partnerview" element={<PartnerView />} />
					<Route path="/updatelists" element={<UpdateRequestsList />} />
				</Routes>
			</Box>
		</Box>
	);
}
