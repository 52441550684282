import React, { useState, useEffect, useContext } from "react";

import Title from "../Commons/Title";

import moment from "moment";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getData, getUserByEmail } from "../../Actions/Actions";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../src/Firebase.js/Firebase";

import PreviewRoundedIcon from "@mui/icons-material/PreviewRounded";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import Typography from "@mui/material/Typography";
import { GlobalContext } from "../../context/GlobalContext";
import ExportReportBtn from "../Commons/ExportReportBtn";

moment.locale("es");

const columns = [
  {
    field: "id",
    headerName: "Acciones",
    width: 80,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/request_details?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument>
        <AssignmentIndIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },
  {
    field: "createAt",
    headerName: "Create At",
    width: 160,
  },
  {
    field: "userName",
    headerName: "Request by",
    width: 150,
    editable: true,
  },
  {
    field: "bordingDate",
    headerName: "Boarding Date",
    width: 120,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    editable: false,
    renderCell: (params) => <StatusComponent params={params} />,
  },
];

const getStatusStyle = (status) => {
  let baseStyle = {
    width: 15,
    height: 15,
    mr: 1,
    borderRadius: "50%",
  };
  if (status === "completed") baseStyle.backgroundColor = "green";
  if (status === "reviewing") baseStyle.backgroundColor = "yellow";
  if (status === "pending") baseStyle.backgroundColor = "orange";
  return baseStyle;
};

const StatusComponent = ({ params }) => {
  let value = params.row.status;
  return (
    <>
      <Box sx={getStatusStyle(value)} />
      <Typography variant="body1" color="initial">
        {value}
      </Typography>
    </>
  );
};

export default function Requests() {
  const [loading, setloading] = useState(false);
  const [data, setData] = useState([]);
  const { staffRequest } = useContext(GlobalContext);

  useEffect(() => {
    const loadResults = async () => {
      setloading(true);
      const user = JSON.parse(sessionStorage.getItem("user"));
      const userRol = JSON.parse(sessionStorage.getItem("userdata"));
      const citiesRef = query(collection(db, "Request"));

      onSnapshot(citiesRef, (snapshot) => {
        const result = [];
        let respuesta = [];

        snapshot.docs.forEach((doc) => {
          let info = doc.data();
          info.id = doc.id;
          result.push(info);
        });

        result.length > 0 && result.sort(compare);
        result.map((item) => {
          let dato = item;
          let amount = 0;
          dato.createAt = moment(dato.createAt.toDate()).format("lll");
          dato.bordingDate = dato.bordingDate
            ? moment(dato.bordingDate.toDate()).format("ll")
            : dato.bordingDate;

          dato.request_details.map((amountdetail) => {
            amount = Number(amount) + Number(amountdetail.requested);
          });
          dato.amount = amount;

          respuesta.push(dato);
        });

        if (userRol.rol !== "PMSSA") {
          const filteredRequests = respuesta.filter((item) => {
            return item.userId === user.uid;
          });
          setData(filteredRequests);
          setloading(false);
          return;
        }
        setData(respuesta);
        setloading(false);
      });
    };
    loadResults();
  }, []);

  function compare(a, b) {
    if (a.createAt > b.createAt) {
      return -1;
    }
    if (a.createAt < b.createAt) {
      return 1;
    }
    return 0;
  }

  const getCsvData = () => {
    const csvData = [];
    const headers = ["Created at", "Request by", "Boarding date", "Status"];
    data.forEach((item) => {
      csvData.push({
        createdAt: item.createAt,
        requestBy: item.userName,
        boardingDate: item.bordingDate,
        status: item.status,
      });
    });
    return [headers, csvData];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}>
              <Title>Staff request</Title>
              <ExportReportBtn getData={getCsvData} />
            </Box>
            {data && (
              <DataGrid
                rows={data}
                columns={columns}
                autoPageSize={true}
                sx={{
                  boxShadow: 2,
                }}
                loading={loading}
              />
            )}
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
}
