import React, { useState, useEffect } from "react";
import Title from "../Commons/Title";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { getGapPool, getSkills } from "../../Actions/Actions";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import ExportReportBtn from "../Commons/ExportReportBtn";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ButtonCleanFilters } from "../Buttons/Button";
import { Label, Search } from "../Input/Input";
import DropDown from "../DropDown/DropDown";
import { STATUSGAPPOOl, DEPARTAMENTGAPPOOL } from "../../constants";

const columns = [
  {
    field: "id",
    headerName: "#",
    width: 20,
  },
  {
    field: "Id",
    headerName: "Acciones",
    width: 80,
    renderCell: (params) => (
      <Link
        to={{
          pathname: `/updatepersonalinfo?id=${params.value}`,
          state: { id: params.value },
        }}
        reloadDocument
      >
        <EditIcon style={{ fontSize: 25 }} color="primary" />
      </Link>
    ),
    sortable: false,
  },
  {
    field: "fullname",
    headerName: "Full Name",
    width: 300,
  },
  {
    field: "category_vessel",
    headerName: "Vessel Type",
    width: 150,
  },
  {
    field: "department",
    headerName: "Department",
    width: 350,
    editable: true,
  },

  {
    field: "position_applying",
    headerName: "Position",
    width: 300,
    editable: true,
  },
  {
    field: "company",
    headerName: "Company",
    width: 170,
    editable: true,
  },
  {
    field: "status",
    headerName: "Status",
    width: 180,
    editable: true,
  },
  {
    field: "place_of_birth",
    headerName: "Nationality",
    width: 180,
    editable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 350,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 250,
    editable: true,
  },
  {
    field: "date_of_birth",
    headerName: "Date of Birth",
    width: 150,
    editable: true,
  },
  {
    field: "sex",
    headerName: "Gender",
    width: 100,
    editable: true,
  },
  {
    field: "nearly_airport",
    headerName: "Nerly Airport",
    width: 590,
    editable: true,
  },
];

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: "space-between",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
      },
      textField: {
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
        margin: theme.spacing(1, 0.5, 1.5),
        "& .MuiSvgIcon-root": {
          marginRight: theme.spacing(0.5),
        },
        "& .MuiInput-underline:before": {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme }
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarFilterButton />
      </div>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Search
          value={props.value}
          change={props.onChange}
          className={classes.textField}
        />
      </Box>
    </div>
  );
}
export const GapPool = (props) => {
  const [data, setdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(50);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [vesselType, setVesselType] = React.useState("Show All");
  const [vesselTypelist, setvesseltypelist] = useState([]);
  const [departmentlist, setdepartmentlist] = useState([]);
  const [positionlist, setpositionlist] = useState([]);
  const [companylist, setcompanylist] = useState([]);
  const [statuslist, setstatuslist] = useState([]);
  const [nationalitylist, setnationalitylist] = useState([]);
  const [vesseltypefilter, setvesseltypefilter] = useState("");
  const [departmentfilter, setdepartmentfilter] = useState("");
  const [positionfilter, setpositionfilter] = useState("");
  const [companyfilter, setcompanyfilter] = useState("");
  const [statusfilter, setstatusfilter] = useState("");
  const [nationalityfilter, setnationalityfilter] = useState("");

  useEffect(() => {
    const loadResults = async () => {
      setloading(true);
      const resultado = await getGapPool();
      let uniquevessel = [
        ...new Set(resultado.map((item) => item.category_vessel)),
      ].filter(
        (vessel, index, array) =>
          vessel !== "" && array.indexOf(vessel) === index
      );
      let uniquedepartment = resultado
        .map((item) => item.department.toLowerCase())
        .filter(
          (department, index, array) =>
            department !== "" && array.indexOf(department) === index
        );
      let uniqueposition = resultado
        .map((item) => item.position_applying)
        .filter(
          (position, index, array) =>
            position !== "" && array.indexOf(position) === index
        );
      let uniquecompany = resultado
        .map((item) => item.company)
        .filter(
          (company, index, array) =>
            company !== "" && array.indexOf(company.toUpperCase()) === index
        );
      let uniquestatus = [...new Set(resultado.map((item) => item.status))];
      let uniquenationality = resultado
        .map((item) => item.place_of_birth.toLowerCase())
        .filter(
          (nationality, index, array) =>
            nationality !== "" && array.indexOf(nationality) === index
        );
      setvesseltypelist(uniquevessel);
      setdepartmentlist(uniquedepartment);
      setpositionlist(uniqueposition);
      setcompanylist(uniquecompany);
      setstatuslist(uniquestatus);
      setnationalitylist(uniquenationality);
      const upper = resultado.map((item) => {
        return {
          ...item,
          fullname: item.fullname.toUpperCase(),
          company: item.company ? item.company.toUpperCase() : item.company,
        };
      });

      resultado.forEach((item) => {
        if (!item.vaccination_info) return;
        const exp = JSON.parse(item.vaccination_info);
      });

      setCompleteData(upper);
      setdata(upper);
      setloading(false);
    };

    loadResults();
  }, []);

  useEffect(() => {
    getSkills();
  }, []);

  useEffect(() => {
    const filterdatabackend = data.filter((item, value, index) => {
      const filterVessel =
        vesseltypefilter !== ""
          ? item.category_vessel.toUpperCase() ===
            vesseltypefilter.toUpperCase()
          : true;
      const filterDepartament =
        departmentfilter !== ""
          ? item.department.toUpperCase() === departmentfilter.toUpperCase()
          : true;
      const filterPosition =
        positionfilter !== ""
          ? item.position_applying.toUpperCase() ===
            positionfilter.toUpperCase()
          : true;
      const filterCompany =
        companyfilter !== ""
          ? item.company.toUpperCase() === companyfilter.toUpperCase()
          : true;
      const filterStatus =
        statusfilter !== ""
          ? item.status.toUpperCase() === statusfilter.toUpperCase()
          : true;
      const filterNationality =
        nationalityfilter !== ""
          ? item.place_of_birth.toUpperCase() ===
            nationalityfilter.toUpperCase()
          : true;

      return (
        filterVessel &&
        filterDepartament &&
        filterPosition &&
        filterCompany &&
        filterStatus &&
        filterNationality
      );
    });

    let filteredDataWithSearch = filterdatabackend;

    if (searchText !== "") {
      const partesBusqueda = searchText.split(" ");
      const searchRegex = new RegExp(
        partesBusqueda.map(escapeRegExp).join(".*"),
        "i"
      );
      filteredDataWithSearch = filterdatabackend.filter((row) => {
        return Object.keys(row).some((field) => {
          return searchRegex.test(row[field].toString());
        });
      });
    }

    const upper = filteredDataWithSearch.map((item) => {
      return { ...item, fullname: item.fullname.toUpperCase() };
    });

    setFilteredData(upper);
  }, [
    data,
    vesseltypefilter,
    departmentfilter,
    positionfilter,
    companyfilter,
    statusfilter,
    nationalityfilter,
    searchText,
  ]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const getCsvData = () => {
    const csvData = [];
    const headers = [];
    columns.forEach((item) => {
      if (item.headerName === "Acciones") return;
      headers.push(item.headerName);
    });
    filteredData.forEach((item) => {
      csvData.push({
        id: item.id,
        fullname: item.fullname,
        vesselType: item.category_vessel,
        department: item.department,
        position: item.position_applying,
        company: item.company,
        status: item.status,
        nationality: item.place_of_birth,
        email: item.email,
        phone: item.phone,
        dateofbirth: item.date_of_birth,
        gender: item.sex,
        nearlyAirport: item.nearly_airport,
      });
    });
    return [headers, csvData];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Container maxWidth="xl" sx={{ mt: 10, mb: 4 }}>
        <Grid item>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
              height: "100vh",
            }}
          >
            <Grid container>
              <Grid item xs={3}>
                <Label text="Filter by Vessel Type" />
                <DropDown
                  name="vesseltype"
                  label="vesseltype"
                  value={vesseltypefilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setvesseltypefilter(e.target.value)}
                  lists={vesselTypelist}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Filter by Department" />
                <DropDown
                  name="department"
                  label="department"
                  value={departmentfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setdepartmentfilter(e.target.value)}
                  lists={departmentlist}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Filter by Position" />
                <DropDown
                  name="position"
                  label="position"
                  value={positionfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setpositionfilter(e.target.value)}
                  lists={positionlist}
                />
              </Grid>
              <Grid item xs={3}>
                <Label text="Filter by Company" />
                <DropDown
                  name="company"
                  label="company"
                  value={companyfilter}
                  style={{ width: 200, mr: 2 }}
                  change={(e) => setcompanyfilter(e.target.value)}
                  lists={companylist}
                />
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <Label text="Filter by Status" />
                <DropDown
                  name="status"
                  label="status"
                  value={statusfilter}
                  style={{ width: 200, mr: 2, mb: 2 }}
                  change={(e) => setstatusfilter(e.target.value)}
                  lists={statuslist}
                />
              </Grid>
              <Grid item xs={3} sx={{ mt: 2 }}>
                <Label text="Filter by Nationality" />
                <DropDown
                  name="nationality"
                  label="nationality"
                  value={nationalityfilter}
                  style={{ width: 200, mr: 2, mb: 2 }}
                  change={(e) => setnationalityfilter(e.target.value)}
                  lists={nationalitylist}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
                sx={{ marginBottom: 2 }}
              >
                <ButtonCleanFilters
                  style={{ mr: 2 }}
                  click={() => {
                    setdata(completeData);
                    setvesseltypefilter("");
                    setdepartmentfilter("");
                    setpositionfilter("");
                    setcompanyfilter("");
                    setstatusfilter("");
                    setnationalityfilter("");
                    setSearchText("");
                  }}
                />
                <ExportReportBtn getData={getCsvData} />
                <Button
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    navigate("/addstaff");
                  }}
                  startIcon={<PersonAddAlt1OutlinedIcon />}
                >
                  SHORT NOTICE
                </Button>
              </Grid>
            </Grid>
            <DataGrid
              rows={filteredData}
              columns={columns}
              sx={{
                boxShadow: 2,
              }}
              loading={loading}
              pagination
              rowsPerPageOptions={[20, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              initialState={{
                pagination: {
                  pageSize: pageSize,
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => {
                    setSearchText(event.target.value);
                  },
                  vesselType,
                  setVesselType,
                },
                pagination: { classes: null },
              }}
            />
          </Paper>
        </Grid>
      </Container>
    </Box>
  );
};

export default GapPool;
