import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Paper } from "@mui/material";

export default function InitialView() {
  const videoUrl =
    "https://firebasestorage.googleapis.com/v0/b/portal-panama-marine-services.appspot.com/o/y2mate.com%20-%20OCEAN%20TRANSPORT%20AND%20LOGISTICS_1080p%20(1).mp4?alt=media&token=8ff198d1-5021-4eca-b84c-323519b793cd";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}>
      <video
        src={videoUrl}
        autoPlay
        muted
        loop
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
      <CssBaseline />
      <Container
        component="main"
        sx={{ mt: 20, mb: 2, zIndex: 1, color: "white" }}
        maxWidth="sm">
        <Box
          sx={{
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            p: 5,
            borderRadius: 2,
          }}>
          <Typography variant="h2" component="h2" gutterBottom>
            Welcome to Logistic International Services Corporation Portal
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="justify">
            {
              "Logistic International Services Corporation Portal is a tool that allow you to have access to all information about Staff. Please use the platform with responsability and securiy and do not share your password with anyone."
            }
          </Typography>
          <Typography variant="body1">¡Thank You!</Typography>
        </Box>
      </Container>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: "rgba(255, 255, 255, 0)",
          zIndex: 99999,
        }}>
        <Container maxWidth="sm">
          <Typography variant="body1" sx={{ color: "white" }}>
            Logistic International Services Corp.
          </Typography>
        </Container>
      </Box>
    </Box>
  );
}
