import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Title from "../Commons/Title";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import CircularProgress from "@mui/material/CircularProgress";
const theme = createTheme();

export default function AddEmergencyContacts({
  open,
  handleClose,
  handleChangeData,
  isEdit,
  edit,
  loading,
  newContact,
  setNewContact,
}) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewContact((state) => ({
      ...state,
      [name]: value,
    }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex" }}>
          <Container component="main" maxWidth="md" style={{ marginTop: 20 }}>
            <Paper
              sx={{
                width: 0.5,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                paddingBottom: 5,
              }}>
              <Grid>
                <Grid item xs={12} sx={{ textAlign: "right", mr: 1, mt: 1 }}>
                  <CloseOutlinedIcon onClick={() => handleClose()} />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "center" }}>
                  {isEdit.isEdit ? (
                    <Title>UPDATE EMERGENCY CONTACTS</Title>
                  ) : (
                    <Title>ADD EMERGENCY CONTACTS</Title>
                  )}
                </Grid>
              </Grid>

              <Grid xs={12}>
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItem: "center",
                    marginRight: "auto",
                    marginLeft: "auto",
                    pr: 5,
                    pl: 5,
                    width: 520,
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Name: </Typography>
                    <TextField
                      required
                      id="Name"
                      label=""
                      name="Name"
                      autoComplete="fullname"
                      value={newContact.Name}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Middle name: </Typography>
                    <TextField
                      required
                      id="Middle name"
                      label=""
                      name="Middle name"
                      autoComplete="fullname"
                      value={newContact["Middle name"]}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Surnames: </Typography>
                    <TextField
                      required
                      id="Surnames"
                      label=""
                      name="Surnames"
                      autoComplete="fullname"
                      value={newContact.Surnames}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Address: </Typography>
                    <TextField
                      required
                      id="Address"
                      label=""
                      name="Address"
                      autoComplete="Address"
                      value={newContact["Address"]}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Relationship: </Typography>
                    <TextField
                      required
                      id="Relationship"
                      label=""
                      name="Relationship"
                      autoComplete="fullname"
                      value={newContact.Relationship}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <Typography variant="subtitle2">Phone: </Typography>
                    <TextField
                      required
                      id="Phone"
                      label=""
                      name="Phone"
                      autoComplete="fullname"
                      value={newContact.Phone}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      variant="standard"
                      sx={{ marginLeft: 3, width: 300 }}
                      fullWidth
                    />
                  </Box>

                  <Grid item xs={6} sx={{ mt: 5, textAlign: "center" }}>
                    {isEdit.isEdit ? (
                      <Button variant="outlined" sx={{ mr: 5 }} onClick={edit}>
                        {!loading ? (
                          "UPDATE CONTACT"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{ mr: 5 }}
                        onClick={() => handleChangeData(newContact)}>
                        {!loading ? (
                          "ADD NEW CONTACT"
                        ) : (
                          <CircularProgress color="inherit" />
                        )}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
      </ThemeProvider>
    </Modal>
  );
}
