import { InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";

export const Label = ({ text }) => {
  return(
   <InputLabel id="demo-simple-select-label">
      {text}
   </InputLabel>
  )
}

export const Search = ({ value, change, styles, click, clear}) => {
  return(
    <TextField
      variant="standard"
      value={value}
      onChange={change}
      placeholder="Search"
      className={styles}
      InputProps={{
      startAdornment: <SearchIcon fontSize="small" />,
      endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: clear ? "visible" : "hidden" }}
                onClick={click}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
      />
  )
}

export const Input = ({value, type, change}) => {
  return(
    <TextField
     id="outlined-basic"
     variant="outlined"
     value={value}
     type={type}
     size={"small"}
     onChange={change}
    />
  )
}